import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpProfile.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import DataForm from '../../libs/react-mpk/components/DataForm'
import errorService from './../../services/errorService'

const OpProfile = ({ 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: { user: {} }})
  const [customRules, setCustomRules] = useState({})

  const basePath = 'profile'
  const baseId = 'mod-profile-form'

  useEffect(() => {
    async function initData(){
      var user = await service.me()
      setData({ content: {
          user: user.data
      }, loading: false })
    }
    initData()
  }, [])
  
  return (
    <>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{
          maxWidth: '100%'
      }}
      title={t.translate('menu.profile')}
      baseId={`mod-form-${basePath}`}
      onChange={(formData, key, value)=> {}}
      defaultData={data.content}
      definitions={[
        {
            render: (
                <DataForm 
                    onSubmit={async (values, callback)=> {
                        try {
                            let res = await service.saveAccount(values)
                            callback(t.translate('sentences.tersimpan'), false, false, false)
                        } catch(e){
                            errorService(e)
                            callback("", false, false, false)
                        }
                    }}
                    hintMessage={t.translate('menu.editProfile')}
                    style={{
                        maxWidth: '100%'
                    }}
                    defaultData={data.content.user}
                    definitions={[
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.firstName`),
                        key: 'firstName',
                        type: 'text',
                        validation: 'required',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.lastName`),
                        key: 'lastName',
                        type: 'text',
                        validation: 'required',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.email`),
                        key: 'email',
                        type: 'text',
                        validation: 'required',
                    },
                    ]} 
                />
            )
        },
        {
            render: (
                <DataForm 
                    onSubmit={async (values, callback)=> {
                        try {
                            let res = await service.changePassword(values)
                            callback(t.translate('sentences.tersimpan'), false, false, false)
                        } catch(e){
                            errorService(e)
                            callback("", false, false, false)
                        }
                    }}
                    defaultData={{}}
                    hintMessage={t.translate('menu.editPassword')}
                    style={{
                        maxWidth: '100%'
                    }}
                    definitions={[
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.currentPassword`),
                        key: 'currentPassword',
                        type: 'password',
                        validation: 'required',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.password`),
                        key: 'password',
                        type: 'password',
                        validation: 'required',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.passwordConfirmation`),
                        key: 'passwordConfirmation',
                        type: 'password',
                        validation: 'required',
                    },
                    ]} 
                />
            )
        },
      ]}
    />
    </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpProfile))
