import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpSettingEmail.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { Chip, ListItem, List, Button } from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'

const OpSettingEmail = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  
}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})

  const baseId = 'mod-op-email'
  const basePath = 'email'

  async function initData() {}

  useEffect(() => {
    try {
      var company = JSON.parse(window.localStorage.getItem("user"))
      var companyId = company.company
      var id = companyId.id
      if(company.authorities[0].name == 'ROLE_EPPT_OWNER'){
        localStorage.setItem('email', JSON.stringify(companyId))
        history.push(`${basePath}/${id}`)
      }
    } catch(e){}
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      console.log(selectedData)
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
  }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.deleteById(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }
  return (
    <TableWrapper
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      sideHeader = {
        <DataForm
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`${baseId}-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            setSearch(values)
            localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.id`),
              key: 'id.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.company`),
              key: 'company.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.host`),
              key: 'host.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.port`),
              key: 'port.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.from`),
              key: 'from.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.username`),
              key: 'username.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.password`),
              key: 'password.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.protocol`),
              key: 'protocol.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.tls`),
              key: 'tls.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.authoroties`),
              key: 'auth.equals',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('menu.companies'),
          searchable: true,
          sortable : true,
          key:'company',
          render: item => {
            try {
              return item.company.nama
            } catch(e){
              return "-"
            }
          }
        },
        {
          label: t.translate('words.host'),
          searchable: true,
          sortable : true,
          key: 'host',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.host)
        },
        {
          label: t.translate('words.port'),
          searchable: true,
          sortable : true,
          key: 'port',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.port)
        },
        {
          label: t.translate('words.from'),
          searchable: true,
          sortable : true,
          key: 'from',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.from)
        },
        {
          label: t.translate('words.tls'),
          searchable: true,
          sortable : true,
          key: 'tls',
          type: TableWrapper.dataTypes.BOOLEAN,
          render: item => {
            if(item.tls){
              return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
            } else {
              return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
            }
          }
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {
          localStorage.setItem('email', JSON.stringify(item))
          history.push(`${basePath}/${item.company.id}`)}, true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteById(item)}, true),
      ]}
      onFetchData={ (params)=> {
        params = {
          ...search,
          ...params
        }
        return (
          new Promise(async (resolve, reject)=> {
            try {
              await initData()
              let user = JSON.parse(localStorage.getItem('user'))
              let getPage = {}
              if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
                getPage = await service.get(params)
              } else {
                var companyId = user.company.id
                getPage = await service.findAll(params, companyId)
              }
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpSettingEmail))
