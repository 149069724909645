import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS, get } from 'mobx';
import UtilsService from '../../services/utilsService'
import ErrorService from '../../services/errorService'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import service from './Organization.service'
import { Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter  } from 'react-md'
import { useToggle } from '@react-md/utils';
import LoadingOverlay from 'react-loading-overlay'
import { Chip, ListItem, List, Button } from 'react-md'
import errorServiceGet from '../../services/errorServiceGet'
import iziToast from 'izitoast'
import Bluebird, { resolve } from 'bluebird'
import errorService from '../../services/errorService'
import AppMode from '../../AppMode'
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'

const Organization = ({
  className       = '',
  showCommandbar  = true,
  history,
  modalStore,
  authStore
}) => {

  const basePath = `organization`
  const baseId = `mod-organization`
  const [organization, setOrganization] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  let [search, setSearch] = useState({})

  const getOrganizations = async ()=> {
    let organizations = UtilsService.getOrganizations(toJS(authStore))
    organizations.map((d)=> {
      d.label = d.npwp + " - " + d.name
    })
    setOrganization(organizations)
    return organizations
  }

  useEffect(() => {
    getOrganizations()
  }, [])

  const addSertel = async (item) => {
    setDialogItem(item)
    setDialog(true)
  }

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      Bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.delete(di.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function exportCsvExc(item) {
    try {
        var proses = t.translate('words.exportCsv')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        Bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.exportCsv(di.id)
              window.open(proccedThis.data.data.url)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function exportCsv(item) {
    modalStore.showConfirm({
      title : t.translate('words.cetak'),
      children : t.translate('words.confirmexportCsv'),
      onSubmit : (callback) => {
        exportCsvExc(item)
        callback()
      }
    })
  }

  async function exportCsvOrgExc(item) {
    try {
        var proses = t.translate('words.exportCsv')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        Bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.exportCsvOrg(di.id)
              window.open(proccedThis.data.data.url)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function exportCsvOrg(item) {
    modalStore.showConfirm({
      title : t.translate('words.cetak'),
      children : t.translate('words.confirmexportCsv'),
      onSubmit : (callback) => {
        exportCsvOrgExc(item)
        callback()
      }
    })
  }

  async function resetCache(item) {
    try {
      var proses = t.translate('words.resetCache')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      Bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.resetCache(di.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
  }
  }

  async function reset(item) {
    modalStore.showConfirm({
      title : t.translate('words.resetCache'),
      children : t.translate('sentences.resetCache'),
      onSubmit : (callback) => {
        resetCache(item)
        callback()
      }
    })
  }

  var actions = []
  
  if (AppMode.onPremise){
    actions = [
      new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
      new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
      new TableWrapper.action(`${t.translate('words.exportCsv')}`, 'mdi mdi-file-excel', (item) => {exportCsv(item)}, true),
      new TableWrapper.action(`${t.translate('words.csvOrgUser')}`, 'mdi mdi-account-group', (item) => {exportCsvOrg(item)}, true),
      new TableWrapper.action(`${t.translate('words.resetCache')}`, 'mdi mdi-alert-decagram', (item) => {reset(item)}, true),
    ]
  } else {
    actions = []
  }


  return (
      <>
        <LoadingOverlay
          style={{
            position: 'inherit',
            background: 'red'
          }}
          active={ploading.loading}
          spinner
          text={ploading.message}
          >
        </LoadingOverlay>
        <Dialog 
          disableFocusOnMount={true}
          initialFocus="sertel-dialog"
          id="sertel-dialog"
          onFocus={()=>{}}
          visible={dialog}
          onRequestClose={()=> {
            setDialog(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="sertel-dialog"
        >
          <DialogContent>
            <FormWrapper 
              style={{
                margin: '0px !important',
                width: '100%',
                maxWidth: '100%'
              }}
              defaultData={{
                npwp: dialogItem.npwp
              }}
              hintMessage={'Bila Anda belum menggunggah sertifikat elektronik. Silahkan unggah sertifikat elektronik Anda disini!'}
              showCommandbar={false}
              baseId = {baseId}
              title={t.translate('modules.organization.title')}
              definitions={[
                {
                  inputType : inputTypes.FILE_INPUT,
                  label : t.translate('words.file'),
                  key : 'file',
                  type : 'file',
                  validation: 'required'
                },
                {
                  inputType : inputTypes.INPUT,
                  label : t.translate('words.npwp'),
                  key : 'npwp',
                  type : 'text',
                  validation: 'required',
                  disabled: true
                },
                {
                  inputType : inputTypes.INPUT,
                  label : t.translate('words.password'),
                  key : 'password',
                  type : 'password',
                  validation: 'required'
                },
                {
                  render: (
                    <div className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width mpk-hint" style={{ background: '#FEF6F4', color: 'black' }}>
                      <div class="container">
                        <h3 class="">Syarat dan Ketentuan Penyimpanan Data Sertifikat Elektronik</h3>
                        <div class="divider"></div>
                        <p class="">Terima kasih telah memilih layanan Nebula Surya Corpora (selanjutnya disebut "Sobat Pajak")</p>
                        <p>Syarat dan ketentuan ini mengatur secara spesifik mengenai syarat dan ketentuan penyimpanan data berupa sertifikat elektronik pada layanan aplikasi perpajakan melalui Sobat Pajak yang merupakan bagian dari Syarat &amp; Ketentuan Umum Layanan Aplikasi Perpajakan Sobat Pajak.</p>
                        <p>I. Umum</p>
                        <ul class="dec">
                          <li>1.1. Sobat Pajak merupakan Penyedia Jasa Aplikasi Perpajakan (PJAP) resmi Direktorat Jenderal Pajak (DJP).</li>
                          <li>1.2. Syarat dan Ketentuan ini merupakan salah satu kewajiban Sobat Pajak untuk memenuhi Peraturan Direktur Jenderal Pajak Nomor PER-10/PJ/2020 tentang Perubahan atas PER-11/PJ/2019 tentang Penyedia Jasa Aplikasi Perpajakan.</li>
                          <li>1.3. Pengguna Layanan Aplikasi Sobat Pajak adalah karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain, sebagai perwakilan sah untuk dan atas nama badan hukum, organisasi, atau orang lain.</li>
                          <li>1.4. Syarat dan ketentuan ini berlaku terhadap seluruh pengguna yang mengakses dan/atau menggunakan Layanan Aplikasi Sobat Pajak.</li>
                          <li>1.5. Sertifikat Elektronik dalam hal ini diartikan sebagai sertifikat yang bersifat elektronik yang memuat Tanda Tangan Elektronik dan identitas yang menunjukan status subjek hukum para pihak dalam transaksi elektronik yang dikeluarkan oleh Direktorat Jenderal Pajak.</li>
                          <li>1.6. Passphrase atau umum disebut kata sandi (password) merupakan serangkaian angka dan/atau huruf dan/atau karakter tertentu yang digunakan sebagai kunci untuk mengakses data yang tersimpan pada sertifikat elektronik.</li>
                        </ul>
                        <p>II. Akun Pengguna</p>
                        <ul class="dec">
                          <li>2.1. Untuk menggunakan Layanan Aplikasi Sobat Pajak, Pengguna harus terlebih dahulu terdaftar pada Layanan Aplikasi Sobat Pajak.</li>
                          <li>2.2. Pengguna diminta untuk melengkapi data-data yang diminta pada saat pendaftaran. Data-data yang diberikan harus benar, akurat dan lengkap.</li>
                          <li>2.3. Pengguna berkewajiban untuk menjaga kerahasiaan kata sandi. Kata sandi yang di berikan kepada karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain menjadi tanggung jawab Pengguna.</li>
                          <li>2.4. Pengguna wajib memberitahukan Sobat Pajak jika diketahui adanya penggunaan Akun Pengguna dari pihak yang tidak berwewenang.</li>
                          <li>2.5. Atas permintaan Pengguna, Sobat Pajak dapat membantu Pengguna untuk mengganti Sandi Akun melalui support@sobatpajak.com dengan melengkapi data-data yang diwajibkan.</li>
                        </ul>
                      </div>
                      <p>III. Data Pengguna</p>
                        <ul class="dec">
                          <li>3.1. Pengguna dengan ini menyatakan dan menjamin bahwa sertifikat elektronik, passphrase dan data lainnya yang didaftarkan pada Layanan Aplikasi Sobat Pajak (selanjutnya disebut “Data Pengguna”) adalah akurat, benar, lengkap, dan sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                          <li>3.2. Pengguna bertanggung jawab atas Data Pengguna dan melepaskan Sobat Pajak dari tuntutan apapun apabila Data Pengguna yang tersedia tidak benar atau tidak sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                          <li>3.3. Pengguna menyatakan dan menjamin bahwa:</li>
                          <li style={{ paddingRight: '56px' }}>3.3.1. Data Pengguna bebas dari data yang bukan milik Pengguna atau Pengguna tidak mempunyai hak untuk mengakses atau mengolah data tersebut;</li>
                          <li style={{ paddingRight: '56px' }}>3.3.2. Data Pengguna yang jika di akses, olah, atau gunakan adalah bebas dari pelanggaran hak pihak ketiga termasuk namun tidak terbatas terhadap hak kekayaan intelektual;</li>
                          <li style={{ paddingRight: '56px' }}>3.3.3. Data Pengguna bebas dari virus, trojan horses, spyware, malware, worms, time bombs, cancelbots, dan/atau segala hal yang dapat membahayakan atau merusak Layanan Aplikasi Sobat Pajak baik secara sebagian atau keseluruhan;</li>
                          <li style={{ paddingRight: '56px' }}>3.3.4. Data Pengguna bebas dari tujuan baik langsung maupun tidak langsung untuk melakukan perbuatan yang melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                          <li>3.4. Sobat Pajak berhak untuk menggunakan, menyalin, mengirim, menyimpan dan melakukan back-up Data Pengguna dengan tujuan hanya untuk keperluan kewajiban perpajakan Pengguna.</li>
                          <li>3.5. Sobat Pajak berkewajiban untuk menjaga Data Pengguna dan tidak akan memberikan Data Pengguna kepada pihak ketiga tanpa persetujuan Pengguna. Namun, Data Pengguna yang di berikan kepada pihak ketiga yang sudah diizinkan Pengguna, merupakan tanggung jawab Pengguna dan Pengguna melepaskan Sobat Pajak dari tanggung jawab terhadap segala penyalahgunaan Data Pengguna yang dilakukan oleh pihak ketiga yang sudah diizinkan.</li>
                          <li>3.6. Sobat Pajak berhak untuk tidak memberikan Data Pengguna kepada pihak ketiga (walaupun Pengguna telah memberikan izin) apabila Sobat Pajak meyakini bahwa pemberian Data Pengguna tersebut dapat merugikan atau membahayakan Sobat Pajak, melanggar Syarat dan Ketentuan ini atau melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                        </ul>
                        <p>IV. Larangan</p>
                        <ul class="dec">
                          <li>4.1. Pengguna tidak diperkenankan untuk mengakses atau mencoba mendapatkan akses selain dari yang merupakan hak atau wewenang Pengguna.</li>
                          <li>4.2. Pengguna tidak diperkenankan untuk mengubah, menyalin, meniru, membongkar, atau melakukan reverse engineering atas layanan aplikasi Sobat Pajak yang digunakan.</li>
                          <li>4.3. Pengguna tidak diperkenankan untuk mengirimkan atau memasukkan hal yang bersifat menghina ataupun melanggar hukum, seperti data atau materi lainnya yang terlindungi hak cipta atau rahasia dagang dimana Pengguna tidak memiliki hak untuk menggunakannya dalam kondisi apapun.</li>
                          <li>4.4. Pengguna tidak diperkenankan untuk menggunakan Layanan Aplikasi Sobat Pajak dengan tujuan yang ilegal atau tidak sah. Sobat Pajak berhak untuk membatasi atau meniadakan sama sekali akses Pengguna untuk menggunakan Layanan Aplikasi Sobat Pajak lebih lanjut jika penggunaan tersebut menimbulkan pelanggaran terhadap hukum yang berlaku.</li>
                          <li>4.5. Sobat Pajak berdasarkan kebijakan sendiri, berhak untuk menangguhkan atau menghentikan akses atau penggunaan terhadap Layanan Aplikasi Sobat Pajak tanpa pemberitahuan terlebih dahulu kepada Pengguna apabila Pengguna melakukan pelanggaran terhadap Syarat dan Ketentuan ini atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                        </ul>
                        <p>V. Persetujuan</p>
                        <ul class="dec">
                          <li>5.1. Pengguna menyatakan dan menjamin bahwa Pengguna adalah individu yang sah secara hukum untuk terikat dalam perjanjian berdasarkan hukum Republik Indonesia, secara khusus terikat dalam Syarat dan Ketentuan ini, untuk menggunakan Layanan Aplikasi Sobat Pajak.</li>
                          <li>5.2. Pengguna menyatakan dan menjamin untuk membebaskan Direktorat Jenderal Pajak dari segala tuntutan yang berkait dengan penyediaan Layanan Aplikasi Perpajakan Sobat Pajak.</li>
                          <li>5.3. Pengguna memahami dan menyetujui bahwa menggunakan Layanan Aplikasi Sobat Pajak dengan risiko Pengguna sendiri.</li>
                          <li>5.4. Sobat Pajak dengan upaya terbaik menyediakan layanan untuk Pengguna, namun tidak memberikan jaminan bahwa penggunaan terhadap Layanan Aplikasi Sobat Pajak akan selalu sesuai dengan harapan Pengguna, selalu ada dalam kualitas terbaik atau terbebas dari virus, kerusakan, bug, error, kehilangan data, dan gangguan lainnya dan Pengguna melepaskan Sobat Pajak dari tuntutan sehubungan dengan terjadinya hal-hal tersebut.</li>
                        </ul>
                        <p>VI. Batasan Tanggung Jawab Sobat Pajak</p>
                        <ul class="dec">
                          <li>6.1. Sobat Pajak tidak bertanggung jawab terhadap kesalahan (human error) yang Pengguna lakukan dalam menggunakan Layanan Aplikasi Sobat Pajak.</li>
                          <li>6.2. Sobat Pajak tidak bertanggung jawab atas dampak dari segala kerugian yang timbul akibat tindakan pengguna yang secara langsung maupun tidak langsung dari penggunaan Layanan Aplikasi Sobat Pajak.</li>
                          <li>6.3. Sobat Pajak tidak bertanggung jawab atas kesalahan yang terjadi, yang termasuk namun tidak terbatas pada:</li>
                          <li style={{ paddingRight: '56px' }}>6.3.1. Kesalahan dan/atau ketidak akuratan data dalam sistem yang di masukkan Pengguna;</li>
                          <li style={{ paddingRight: '56px' }}>6.3.2. Perubahan kebijakan akibat peraturan baru dari seluruh otoritas yang berwenang; dan/atau</li>
                          <li style={{ paddingRight: '56px' }}>6.3.3. Gangguan server atau koneksi internet Pengguna saat menggunakan Layanan Aplikasi Sobat Pajak.</li>
                        </ul>
                        <p>VII. Domisili Hukum dan Penyelesaian Perselisihan</p>
                        <ul class="dec">
                          <li>7.1. Syarat dan Ketentuan ini dilaksanakan menurut ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia.</li>
                          <li>7.2. Setiap perselisihan dan permasalahan yang timbul berkaitan dengan pelaksanaan isi Syarat dan Ketentuan ini akan diselesaikan secara musyawarah dan mufakat oleh Sobat Pajak dan Pengguna.</li>
                          <li>7.3. Apabila penyelesaian secara musyawarah dan mufakat tidak tercapai maka Sobat Pajak dan Pengguna setuju untuk memilih domisili hukum yang tetap dan umum di Kantor Kepaniteraan Pengadilan Negeri Jakarta Barat yang demikian dengan tidak mengurangi hak Sobat Pajak dan Pengguna untuk menuntut pihak lainnya melalui atau dihadapan pengadilan lainnya dalam wilayah Negara Republik Indonesia.</li>
                        </ul>
                        <p>Syarat dan Ketentuan ini dapat berubah dari waktu ke waktu, Sobat Pajak tidak akan memberikan notifikasi kepada Pengguna atas setiap perubahan Syarat dan Ketentuan, namun Sobat Pajak akan mengunggah setiap perubahan tersebut pada laman www.sobatpajak.com.</p>
                        <p>Dengan ini Pengguna menyatakan telah membaca, mengerti dan setuju untuk terikat pada Syarat dan Ketentuan ini serta perubahannya.</p>
                    </div>
                  )
                },
                {
                  inputType : inputTypes.CHECKBOX,
                  label : t.translate('words.aggreed'),
                  key : 'aggree',
                  type : 'text',
                  validation: 'required'
                },
              ]}
              onSubmit={async (values, callback)=> {
                var proses = t.translate('words.uploadSertel')
                setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                try {
                  var formData = new FormData()
                  formData.append('file', values.file)
                  formData.append('password', values.password)
                  formData.append('npwp', values.npwp)
                  document.getElementsByClassName('mdi-reload')[0].click()
                  await service.uploadSertel(formData)
                  iziToast.success({
                    title: proses,
                    message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                  })
                } catch(e){
                  var errors = []
                  errors.push(await errorServiceGet(e, { id: dialogItem.npwp }, 0))
                  modalStore.showInfo({
                    title: '',
                    children: (
                      <List>
                        {errors.map((er)=> {
                          return <ListItem primaryText={''} secondaryText={er}></ListItem>
                        })}
                      </List>
                    )  
                  })
                }
                setDialog(false)
                setPloading({ loading: false, message: `` })
              }}
            />
          </DialogContent>
        </Dialog>
        <TableWrapper
          baseId="mod-organization"
          title={t.translate('modules.organization.title')}
          className={className}
          defaultData={[]}
          defaultSortBy="id"
          columns={[
            {
              label: t.translate('words.npwp'),
              searchable: true,
              sortable : true,
              key: 'npwp',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.npwp)
            },
            {
              label: t.translate('words.name'),
              searchable: true,
              sortable : true,
              key: 'name',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.name)
            },
            {
              label: t.translate('words.email'),
              searchable: true,
              sortable : true,
              key: 'email',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.email)
            },
            {
              label: t.translate('words.phone'),
              searchable: true,
              sortable : true,
              key: 'phone',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.phone)
            },
            {
              label: t.translate('words.city'),
              searchable: true,
              sortable : true,
              key: 'city',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.city)
            },
            {
              label: t.translate('words.postalCode'),
              searchable: true,
              sortable : true,
              key: 'postalCode',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.postalCode)
            },
            {
              label: t.translate('words.address'),
              searchable: true,
              sortable : true,
              key: 'address',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.address)
            },
            {
              label: t.translate('words.certExists'),
              searchable: false,
              sortable : false,
              key: 'certExists',
              type: TableWrapper.dataTypes.BOOLEAN,
              render: item => {
                if(item.certExists){
                  return <Chip className="chip-green">{'Ada'}</Chip>
                } else {
                  item.certExists = false
                  return <Chip className="chip-red">{'Tidak Ada'}</Chip>
                }
              }
            },
            {
              label: t.translate('words.certFilename'),
              searchable: false,
              sortable : false,
              key: 'certFilename',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.certFilename)
            },
            {
              label: t.translate('words.certExpiredDate'),
              searchable: false,
              sortable : false,
              key: 'certExpiredDate',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.certExpiredDate)
            },
            {
              label: t.translate('words.createdBy'),
              searchable: true,
              sortable : true,
              key: 'createdBy',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.createdBy)
            },
            {
              label: t.translate('words.createdDate'),
              searchable: true,
              sortable : true,
              key: 'createdAt',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.createdAt)
            },
            {
              label: t.translate('words.lastModifiedBy'),
              searchable: true,
              sortable : true,
              key: 'updateBy',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.updateBy)
            },
            {
              label: t.translate('words.lastModifiedDate'),
              searchable: true,
              sortable : true,
              key: 'updateAt',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.updateAt)
            },
          ]}
          actions={actions}
          itemActions={[
            new TableWrapper.action(`${t.translate('words.uploadSertel')}`, 'mdi mdi-tag', (item) => addSertel(item))
          ]}
          onFetchData={ async query => {
            let res = await getOrganizations();
            var getAll = await Bluebird.mapSeries(res, async (org, OrgIndex)=> {
              return new Promise(async (resolve)=> {
                setTimeout(async ()=> {
                  try {
                    let getSearch = await service.getCertStatus(org.npwp)
                    if(getSearch && getSearch.data && getSearch.data.data){
                      org = {
                        ...org,
                        ...getSearch.data.data
                      }
                      resolve(org)
                    } else {
                      resolve(org)
                    }
                  } catch(e){
                    resolve(org)
                  }
                }, 500 * OrgIndex)
              })
            })
            if(search.npwp){
              getAll = getAll.filter((d)=> {
                return d.npwp.indexOf(search.npwp) != -1  
              })
            }
            if(search.name){
              getAll = getAll.filter((d)=> {
                return d.name.indexOf(search.name) != -1  
              })
            }
            if(search.email){
              getAll = getAll.filter((d)=> {
                return d.email.indexOf(search.email) != -1  
              })
            }
            console.log(getAll)
            return {
              data: getAll,
              headers: {
                'x-total-count': getAll.length
              }
            }
          }}
          sideHeader={
            <DataForm 
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName = 'mdi mdi-magnify'
              submitLabel={`${t.translate('words.cari')}`}
              additionalAction={[
                {
                  show: true,
                  render: ()=> (
                    <Button style={{ marginRight: 15 }} onClick={()=> {
                      setSearch({})
                      localStorage.removeItem(`${baseId}-saved-search`)
                      setTimeout(()=> {
                        document.getElementsByClassName('mdi-reload')[0].click()
                      }, 1000)
                    }}>{t.translate('words.hapus')}</Button>
                  )
                }
              ]}
              onSubmit={(values, callback)=> {
                if(values['date.equals']) values['date.equals'] = moment(new Date(values['date.equals'])).format('YYYY-MM-DD')
                setSearch(values)
                localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
                setTimeout(()=> {
                  document.getElementsByClassName('mdi-reload')[0].click()
                  callback("", false, false)
                }, 1000)
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                        <p>{t.translate(`words.pencarian`)}</p>
                      </div>
                    </div>
                  )
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: t.translate(`words.npwp`),
                  key: 'npwp',
                  type: 'text',
                  mask: '##.###.###.#-###.###',
                  maskChar: '_',
                  maskValue: 'string',
                  width: '100%'
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate(`words.name`),
                  key: 'name',
                  type: 'text',
                  width: '100%'
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate(`words.email`),
                  key: 'email',
                  type: 'text',
                  width: '100%'
                },
              ]}
            />
          }
          showCommandbar={showCommandbar}
          showFilterPeriod={false}
        /> 
      </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(Organization))