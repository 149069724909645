import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpScheduleEmail.service'
import { toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import moment from 'moment'
import OpScheduleEmailDialog from './OpScheduleEmail.dialog'
import Modal from '../../libs/react-mpk/components/Modal'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, List, FontIcon, Divider, Dialog, ListItem } from 'react-md'

const OpScheduleEmailForm = ({ 
  authStore, 
  params, 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [date, setDate] = useState([{bulan : "", tanggal : ""}])
  const [dialogDokumen, setDialogDokumen] = useState(false)
  const [dialogData, setDialogData] = useState(false)
  const basePath = 'schedule-email'
  const baseId = 'mod-schedule-email-form'

  useEffect(() => {
    async function initData(){
      var bpform = JSON.parse(localStorage.getItem('schedule'))
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        let res = null
        let user = JSON.parse(localStorage.getItem('user'))
        let id = null
        let schedule = null

        if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
          id = match.params.id
        } 
        if(window.location.href.indexOf('open') != -1){
          id = match.params.companyId
        }
        if(user.authorities[0].name == "ROLE_EPPT_OWNER") {
          id = user.company.id
        }

        res = await service.findAll(params, id)
        schedule = await service.findById(res.data.id)

        setData({loading: false, content: res.data})
        setDate(schedule.data)
      }
    }
    initData()
  }, [])
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  async function handleDelete(value){
    setDate(
      date.filter((d)=>{
        return d.tanggal !== value
      })
    )
  }

  function addItem(){
    setDialogDokumen(true)
    setDialogData({})
  }

  function _closeDialog() {
    setDialogDokumen(false)
  }

  return (
    <>
    <Modal
        id="dokumen-form"
        aria-label="dokumen-form"
        title={t.translate('words.AddSchedule')}
        visible={dialogDokumen}
        onRequestClose={_closeDialog}
        style={{
          width: 800, 
          height: 300
        }}
        disableFocusOnMount={true}
      >
        <OpScheduleEmailDialog _closeDialog={_closeDialog} data={dialogData} />
      </Modal>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      // backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {
        var schedule = []
        if(key =='schedule'){
          var bulan = moment(value).format("M")
          var tanggal = moment(value).format("D")

          let settingEmailScheduleDetail = {tanggal, bulan};
        
          setDate(
            date.concat(
              settingEmailScheduleDetail
            )
          )
        
        }
      }}
      style ={{
        maxWidth : '80%'
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.active`),
          key: 'active',
          width: '50%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate('words.limitPerDay'),
          key: 'limitPerDay',
          type: 'number',
          validation: 'required',
          width: '50%'
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: t.translate('words.date'),
          key: 'schedule',
          type: 'date',
        },
        {
          render : (
            <List style={{width: '100%'}}>
              {Object.values(date).map((d, i)=>{
                return(
                <ListItem key={i} rightAddon={<FontIcon onClick={()=>{ handleDelete(d.tanggal)}} iconClassName="mdi mdi-delete"></FontIcon>}>
                  {"Tanggal : "+d.tanggal+" Bulan : "+ d.bulan}
                </ListItem>
                )
              })}
            </List>
          )
        }
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          
          formData.settingEmailEunifikasiScheduleDetails = date
          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }

          delete formData.schedule
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`${backTo}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
    </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpScheduleEmailForm))
