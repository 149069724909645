import React from 'react'
import { FontIcon } from 'react-md'
import renderHTML from 'react-render-html'

const Summary = ({
  data,
  className     = '',
}) => {
  return (
    <div className={`mpk-data-view-summary mpk-paper mpk-padding-N padding-all mpk-flex justify-between align-center ${className}`}>
      <div>
        <div className="mpk-font size-NS color-D3">{renderHTML(data.label)}</div>
        <div className="mpk-font weight-B size-XL">
          {data.value}
          {data.unit && (
            <span className="mpk-font size-S weight-L mpk-margin-XS margin-left">{renderHTML(data.unit)}</span>
          )}
        </div>
      </div>
      { data.trend && data.trend !== 0 && (
        <div className={`trend mpk-flex direction-column align-center ${data.trend >= 0 ? 'up' : 'down'}`}>
          <FontIcon iconClassName={data.trend >= 0 ? 'mdi mdi-arrow-up' : 'mdi mdi-arrow-down'}/>
          <span className="mpk-font size-S">{data.trend} {data.trendUnit && renderHTML(data.trendUnit)}</span>
        </div>
      )}
    </div>
  )
}

export default Summary
