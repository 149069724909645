import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/ebilling/setting-core/page');


service.coreStatus = async(data) => {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling/coreStatus`,
    data
  })
}

service.get = async(data)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling/setting-core`,
    data
  })
}

service.update = async(id, data) => {
  return http.request({
    method : http.methods.PUT,
    url : `/api/ebilling/setting-core`,
    data
  })
}

export default service;