import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null

if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/signer');
} else {
  service = new CrudService('/signer');
}

var baseUrl = `/signer`
if(AppMode.onPremise){
  baseUrl = `/api/eunifikasi/signer`
}

service.getActiveSigner = async(npwpProfile)=> {
    return http.request({
      method: http.methods.GET,
      url: `${baseUrl}/active?npwpProfile=${npwpProfile}`,
    })
}

service.getDefault = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `${baseUrl}/default/${id.id}`,
    query: {
      used: true
    }
  })
}

service.getNonActive = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `${baseUrl}/active/${id.id}`,
    query: {
      active: false
    }
  })
}

service.getActive = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `${baseUrl}/active/${id.id}`,
    query: {
      active: true
    }
  })
}

service.getRecon = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `${baseUrl}/recon/${id.id}`,
  })
}


export default service;