import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './DaftarSptArchive.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import { Chip, ListItem, Button } from 'react-md'
import errorService from '../../services/errorService'
import { reject } from 'lodash'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import DataForm from '../../libs/react-mpk/components/DataForm'
import AppMode from '../../AppMode'

const DaftarHistorySpt = ({
    className       = '',
    showCommandbar  = true,
    history,
    match,
    modalStore
}) => {

    const basePath = `daftar-history-spt`
    const [data, setData] = useState({loading: true, content: {}})
    const baseId = 'mod-daftar-spt-history'
    let [search, setSearch] = useState({})
    
    useEffect(() => {
        if(localStorage.getItem(`${baseId}-saved-search`)){
            setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
          }
        async function initData(){
          try {
              let history = await service.history(match.params.id)
              setData({
                loading: false,
                content: history.data
              })
          } catch(e){
            errorService(e)
          }
        }
        initData()
    }, [])

    async function hapusExc(item) {
        try {
            var r = await service.delete(item.id)

            if(r.data && r.data.status == 1) {
            window.open(r.data.data.url)
            iziToast.success({
                message : r.data.message
            })
            } else {
            iziToast.info({
                message : r.data.message
            })
            }
        } catch(e) {
            errorService(e)
        }
    }

    async function hapus(item) {
        modalStore.showConfirm({
            title : t.translate('words.delete'),
            children : t.translate('words.hapusIni'),
            onSubmit : (callback) => {
            hapusExc(item)
            callback()
            }
        })
    }

    return (
      <>
        <TableWrapper
          baseId = "mod-daftar-spt-history"
          title = {t.translate('modules.daftarSptArchive.history')}
          className = {className}
          defaultData ={[]}
          showActionColumn = {false}
          defaultSortBy = "auditDate"
          isShowFilter={false}
          useFilter={false}
          onFetchData = {(params) => {
              return (
                  new Promise(async (resolve, reject) => {
                    try{  
                      params = {
                        ...search,
                        ...params
                      }
                      let getPage = await service.history(match.params.id, params)
                      getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                      resolve(getPage)
                    } catch(e){
                      resolve()
                      errorService(e)
                    }
                  })
              )
          }}
          // sideHeader={
          //   <DataForm 
          //     baseId={`${baseId}-search`}
          //       defaultData={search}
          //       submitIconClassName = 'mdi mdi-magnify'
          //       submitLabel={`${t.translate('words.cari')}`}
          //       additionalAction={[
          //         {
          //           show: true,
          //           render: ()=> (
          //             <Button style={{ marginRight: 15 }} onClick={()=> {
          //               setSearch({})
          //               localStorage.removeItem(`${baseId}-saved-search`)
          //               // DataForm.reset(`${baseId}-search`)
          //               setTimeout(()=> {
          //                 document.getElementsByClassName('mdi-reload')[0].click()
          //               }, 1000)
          //             }}>{t.translate('words.hapus')}</Button>
          //           )
          //         }
          //       ]}
          //       onSubmit={(values, callback)=> {
          //         if(values['createdDate.auditDate']) values['createdDate.auditDate'] = new Date(values['createdDate.auditDate'])
          //         setSearch(values)
          //         localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
          //         setTimeout(()=> {
          //           document.getElementsByClassName('mdi-reload')[0].click()
          //           callback("", false, false)
          //         }, 1000)
          //       }}
          //       definitions={[
          //         {
          //           render: (
          //             <div className="mpk-data-filter">
          //               <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
          //                 <p>{t.translate(`words.pencarian`)}</p>
          //               </div>
          //             </div>
          //           )
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.revisionId`),
          //           key: 'revisionId.equals',
          //           type: 'text'
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.type`),
          //           key: 'type.contains',
          //           type: 'text'
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.auditBy`),
          //           key: 'auditBy.contains',
          //           type: 'text'
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.auditDate`),
          //           key: 'auditDate.equals',
          //           type: 'date'
          //         },
          //     ]}
          //   />
          // }
          sideHeader={
            <div></div>
          }
          columns = {[
              {
                  label : t.translate('words.revisionId'),
                  searchable : false,
                  sortable : true,
                  key : 'revisionId',
                  type : TableWrapper.dataTypes.NUMBER,
                  render : (item) => (item.revisionId)
              },
              {
                  label : t.translate('words.type'),
                  searchable : true,
                  sortable : true,
                  key : 'type',
                  type : TableWrapper.dataTypes.STRING,
                  render : (item) => (item.type)
              },
              {
                  label : t.translate('words.auditBy'),
                  searchable : true,
                  sortable : true,
                  key : 'auditBy',
                  type : TableWrapper.dataTypes.STRING,
                  render : (item) => (item.auditBy)
              },
              {
                  label : t.translate('words.auditDate'),
                  searchable : true,
                  sortable : true,
                  key : 'auditDate',
                  type : TableWrapper.dataTypes.STRING,
                  render : (item) => (item.auditDate)
              },
              // {
              //     label : t.translate('words.data'),
              //     searchable : true,
              //     sortable : true,
              //     key : 'data',
              //     type : TableWrapper.dataTypes.STRING,
              //     render : (item) => (JSON.stringify(item.data))
              // },
              {
                  label: t.translate('words.npwp'),
                  searchable: true,
                  sortable : true,
                  key: 'npwp',
                  type: TableWrapper.dataTypes.STRING,
                  render: item => (JSON.stringify(item.data.npwp))
                },
                {
                  label: t.translate('words.name'),
                  searchable: true,
                  sortable : true,
                  key: 'name',
                  type: TableWrapper.dataTypes.STRING,
                  render: item => (JSON.stringify(item.data.name))
                },
                {
                  label: t.translate('words.month'),
                  searchable: true,
                  sortable : true,
                  key: 'month',
                  type: TableWrapper.dataTypes.NUMBER,
                  render: item => (JSON.stringify(item.data.month))
                },
                {
                  label: t.translate('words.year'),
                  searchable: true,
                  sortable : true,
                  key: 'year',
                  type: TableWrapper.dataTypes.NUMBER,
                  render: item => (JSON.stringify(item.data.year))
                },
                {
                  label: t.translate('words.rev'),
                  searchable: true,
                  sortable : true,
                  key: 'rev',
                  type: TableWrapper.dataTypes.NUMBER,
                  render: item => (JSON.stringify(item.data.rev))
                },
                {
                  label: t.translate('words.status'),
                  searchable: true,
                  sortable : true,
                  key: 'status',
                  type: TableWrapper.dataTypes.STRING,
                  render: item => (<Chip>{JSON.stringify(item.data.status).toUpperCase()}</Chip>)
                },
                {
                  label: t.translate('words.message'),
                  searchable: true,
                  sortable : true,
                  key: 'message',
                  type: TableWrapper.dataTypes.STRING,
                  render: item => (JSON.stringify(item.data.message))
                },
              //   {
              //     label: t.translate('words.createdBy'),
              //     searchable: true,
              //     sortable : true,
              //     key: 'createdBy',
              //     type: TableWrapper.dataTypes.STRING,
              //     render: item => (JSON.stringify(item.data.createdBy))
              //   },
              //   {
              //     label: t.translate('words.createdDate'),
              //     searchable: true,
              //     sortable : true,
              //     key: 'createdDate',
              //     type: TableWrapper.dataTypes.STRING,
              //     render: item => (JSON.stringify(item.data.createdDate))
              //   },
              //   {
              //     label: t.translate('words.lastModifiedBy'),
              //     searchable: true,
              //     sortable : true,
              //     key: 'lastModifiedBy',
              //     type: TableWrapper.dataTypes.STRING,
              //     render: item => (JSON.stringify(item.data.lastModifiedBy))
              //   },
              //   {
              //     label: t.translate('words.lastModifiedDate'),
              //     searchable: true,
              //     sortable : true,
              //     key: 'lastModifiedDate',
              //     type: TableWrapper.dataTypes.STRING,
              //     render: item => (JSON.stringify(item.data.lastModifiedDate))
              //   },
          ]}
          actions = {[]}
          itemActions = {[]}
          showCommandbar={showCommandbar}
          showFilterePeriod={false}
          actions={[
              new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
                if(AppMode.onPremise){
                  history.push(`/onpremise/archive/daftar-spt`)
                } else {
                  history.push(`/product/company/${match.params.companyId}/archive/daftar-spt`)
                }
              }, true)
          ]}
        />
      </>
    )
}

export default inject('temporaryStore', 'modalStore')(observer(DaftarHistorySpt))