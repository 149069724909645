import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';

let service = new CrudService('/spt');

service.getCheckSpt = async(query) => {
  return http.request({
    method: http.methods.POST,
    url: `/spt/check`,
    query,
    config: {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
    data: []
    }
  })
}

service.getPostingSpt = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `/spt/posting/${id}`,
  })
}

service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/ssp/spt/summary/total`,
    query
  })
}

service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/spt/debt`,
    query
  })
}

export default service;