import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Button } from 'react-md'
import moment from 'moment'

const OpScheduleDialog = ({ 
  authStore, 
  envStore, 
  temporaryStore, 
  match, 
  history, 
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})

  const baseName = `OpScheduleDialog`
  const basePath = `schedule-email`

  useEffect(() => {
    async function initData(){
      setData({
        loading: false,
        content: props.data
      })
    }
    initData()
  }, [])
  
  return (
    <>
      <FormWrapper
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        definitions={[
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.date`),
            key: 'tanggal',
            width: '100%',
            type: 'date',
            validation: 'required'
          },
        ]}
        additionalAction={[
          {
            show: true,
            render: ()=> (
              <Button style={{ marginRight: 15 }} onClick={()=> props._closeDialog()}>{t.translate('words.close')}</Button>
            )
          }
        ]}
        onSubmit={async (data, callback) => {
            var date ={tanggal : {}, bulan : {}}
            var tanggal = moment(data.tanggal).format("DD")
            var month = moment(data.tanggal).format("DD")
            date = {tanggal : tanggal, bulan : month}
            localStorage.setItem('schedule', JSON.stringify(date))
            // props._setDokumens(data)
            props._closeDialog();
        }}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(OpScheduleDialog))
