import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'
import axios from 'axios'

let service = null
let serviceUrl = '/bp/dn'
let serviceObject = '/bp/object'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/dn');
  serviceUrl = '/api/eunifikasi/bp/dn'
  serviceObject = '/api/eunifikasi/bp/object'
} else {
  service = new CrudService('/bp/dn');
}

service.exportCurrent = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/search`,
    query
  })
}

service.getTaxObjectCode = async(type)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceObject}?size=80&&type.equals=${type}`,
  })
}

service.getReport = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/${id}`,
  })
}

service.postReport = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/report`,
    data
  })
}

service.getRequestReport = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/request/report/${id}`
  })
}

service.getReupload = async(id)=> {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/reupload/${id}`,
  })
}

service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/summary`,
    query
  })
}

service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/summary`,
    query
  })
}

service.history = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`
  })
}

service.sendMail = async(data, email) => {
  return http.request({
    method : http.methods.POST,
    url : `${serviceUrl}/send`,
    data,
    config: {
      params: email
    }
  })
}

service.sendMailBulk = async(data, email)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/send/search`,
    data,
    config: {
      params: {
        email: email
      }
    }
  })
}

service.sendMailBulkOp = async(data, email)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/send/search`,
    query: data,
    // config: {
    //   params: data
    // }
  })
}

export default service;
