import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import ErrorService from '../../services/errorService'
import Inquiry from './Inquiry.form'
import DashboarSpt from './../DashboardSpt/DashboardSpt'

const DashboardForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: false, content: {}})
  const [organization, setOrganization] = useState([])

  const baseName = `dashboard`
  const basePath = `dashboard-spt`

  const getOrganizations = async ()=> {
    let organizations = UtilsService.getOrganizations(toJS(authStore))
    organizations.map((d)=> {
      d.label = d.npwp + " - " + d.name
    })
    setOrganization(organizations)
  }

  // useEffect(() => {
  //   getOrganizations()
  //   initData()
  // }, [])

  async function initData(){}
  
  return (
    <>
      <DashboarSpt match={match} inSpt={true} />
      {/* <Inquiry match={match}/> */}
    </>
  )
}

export default inject('authStore', 'envStore')(observer(DashboardForm))
