import React from 'react'
import logoDJP from "../../assets/logo-djp.png";
import logoDJPInverse from "../../assets/logo-djp-inverse.png";
import Flex from '../Flex'

const MitraDJP = ({
  className   = '',
  inverse     = false,
  ...props
}) => {
  return (
    <Flex 
      className={`mpk-mitra-djp ${className}`}
      align={Flex.properties.align.CENTER}
      {...props}
    >
      <img src={inverse ? logoDJPInverse : logoDJP} alt="logo-djp"/>
      <div 
        className="mpk-margin-S margin-left"
        style={{
          lineHeight: 1
        }}
      >
        <div className="mpk-font size-XS">MITRA</div>
        <div className="mpk-font weight-B">djp</div>
      </div>
    </Flex>
  )
}

export default MitraDJP
