import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpMasterKpp.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button } from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'

const OpMasterKpp = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})

  const baseId = 'mod-op-master-kpp'
  const basePath = 'master-kpp'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-serach`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  })

  async function initData() {}

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.deleteById(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }
  
  return (
    <TableWrapper
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="code"
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`${baseId}-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values['searchKey.equals'] = values['name.equals'] || values['code.equals'] || values['address.equals']
            setSearch(values)
            localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.code`),
              key: 'code.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.address`),
              key: 'address.equals',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.code'),
          searchable: true,
          sortable : true,
          key: 'code',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.code),
        },
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable : true,
          key:'name',
          render: item => (item.name),
        },
        {
          label: t.translate('words.address'),
          searchable: true,
          sortable : true,
          key:'address',
          render: item => (item.address),
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => history.push(`${basePath}/${item.id}`), true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => deleteById(item), true),
      ]}
      onFetchData={(params)=> {
        params = {
          ...search,
          ...params
        }
        return (
          new Promise(async (resolve, reject)=> {
            try {
              await initData()
              let getPage = null
              getPage = await service.get(params)
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpMasterKpp))
