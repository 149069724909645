import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './BpArchive.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import { Chip, ListItem, List, Button } from 'react-md'
import ErrorService from './../../services/errorService'
import { format } from '../../libs/react-mpk/services/number.service'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import DataForm from '../../libs/react-mpk/components/DataForm'
import AppMode from '../../AppMode'

const BpArchiveHistory = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  modalStore,
  temporaryStore
}) => {

  const basePath = `bp`
  const [data, setData] = useState({loading: true, content: {}})
  const baseId = 'mod-bp-archive-history'
  let [search, setSearch] = useState({})

  useEffect(() => {
    async function initData(){
      try {
          let history = await service.history(match.params.id)
          setData({
            loading: false,
            content: history.data
          })
      } catch(e){
        errorService(e)
      }
    }
    initData()
  }, [])
 
  async function hapusExc(item) {
    try {
      var r = await service.delete(item.id)

      if(r.data && r.data.status == 1) {
        window.open(r.data.data.url)
        iziToast.success({
          message : r.data.message
        })
      } else {
        iziToast.info({
          message : r.data.message
        })
      }
    } catch(e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }
  
  var actions = [
    new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
      if(AppMode.onPremise){
        history.push(`/onpremise/archive/bp`)
      } else {
        history.push(`/product/company/${match.params.companyId}/${basePath}`)
      }
    }, true)
  ]
  if(window.location.href.indexOf('open') != -1){
    actions = [
      new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
        if(AppMode.onPremise){
          history.push(`/onpremise/daftar-spt-archive/open/${match.params.id}/${basePath}`)
        } else {
          history.push(`/product/company/${match.params.companyId}/daftar-spt/open/${match.params.id}/${basePath}`)
        }
      }, true)
    ]
  }

  return (
    <>
      <TableWrapper
        baseId = "mod-bp-history"
        title = {t.translate('modules.bp.history')}
        className = {className}
        defaultData ={[]}
        defaultSortBy = "auditDate"
        isShowFilter={false}
        showActionColumn = {false}
        useFilter={false}
        onFetchData = {(params) => {
          return (
              new Promise(async (resolve, reject) => {
                try{  
                  params = {
                    ...search,
                    ...params
                  }
                  let getPage = await service.history(match.params.id, params)
                  getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                  resolve(getPage)
                } catch(e){
                  resolve()
                  ErrorService(e)
                }
              })
          )
        }}
        // sideHeader={
        //   <DataForm 
        //     baseId={`${baseId}-search`}
        //       defaultData={search}
        //       submitIconClassName = 'mdi mdi-magnify'
        //       submitLabel={`${t.translate('words.cari')}`}
        //       additionalAction={[
        //         {
        //           show: true,
        //           render: ()=> (
        //             <Button style={{ marginRight: 15 }} onClick={()=> {
        //               setSearch({})
        //               localStorage.removeItem(`${baseId}-saved-search`)
        //               // DataForm.reset(`${baseId}-search`)
        //               setTimeout(()=> {
        //                 document.getElementsByClassName('mdi-reload')[0].click()
        //               }, 1000)
        //             }}>{t.translate('words.hapus')}</Button>
        //           )
        //         }
        //       ]}
        //       onSubmit={(values, callback)=> {
        //         if(values['createdDate.auditDate']) values['createdDate.auditDate'] = new Date(values['createdDate.auditDate'])
        //         setSearch(values)
        //         localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
        //         setTimeout(()=> {
        //           document.getElementsByClassName('mdi-reload')[0].click()
        //           callback("", false, false)
        //         }, 1000)
        //       }}
        //       definitions={[
        //         {
        //           render: (
        //             <div className="mpk-data-filter">
        //               <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
        //                 <p>{t.translate(`words.pencarian`)}</p>
        //               </div>
        //             </div>
        //           )
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.revisionId`),
        //           key: 'revisionId.equals',
        //           type: 'text'
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.type`),
        //           key: 'type.contains',
        //           type: 'text'
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.auditBy`),
        //           key: 'auditBy.contains',
        //           type: 'text'
        //         },
        //         {
        //           inputType: inputTypes.INPUT,
        //           label: t.translate(`words.auditDate`),
        //           key: 'auditDate.equals',
        //           type: 'date'
        //         },
        //     ]}
        //   />
        // }
        sideHeader={
          <div></div>
        }
        columns = {[
            {
                label : t.translate('words.revisionId'),
                searchable : false,
                sortable : true,
                key : 'revisionId',
                type : TableWrapper.dataTypes.NUMBER,
                render : (item) => (item.revisionId)
            },
            {
                label : t.translate('words.type'),
                searchable : true,
                sortable : true,
                key : 'type',
                type : TableWrapper.dataTypes.STRING,
                render : (item) => (item.type)
            },
            {
                label : t.translate('words.auditBy'),
                searchable : true,
                sortable : true,
                key : 'auditBy',
                type : TableWrapper.dataTypes.STRING,
                render : (item) => (item.auditBy)
            },
            {
                label : t.translate('words.auditDate'),
                searchable : true,
                sortable : true,
                key : 'auditDate',
                type : TableWrapper.dataTypes.STRING,
                render : (item) => (item.auditDate)
            },
            {
              label: t.translate('words.no'),
              searchable: true,
              sortable : true,
              key: 'no',
              type: TableWrapper.dataTypes.STRING,
              render: item => {
                if(item.no){
                  return item.no
                } else {
                  return ''
                }
              }
            },
            {
              label: t.translate('words.type'),
              searchable: true,
              sortable : true,
              key: 'type',
              type: TableWrapper.dataTypes.STRING,
              render : (item) => (JSON.stringify(item.data.type))
            },
            {
              label: t.translate('words.objectCode'),
              searchable: true,
              sortable : true,
              key: 'objectCode',
              type: TableWrapper.dataTypes.STRING,
              render: item => (JSON.stringify(item.data.objectCode))
            },
            {
              label: t.translate('words.name'),
              searchable: true,
              sortable : true,
              key: 'name',
              type: TableWrapper.dataTypes.STRING,
              render: item => (JSON.stringify(item.data.name))
            },
            {
              label: t.translate('words.identity'),
              searchable: true,
              sortable : true,
              key: 'identity',
              type: TableWrapper.dataTypes.STRING,
              render: item => (JSON.stringify(item.data.identity))
            },
            {
              label: t.translate('words.bruto'),
              searchable: true,
              sortable : true,
              key: 'bruto',
              type: TableWrapper.dataTypes.NUMBER,
              render: item => (JSON.stringify(item.data.bruto))
            },
            {
              label: t.translate('words.rates'),
              searchable: true,
              sortable : true,
              key: 'rates',
              type: TableWrapper.dataTypes.NUMBER,
              render: item => (JSON.stringify(item.data.rates))
            },
            {
              label: t.translate('words.pph'),
              searchable: true,
              sortable : true,
              key: 'pph',
              type: TableWrapper.dataTypes.NUMBER,
              render: item => (JSON.stringify(item.data.pph))
            },
            {
              label: t.translate('words.date'),
              searchable: true,
              sortable : true,
              key: 'date',
              type: TableWrapper.dataTypes.STRING,
              render: item => (JSON.stringify(item.data.date))
            },
            {
              label: t.translate('words.status'),
              searchable: true,
              sortable : true,
              key: 'status',
              type: TableWrapper.dataTypes.STRING,
              render: item => {
                if(JSON.stringify(item.data.status) == "UPLOADING"){
                  return <Chip style={{ background: "#FFC108", color: 'white' }}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                } else if(JSON.stringify(item.data.status) == "COMPLETED"){
                  return <Chip style={{ background: "#2096F3", color: 'white' }}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                } else if(JSON.stringify(item.data.status) == "FINISH"){
                  return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                } else if(JSON.stringify(item.data.status) == "FAILED"){
                  return <Chip style={{ background: "#F44336", color: 'white' }}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                } else if(JSON.stringify(item.data.status) == "DELETED"){
                  return <Chip style={{ background: "grey", color: 'white' }}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                } else {
                  return <Chip style={{ background: "black", color: 'white' }}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                }
              }
            },
            {
              label: t.translate('words.message'),
              searchable: true,
              sortable : true,
              key: 'message',
              type: TableWrapper.dataTypes.STRING,
              render: item => (JSON.stringify(item.data.message))
            },
        ]}
        itemActions = {[]}
        showCommandbar={showCommandbar}
        showFilterePeriod={false}
        actions={actions}
      />
    </>
  )
}
export default inject('temporaryStore', 'modalStore')(observer(BpArchiveHistory))