import React, {useEffect, useState} from 'react'
import { Router, Switch, Route } from "react-router-dom";
import onp from '../libs/custom/onp';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import t from 'counterpart'
import { DashboardSample, OpLogPdfInduk, OpProfileForm, OpLogPdfBp, OpLogPdfBpe, ImportBp, ImportSetorSendiri, OpApprovalFlowForm, OpApprovalFlowTable, ImportDossDopp, ImportSspPbk, ImportLogCsv, OpLogin, OpSettingEmailContent, OpImportInquiryForm, OpLogExportSsp, OpPaymentOrder, OpPaymentNotification, OpSettingCoreForm, OpImportCredentialsEbilling, OpImportCredentialsEbillingForm, OpUserLogin, OpPerusahaan, OpPerusahaanForm, OpUserForm, OpUser, OpOrganizationForm,OpOrganization, TableSample, FormSample, EbillingWidget, DataEsspView, DataEsspForm, Bp26View, BpHistory, PbkHistory, Bp26History, LawanTransaksiForm, Doss2HistoryView, LawanTransaksiView, BpView, Doss2View, DaftarSptView, DaftarHistorySpt, DaftarSptForm, SspView, SspForm, SspHistory, PbkView, PbkForm, SignerForm, SignerView, DaftarBpeView, ImportData, EksporData, OrganizationForm, UserForm, ImportLog, User, Organization, OpMasterKpp, OpMasterKppForm, OpLogSignIn, OpLogValidation, OpSettingEmail, OpScheduleEmail, OpSettingEmailForm, OpScheduleEmailForm, OpImportPenggunaForm, OpImportPengguna, OpOwnerForm, OpOwner, OpUserOrgForm, OpUserOrg, OpImportOrgForm, OpImportOrg, OpSetEunifikasiForm, OpInquiry, OpPaymentAccount, OpPaymentAccountForm, OpLogExportInquiry, OpLogCetakOrder, OpLogNotification, OpLogCetakBpn, OpLogCetakPo, OpLogCetakPn, OpImportInquiry, OpImporteSsp, OpImporteSspForm, OpPaymentNotificationDetails, OpFileTransaksiForm, OpPaymentOrderForm, OpBniDirectForm, OpBniCmsForm, OpMandiriCmsForm, OpFinpayForm, OpBriCmsForm, OpRoleTemplate, OpRoleTemplateForm, OpAboutForm, OpInquiryForm, OpImportInquiryDetail, OpImporteSspDetail, OpLogEmail, LogExportView, LogExportForm, OpImportPenggunaDetail, OpImportOrgDetail, OpImportCredentialsEbillingDetail, OpImportUserOrgDetail, OpImportUserOrg, OpImportSignerView, OpImportSignerDetail, DashboardSpt} from '../modules'
// import { DashboardSample, OpProfileForm,OpLogPdfBp, OpLogPdfBpe, ImportBp, ImportSetorSendiri, OpLogPdfInduk, OpApprovalFlowForm, OpApprovalFlowTable, ImportDossDopp, ImportSspPbk, ImportLogCsv, OpLogin, OpSettingEmailContent, OpImportInquiryForm, OpLogExportSsp, OpPaymentOrder, OpPaymentNotification, OpSettingCoreForm, OpImportCredentialsEbilling, OpImportCredentialsEbillingForm, OpUserLogin, OpPerusahaan, OpPerusahaanForm, OpUserForm, OpUser, OpOrganizationForm,OpOrganization, TableSample, FormSample, EbillingWidget, DataEsspView, DataEsspForm, Bp26View, BpHistory, PbkHistory, Bp26History, LawanTransaksiForm, Doss2HistoryView, LawanTransaksiView, BpView, Doss2View, DaftarSptView, DaftarHistorySpt, DaftarSptForm, SspView, SspForm, SspHistory, PbkView, PbkForm, SignerForm, SignerView, DaftarBpeView, ImportData, EksporData, OrganizationForm, UserForm, ImportLog, User, Organization, OpMasterKpp, OpMasterKppForm, OpLogSignIn, OpLogValidation, OpSettingEmail, OpScheduleEmail, OpSettingEmailForm, OpScheduleEmailForm, OpImportPenggunaForm, OpImportPengguna, OpOwnerForm, OpOwner, OpUserOrgForm, OpUserOrg, OpImportOrgForm, OpImportOrg, OpSetEunifikasiForm, OpInquiry, OpPaymentAccount, OpPaymentAccountForm, OpLogExportInquiry, OpLogCetakOrder, OpLogNotification, OpLogCetakBpn, OpLogCetakPo, OpLogCetakPn, OpImportInquiry, OpImporteSsp, OpImporteSspForm, OpPaymentNotificationDetails, OpFileTransaksiForm, OpPaymentOrderForm, OpBniDirectForm, OpBniCmsForm, OpMandiriCmsForm, OpFinpayForm, OpBriCmsForm, OpRoleTemplate, OpRoleTemplateForm, OpAboutForm, OpInquiryForm, OpImportInquiryDetail, OpImporteSspDetail, OpLogEmail, OpImportPenggunaDetail, OpImportOrgDetail, OpImportCredentialsEbillingDetail, OpImportUserOrgDetail, OpImportUserOrg} from '../modules'
import { ArchiveBpView, ArchiveBpForm, ArchiveBpHistory, ArchiveBp26View, ArchiveBp26Form, ArchiveBp26History, ArchiveDaftarBpeView, ArchiveDaftarSptView, ArchiveDoss2View, ArchiveDoss2Form, ArchiveDoss2HistoryView, ArchiveDaftarHistorySpt, ArchiveSspView, ArchiveSspForm, ArchiveSspDashboardForm, ArchiveSspHistory, ArchivePbkView, ArchivePbkForm, ArchivePbkHistory } from '../modules'
import OpLoginService from './../modules/OpLogin/OpLogin.service'
import axios from 'axios'
import OpSetContentEmailTable from '../modules/OpSetContentEmail/OpSetContentEmail.table';
import OpSetContentEmailForm from '../modules/OpSetContentEmail/OpSetContentEmail.form';

const Onpremise = ({match, history}) => {

  let [user, setUser] = useState({})
  let [activeSidebar, setActiveSidebar] = useState([])

  useEffect(async () => {
    try {
      // set header
      var res = JSON.parse(localStorage.getItem('token'))
      axios.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`
      axios.defaults.withCredentials = true;
      let me = await OpLoginService.me()
      setUser(me.data)
      localStorage.setItem('user', JSON.stringify(me.data))
    } catch(e){
      window.location.href = '/#/login'
    }
    try {
      document.getElementsByClassName('mpk-appbar-shortcut')[0].style.display = 'none'
    } catch(e){}
  }, [])

  useEffect(async () => {
    sidebarInit()
  }, [user])

  var sidebarInit = ()=> {
    var isAdmin = false
    var isOwner = false
    var isUser  = false
    var activeSidebar = []
    if(user){
      try {
        if(user.authorities[0].name == 'ROLE_EPPT_ADMIN') isAdmin = true
        if(user.authorities[0].name == 'ROLE_EPPT_OWNER') isOwner = true
        if(user.authorities[0].name == 'ROLE_EPPT_USER') isUser = true
      } catch(e){}
      var sidebarMenuAdmin = [
        {
          "label": `${t.translate('menu.adminMenu')}`,
          "children":[
            {
              "label": `${t.translate('menu.logged-in')}`,
              "index": "logged-in",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logged-in`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.perusahaan')}`,
              "index": "perusahaan",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/company`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.organization')}`,
              "index": "organization",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/organization-op`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.user')}`,
              "index": "user",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/user-op`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.signInLog')}`,
              "index": "signInLog",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/signInLog`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.logValidasi')}`,
              "index": "logValidasi",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/validationLog`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.roleTemplate')}`,
              "index": "role, template, pengaturan, setting, bupot",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/onpremise/templateRole`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.flowApproval')}`,
              "index": "flowApproval",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/flow-approval`,
              "childPath": []
            },
          ]
        },
        {
          "label": `${t.translate('menu.setting')}`,
          "children":[
            {
              "label": `${t.translate('menu.email')}`,
              "index": "email",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/email`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.contentEmail')}`,
              "index": "contentEmail",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/email-content`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.scheduleEmail')}`,
              "index": "scheduleEmail",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/schedule-email`,
              "childPath": []
            },
          ]
        }
      ]
      var sidebarMenuOwner = [
        {
          "label": `${t.translate('menu.ownerMenu')}`,
          "children":[
            {
              "label": `${t.translate('menu.logged-in')}`,
              "index": "logged-in",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logged-in`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.pengguna')}`,
              "index": "pengguna",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/pengguna`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.owner')}`,
              "index": "owner",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/owner`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.organization')}`,
              "index": "organization",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/organization-op`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.userOrganisasi')}`,
              "index": "userOrganisasi",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/userOrganisasi`,
              "childPath": []
            },
          ]
        },
        {
          "label": `${t.translate('menu.setting')}`,
          "children":[
            {
              "label": `${t.translate('menu.email')}`,
              "index": "email",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/email`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.contentEmail')}`,
              "index": "contentEmail",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/email-content`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.scheduleEmail')}`,
              "index": "scheduleEmail",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/schedule-email`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.roleTemplate')}`,
              "index": "role, template, pengaturan, setting, bupot",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/onpremise/templateRole`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.pengaturanUnifikasi')}`,
              "index": "pengaturanUnifikasi",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/pengaturanUnifikasi`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.about')}`,
              "index": "tentang, company, about, setting, bupot",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/onpremise/about`,
              "childPath": []
            }
          ]
        },
        {
          "label": `${t.translate('menu.import')}`,
          "children":[
            {
              "label": `${t.translate('menu.importPengguna')}`,
              "index": "importPengguna",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/importPengguna`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.importOrganisasi')}`,
              "index": "importOrganisasi",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/importOrganisasi`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.importUserOrganisasi')}`,
              "index": "importUserOrganisasi",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/importUserOrganisasi`,
              "childPath": []
            },
            {
              "label": `${t.translate('menu.importCredentialsEbilling')}`,
              "index": "importCredentialsEbilling",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/importCredentialsEbilling`,
              "childPath": []
            },
          ]
        }
      ]
      var sidebarMenuUser = [
        {
          "label":`${t.translate('menu.mainMenu')}`
        },
        {
          "label": `${t.translate('menu.dashboard')}`,
          "children":[
            {
              "label":`${t.translate('modules.dashboard.title')}`,
              "index":"daftar-spt, daftar, spt, surat, pemberitahuan, tahunan, pajak, summary, dashboard",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/dashboardSpt`,
              "childPath":[]
            },
          ]
        },
        {
          "label": `${t.translate('menu.mainMenu')}`,
          "children":[
            {
              "label":`${t.translate('modules.daftarSpt.title')}`,
              "index":"daftar-spt, daftar, spt, surat, pemberitahuan, tahunan, pajak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/daftar-spt`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.bp.menuTitle')}`,
              "index":"daftar-bp, bp, dn, dalam, negeri, dalam-negeri, bukti, potong",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/bp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.bp26.menuTitle')}`,
              "index":"daftar-bp-26, bp, ln, luar, negeri, luar-negeri, bukti, potong",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/bp26`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.doss2.menuTitle')}`,
              "index":"daftar-pph-setor-sendiri, daftar, pph, setor, sendiri, ss",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/doss2`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.ssp')}`,
              "index":"ssp, daftar, setoran, surat",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/ssp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.pbk')}`,
              "index":"pbk, daftar, pajak, pemindahbukuan, buku, pindah",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/pbk`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.daftar-bpe.title')}`,
              "index":"daftar-bpe, e, elektronik, bukti, penerimaan, bpe",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/daftar-bpe`,
              "childPath":[]
            }
          ]
        },
        {
          "label": `${t.translate('menu.mainMenuArchived')}`,
          "children":[
            {
              "label":`${t.translate('modules.daftarSpt.title')}`,
              "index":"daftar-spt, daftar, spt, surat, pemberitahuan, tahunan, pajak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/archive/daftar-spt`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.bp.menuTitle')}`,
              "index":"daftar-bp, bp, dn, dalam, negeri, dalam-negeri, bukti, potong",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/archive/bp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.bp26.menuTitle')}`,
              "index":"daftar-bp-26, bp, ln, luar, negeri, luar-negeri, bukti, potong",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/archive/bp26`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.doss2.menuTitle')}`,
              "index":"daftar-pph-setor-sendiri, daftar, pph, setor, sendiri, ss",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/archive/doss2`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.ssp')}`,
              "index":"ssp, daftar, setoran, surat",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/archive/ssp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.pbk')}`,
              "index":"pbk, daftar, pajak, pemindahbukuan, buku, pindah",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/archive/pbk`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.daftar-bpe.title')}`,
              "index":"daftar-bpe, e, elektronik, bukti, penerimaan, bpe",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/archive/daftar-bpe`,
              "childPath":[]
            }
          ]
        },
        {
          "label": `${t.translate('menu.setting')}`,
          "children": [
            {
              "label":`${t.translate('modules.lawanTransaksi.title')}`,
              "index":"lawan-transaksi, pengaturan, transaksi, lawan, lt",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/lawan-transaksi`,
              "childPath":[]
            },
            {
              "label":`${t.translate('modules.signer.title')}`,
              "index":"tanda, penandatangan, tangan, signer, tanda tangan",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/signer`,
              "childPath":[]
            },
          ]
        },
        {
          "label": `${t.translate('menu.import')}`,
          "children":[
            {
              "label":`${t.translate('menu.importBpExcel')}`,
              "index":"import, bukti potong",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/import`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.importSetorSendiri')}`,
              "index":"import, setor, sendiri",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/import-setor-sendiri`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.importDossDopp')}`,
              "index":"import, doss, dopp",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/import-doss-dopp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.importSspPbk')}`,
              "index":"import, ssp, pbk",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/import-ssp-pbk`,
              "childPath":[]
            },
            {
              "label": `${t.translate('menu.importSigner')}`,
              "index": "importSigner, penandatangan, ttd, tanda, tangan",
              "resourceUri": "",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/importSigner`,
              "childPath": []
            },
          ]
        },
        // {
        //   "label": `${t.translate('menu.billing')}`,
        //   "children":[
        //     {
        //       "label": `${t.translate('menu.setting')}`,
        //       "index" : "setting, pengaturan, ebilling",
        //       "resource" : "",
        //       "iconClassName" : "mdi mdi-layout",
        //       "path" : `/onpremise/pengaturan`,
        //       "childPath" : []
        //     },
        //     {
        //       "label" : `${t.translate('menu.inquiry')}`,
        //       "index" : "data, inquiry, ebilling",
        //       "resource" : "",
        //       "iconClassName": "mdi mdi-layout",
        //       "path" : `/onpremise/inquiry`,
        //       "childPath": []
        //     },
        //     {
        //       "label":`${t.translate('menu.dataEssp')}`,
        //       "index":"data essp, daftar ssp",
        //       "resourceUri":"",
        //       "iconClassName":"mdi mdi-layout",
        //       "path":`/onpremise/essp`,
        //       "childPath":[]
        //     },
        //     {
        //       "label" : `${t.translate('menu.paymentOrder')}`,
        //       "index" : "payment, bayar, pesanan, order",
        //       "resource" : "",
        //       "iconClassName" : "mdi mdi-layout",
        //       "path": `/onpremise/paymentOrder`,
        //       "childPath": []
        //     },
        //     {
        //       "label" : `${t.translate('menu.paymentNotif')}`,
        //       "index" : "payment, bayar, pesanan, order",
        //       "resource" : "",
        //       "iconClassName" : "mdi mdi-layout",
        //       "path": `/onpremise/paymentNotification`,
        //       "childPath": []
        //     },
        //     {
        //       "label" : `${t.translate('menu.paymentAccount')}`,
        //       "index" : "payment, bayar, pesanan, order",
        //       "resource" : "",
        //       "iconClassName" : "mdi mdi-layout",
        //       "path": `/onpremise/paymentAccount`,
        //       "childPath": []
        //     },
        //     // {
        //     //   "label":`${t.translate('menu.billingWidget')}`,
        //     //   "index":"billing, e-billing, widget, payment, order",
        //     //   "resourceUri":"",
        //     //   "iconClassName":"mdi mdi-layout",
        //     //   "path":`/onpremise/ebilling-widget`,
        //     //   "childPath":[]
        //     // }
        //   ]
        // },
        {
          "label" : `${t.translate('menu.importBilling')}`,
          "children": [
            {
              "label" : `${t.translate('menu.importInquiry')}`,
              "index" : "inquiry, import, billing, ebilling",
              "resource": "",
              "path": `/onpremise/importInquiry`,
              "iconClassname": "mdi mdi-layout",
              "childPath": []
            },
            {
              "label" : `${t.translate('menu.importEssp')}`,
              "index" : "essp, import, essp, ssp, ebilling",
              "resource": "",
              "path": `/onpremise/importEssp`,
              "iconClassname": "mdi mdi-layout",
              "childPath": []
            },
          ]
        },
        {
          "label": `${t.translate('menu.billing')}`,
          "children":[
            {
              "label": `${t.translate('menu.setting')}`,
              "index" : "setting, pengaturan, ebilling",
              "resource" : "",
              "iconClassName" : "mdi mdi-layout",
              "path" : `/onpremise/pengaturan`,
              "childPath" : []
            },
            {
              "label" : `${t.translate('menu.inquiry')}`,
              "index" : "data, inquiry, ebilling",
              "resource" : "",
              "iconClassName": "mdi mdi-layout",
              "path" : `/onpremise/inquiry`,
              "childPath": []
            },
            {
              "label":`${t.translate('menu.dataEssp')}`,
              "index":"data essp, daftar ssp",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/essp`,
              "childPath":[]
            },
            {
              "label" : `${t.translate('menu.paymentOrder')}`,
              "index" : "payment, bayar, pesanan, order",
              "resource" : "",
              "iconClassName" : "mdi mdi-layout",
              "path": `/onpremise/paymentOrder`,
              "childPath": []
            },
            {
              "label" : `${t.translate('menu.paymentNotif')}`,
              "index" : "payment, bayar, pesanan, order",
              "resource" : "",
              "iconClassName" : "mdi mdi-layout",
              "path": `/onpremise/paymentNotification`,
              "childPath": []
            },
            {
              "label" : `${t.translate('menu.paymentAccount')}`,
              "index" : "payment, bayar, pesanan, order",
              "resource" : "",
              "iconClassName" : "mdi mdi-layout",
              "path": `/onpremise/paymentAccount`,
              "childPath": []
            },
          ]
        },
        {
          "label" : `${t.translate('menu.logBilling')}`,
          "children": [
            {
              "label":`${t.translate('menu.logExportDataInquiry')}`,
              "index":"log, export, data, inquiry, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logInquiry`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logExportDataSsp')}`,
              "index":"log, export, data, ssp, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logSsp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logCetakOrder')}`,
              "index":"log, export, data, order, payment, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logOrder`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logCetakNotification')}`,
              "index":"log, export, data, payment, notification, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logNotification`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logCetakBpn')}`,
              "index":"log, export, data, bpn, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logBpn`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logCetakPo')}`,
              "index":"log, export, data, laporan, Po, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logPo`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logCetakPn')}`,
              "index":"log, export, data, laporan, Pn, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logPn`,
              "childPath":[]
            },
          ]
        },
        {
          "label" : `${t.translate('menu.log')}`,
          "children": [
            {
              "label":`${t.translate('menu.logEmail')}`,
              "index":"log, email, data, inquiry, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logEmail`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logExport')}`,
              "index":"log, export, data, inquiry, cetak",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logExport`,
            },
            {
              "label":`${t.translate('menu.logPdfBp')}`,
              "index":"log, bp, bukti, potong, dalam, luar, negeri, data, inquiry, cetak, pdf",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logPdfBp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logPdfBpe')}`,
              "index":"log, pdf, bpe",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logPdfBpe`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.logPdfInduk')}`,
              "index":"log, pdf, bpe",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/onpremise/logPdfInduk`,
              "childPath":[]
            }
          ]
        },
      ]
      if(isAdmin) setActiveSidebar(sidebarMenuAdmin)
      if(isOwner) setActiveSidebar(sidebarMenuOwner)
      if(isUser) setActiveSidebar(sidebarMenuUser)
    }
  }

  return (
    <PageWrapper
      defaultCollapse={true}
      sidebarCollapsible={true}
      sidebarDefaultCollapse={false}
      inverseTheme={true}
      style={{background: '#D14C21'}}
      sidebarMenu={activeSidebar}
      useSidebar={true}
      username={user.login}
      handleLogout={async ()=> {
        let res = await OpLoginService.logout();
        localStorage.removeItem('token')
        window.location.href = '/#/login'
      }}
      handleProfile={()=> {
        window.location.href = `/#/onpremise/profile`
      }}
    >
      <Router history={history}>
        <Switch>
            <Route 
              path = '/onpremise/profile'
              render = {props => (
                <OpProfileForm {...props}/>
              )}
            />
            
            <Route 
              path = '/onpremise/archive/bp26/:id/history'
              render = {props => (
                <ArchiveBp26History {...props}/>
              )}
            />
            <Route
              path='/onpremise/archive/bp26'
              render={props => (
                <ArchiveBp26View {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/bp26/:id/history'
              render = {props => (
                <Bp26History {...props}/>
              )}
            />
            <Route
              path='/onpremise/bp26'
              render={props => (
                <Bp26View {...props}/>
              )}
            />
            <Route
              path='/onpremise/archive/bp/:id/history'
              render={props => (
                <ArchiveBpHistory {...props}/>
              )}
            />
            <Route
              path='/onpremise/archive/bp'
              render={props => (
                <ArchiveBpView {...props}/>
              )}
            />
            <Route
              path='/onpremise/bp/:id/history'
              render={props => (
                <BpHistory {...props}/>
              )}
            />
            <Route
              path='/onpremise/bp'
              render={props => (
                <BpView {...props}/>
              )}
            />
            <Route
              path='/onpremise/archive/doss2/:id/history'
              render={props => (
                <ArchiveDoss2HistoryView {...props}/>
              )}
            />
            <Route
              path='/onpremise/archive/doss2'
              render={props => (
                <ArchiveDoss2View {...props}/>
              )}
            />
            <Route
              path='/onpremise/doss2/:id/history'
              render={props => (
                <Doss2HistoryView {...props}/>
              )}
            />
            <Route
              path='/onpremise/doss2'
              render={props => (
                <Doss2View {...props}/>
              )}
            />
            <Route 
              path='/onpremise/archive/daftar-bpe'
              render={props => (
                <ArchiveDaftarBpeView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/daftar-bpe'
              render={props => (
                <DaftarBpeView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/essp/calc'
              render={props => (
                <DataEsspForm {...props}/>
              )}
            />
            <Route 
              path='/onpremise/essp'
              render={props => (
                <DataEsspView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/ebilling-widget'
              render={props => (
                <EbillingWidget {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/archive/daftar-spt-archive/:id/history'
              render = {props => (
                <ArchiveDaftarHistorySpt {...props}/>
              )}
            />
            <Route
              exact
              path='/onpremise/archive/daftar-spt'
              render={props => (
                <ArchiveDaftarSptView {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/daftar-spt/:id/history'
              render = {props => (
                <DaftarHistorySpt {...props}/>
              )}
            />
            <Route
              path='/onpremise/daftar-spt/:id'
              render={props => (
                <DaftarSptForm {...props}/>
              )}
            />
            <Route
              exact
              path='/onpremise/daftar-spt'
              render={props => (
                <DaftarSptView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/import/:id/import-detail-csv'
              render={props => (
                <ImportLogCsv {...props}/>
              )}
            />
            <Route 
              path='/onpremise/import/:id/import-detail'
              render={props => (
                <ImportLog {...props}/>
              )}
            />
            <Route 
              path='/onpremise/import'
              render={props => (
                <ImportData {...props}/>
              )}
            />
            <Route 
              path='/onpremise/export'
              render={props => (
                <EksporData {...props}/>
              )}
            />
            <Route 
              path='/onpremise/import-bp'
              render={props => (
                <ImportBp {...props}/>
            )}
            />
            <Route 
              path='/onpremise/import-setor-sendiri'
              render={props => (
                <ImportSetorSendiri {...props}/>
            )}
            />
            <Route 
              path='/onpremise/import-doss-dopp'
              render={props => (
                <ImportDossDopp {...props}/>
            )}
            />
            <Route 
              path='/onpremise/import-ssp-pbk'
              render={props => (
                <ImportSspPbk {...props}/>
            )}
            />
            <Route
              path='/onpremise/dashboard'
              render={props => (
                <DashboardSample {...props}/>
              )}
            />
            <Route
              path='/onpremise/table'
              render={props => (
                <TableSample {...props}/>
              )}
            />
            <Route
              path='/onpremise/form'
              render={props => {
                <FormSample {...props}/>
              }}
            />
            <Route 
              path='/onpremise/archive/ssp/:id/history'
              render={props => (
                <ArchiveSspHistory {...props}/>
              )}
            />
            <Route 
              path='/onpremise/archive/ssp/:id'
              render={props => (
                <ArchiveSspForm {...props}/>
              )}
            />
            <Route
              path='/onpremise/archive/ssp'
              render={props => (
                <ArchiveSspView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/ssp/:id/history'
              render={props => (
                <SspHistory {...props}/>
              )}
            />
            <Route 
              path='/onpremise/ssp/:id'
              render={props => (
                <SspForm {...props}/>
              )}
            />
            <Route
              path='/onpremise/ssp'
              render={props => (
                <SspView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/archive/pbk/:id/history'
              render={props => (
                <ArchivePbkHistory {...props}/>
              )}
            />
            <Route 
              path='/onpremise/archive/pbk/:id'
              render={props => (
                <ArchivePbkForm {...props}/>
              )}
            />
            <Route
              path='/onpremise/archive/pbk'
              render={props => (
                <ArchivePbkView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/pbk/:id/history'
              render={props => (
                <PbkHistory {...props}/>
              )}
            />
            <Route 
              path='/onpremise/pbk/:id'
              render={props => (
                <PbkForm {...props}/>
              )}
            />
            <Route
              path='/onpremise/pbk'
              render={props => (
                <PbkView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/lawan-transaksi/:id'
              render={props => (
                <LawanTransaksiForm {...props}/>
              )}
            />
            <Route 
              path='/onpremise/lawan-transaksi'
              render={props => (
                <LawanTransaksiView {...props}/>
              )}
            />
            <Route 
              path='/onpremise/signer/:id'
              render={props => (
                <SignerForm {...props}/>
              )}
            />
            <Route 
              path='/onpremise/signer'
              render={props => (
                <SignerView {...props}/>
              )}
            />
            {/* <Route 
              path='/onpremise/lawan-transaksi/:id'
              render={props => (
                <LawanTransaksiForm {...props}/>
              )}
            />
            <Route 
              path='/onpremise/lawan-transaksi'
              render={props => (
                <LawanTransaksiView {...props}/>
              )}
            /> */}
            <Route
              path='/onpremise/dashboard'
              render={props => (
                <DashboardSample {...props}/>
              )}
            />
            <Route
              path='/onpremise/table'
              render={props => (
                <TableSample {...props}/>
              )}
            />
            <Route
              path='/onpremise/form'
              render={props => {
                <FormSample {...props}/>
              }}
            />
            <Route
              path='/onpremise/organization/new'
              render={props => (
                <OrganizationForm {...props}/>
              )}
            />
            <Route
              path='/onpremise/organization'
              render={props => (
                <Organization {...props}/>
              )}
            />
            <Route 
              path='/onpremise/user/new'
              render={props => (
                <UserForm {...props}/>
              )}
            />
            <Route 
              path='/onpremise/user'
              render={props => (
                <User {...props}/>
              )}
            />
            <Route 
              path='/onpremise/import-log'
              render={props => (
                <ImportLog {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/pengaturan'
              render = { props => (
                <OpSettingCoreForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/inquiry/:id'
              render = { props => (
                <OpInquiryForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/inquiry'
              render = { props => (
                <OpInquiry {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentOrder/finpay'
              render = { props => (
                <OpFinpayForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentOrder/mandiriCms'
              render = { props => (
                <OpMandiriCmsForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentOrder/briCms'
              render = { props => (
                <OpBriCmsForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentOrder/bniDirect'
              render = { props => (
                <OpBniDirectForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentOrder/:id'
              render = { props => (
                <OpPaymentOrderForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentOrder'
              render = { props => (
                <OpPaymentOrder {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/fileTransaksi/:id'
              render = { props => (
                <OpFileTransaksiForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentNotification/:id'
              render = { props => (
                <OpPaymentNotificationDetails {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentNotification'
              render = { props => (
                <OpPaymentNotification {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentAccount/:id'
              render = { props => (
                <OpPaymentAccountForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/paymentAccount'
              render = { props => (
                <OpPaymentAccount {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logInquiry'
              render = { props => (
                <OpLogExportInquiry {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logSsp'
              render = { props => (
                <OpLogExportSsp {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logOrder'
              render = { props => (
                <OpLogCetakOrder {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logNotification'
              render = { props => (
                <OpLogNotification {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logBpn'
              render = { props => (
                <OpLogCetakBpn {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logPo'
              render = { props => (
                <OpLogCetakPo {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logPn'
              render = { props => (
                <OpLogCetakPn {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/importInquiry/:id'
              render = { props => (
                <OpImportInquiryDetail {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/importInquiry'
              render = { props => (
                <OpImportInquiry {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/importEssp/:id'
              render = { props => (
                <OpImporteSspDetail {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/importEssp'
              render = { props => (
                <OpImporteSsp {...props}/>
              )}
            />

            {/* OP ADMIN */}
            <Route 
              path = '/onpremise/logged-in'
              render = {props => (
                <OpUserLogin {...props} />
              )}
            />
            <Route 
              path = '/onpremise/company/:id'
              render = {props => (
                <OpPerusahaanForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/company'
              render = {props => (
                <OpPerusahaan {...props} />
              )}
            />
            <Route 
              path = '/onpremise/organization-op/:id'
              render = {props => (
                <OpOrganizationForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/organization-op'
              render = {props => (
                <OpOrganization {...props} />
              )}
            />
            <Route 
              path = '/onpremise/user-op/:id'
              render = {props => (
                <OpUserForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/user-op'
              render = {props => (
                <OpUser {...props} />
              )}
            />
            <Route 
              path = '/onpremise/master-kpp/:id'
              render = {props => (
                <OpMasterKppForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/master-kpp'
              render = {props => (
                <OpMasterKpp {...props} />
              )}
            />
            <Route 
              path = '/onpremise/signInLog'
              render = {props => (
                <OpLogSignIn {...props} />
              )}
            />
            <Route 
              path = '/onpremise/validationLog'
              render = {props => (
                <OpLogValidation {...props} />
              )}
            />
            <Route 
              path = '/onpremise/email/:id'
              render = {props => (
                <OpSettingEmailForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/email'
              render = {props => (
                <OpSettingEmail {...props} />
              )}
            />
            <Route 
              path = '/onpremise/email-content/:id'
              render = {props => (
                <OpSetContentEmailForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/email-content'
              render = {props => (
                <OpSetContentEmailTable {...props} />
              )}
            />
            <Route 
              path = '/onpremise/schedule-email/:id'
              render = {props => (
                <OpScheduleEmailForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/schedule-email'
              render = {props => (
                <OpScheduleEmail {...props} />
              )}
            />
            {/* OP OWNER */}
            <Route 
              path = '/onpremise/importPengguna/:id'
              render = {props => (
                <OpImportPenggunaDetail {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importPengguna'
              render = {props => (
                <OpImportPengguna {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importUserOrganisasi/:id'
              render = {props => (
                <OpImportUserOrgDetail {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importUserOrganisasi'
              render = {props => (
                <OpImportUserOrg {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importCredentialsEbilling/:id'
              render = {props => (
                <OpImportCredentialsEbillingDetail {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importCredentialsEbilling'
              render = {props => (
                <OpImportCredentialsEbilling {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importOrganisasi/:id'
              render = {props => (
                <OpImportOrgDetail {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importOrganisasi'
              render = {props => (
                <OpImportOrg {...props} />
              )}
            />
            <Route 
              path = '/onpremise/pengguna/:id'
              render = {props => (
                <OpUserForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/pengguna'
              render = {props => (
                <OpUser {...props} />
              )}
            />
            <Route 
              path = '/onpremise/owner/:id'
              render = {props => (
                <OpOwnerForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/owner'
              render = {props => (
                <OpOwner {...props} />
              )}
            />
            <Route 
              path = '/onpremise/userOrganisasi/:id'
              render = {props => (
                <OpUserOrgForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/userOrganisasi'
              render = {props => (
                <OpUserOrg {...props} />
              )}
            />
            <Route 
              path = '/onpremise/pengaturanUnifikasi'
              render = {props => (
                <OpSetEunifikasiForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/templateRole/:id'
              render = {props => (
                <OpRoleTemplateForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/templateRole'
              render = {props => (
                <OpRoleTemplate {...props} />
              )}
            />
            <Route 
              path = '/onpremise/about'
              render = {props => (
                <OpAboutForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/logEmail'
              render = {props => (
                <OpLogEmail {...props} />
              )}
            />
            <Route
              path='/onpremise/log-export/:id'
              render={props => (
                <LogExportForm {...props}/>
              )}
            />
            <Route 
              path = '/onpremise/logExport'
              render = {props => (
                <LogExportView {...props} />
              )}
            />
            <Route 
              path = '/onpremise/logPdfBpe'
              render = {props => (
                <OpLogPdfBpe {...props} />
              )}
            />
            <Route 
              path = '/onpremise/logPdfInduk'
              render = {props => (
                <OpLogPdfInduk {...props} />
              )}
            />
            <Route 
              path = '/onpremise/flow-approval/:id'
              render = {props => (
                <OpApprovalFlowForm {...props} />
              )}
            />
            <Route 
              path = '/onpremise/flow-approval'
              render = {props => (
                <OpApprovalFlowTable {...props} />
              )}
            />
            <Route 
              path = '/onpremise/logPdfBp'
              render = {props => (
                <OpLogPdfBp {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importSigner/:id'
              render = {props => (
                <OpImportSignerDetail {...props} />
              )}
            />
            <Route 
              path = '/onpremise/importSigner'
              render = {props => (
                <OpImportSignerView {...props} />
              )}
            />
            <Route 
              path = '/onpremise/dashboardSpt'
              render = {props => (
                <DashboardSpt {...props} />
              )}
            />
          </Switch>
      </Router>
    </PageWrapper>
  )
}


export default onp({
  basePath: '/onpremise'
})(Onpremise);
