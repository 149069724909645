class action{
  constructor(
    label           = 'string', 
    iconClassName   = 'mdi mdi-arrow-right', 
    onClick         = null, 
    show            = true
  ){
    this.label = label
    this.iconClassName = iconClassName
    this.onClick = onClick
    this.show = show
  }
}

export default action