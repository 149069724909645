import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpLogPdfInduk.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button, Chip} from 'react-md'
import errorService from '../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import fileSaver from 'file-saver'

const OpLogPdfInduk = ({
  className       = '',
  showCommandbar  = true,
  modalStore,

}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const baseId = 'mod-op-logPdfInduk'
  const basePath = 'logPdfInduk'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function initData(){}

  async function downloadExc(item) {
    try {
      var proses = t.translate('words.download')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      var selectedDataFilterStatus = []
      selected.map((d)=> {
        selectedData.push(data[d].id)
        selectedDataFilterStatus.push(data[d].status)
      })
      var finishOnly = selectedDataFilterStatus.filter((d)=> {
        return d != 'FINISH'
      })
      if(finishOnly.length > 0){
        iziToast.info({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesFail')} ${t.translate('words.downloadWarningPdfInduk')}`
        })
      } else {
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.download(di)
            const blob = new Blob([proccedThis.data], {type: 'application/pdf'})
            fileSaver.saveAs(blob, proccedThis.headers.filename);
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
        })
      }
  } catch(e) {
      errorService(e)
    }
  }

  async function download(item) {
    modalStore.showConfirm({
      title : t.translate('words.download'),
      children : t.translate('mpk.confirm.message'),
      onSubmit : (callback) => {
        downloadExc(item)
        callback()
      }
    })
  }

  async function hapusExc(item) {
    try {
        var proses = t.translate('words.hapus')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d].id))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        try {
          let proccedThis = await service.deleteAll(selectedData)
          iziToast.success({
            title: proses,
            message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
          })
          setPloading({ loading: false, message: `` })
        } catch(e){
          setPloading({ loading: false, message: `` })
          errorService(e)
        }
        try{
          var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
          if(checkboxRoot.checked){
            if(checkboxRoot.getAttribute('aria-checked')){
              checkboxRoot.click()
              checkboxRoot.click()
            } else {
              checkboxRoot.click()
            }
          }
          document.getElementsByClassName('mdi-reload')[0].click()
        }catch(e){}
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  return (
    <TableWrapper
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      selectable={true}
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`${baseId}-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            setSearch(values)
            localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.npwp`),
              key: 'orgNpwp.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.nama`),
              key: 'orgNama.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.tahun`),
              key: 'sptTahun.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.masa`),
              key: 'sptMasa.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.pembetulan`),
              key: 'sptPembetulan.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.status`),
              key: 'status.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.orgNpwp'),
          searchable: true,
          sortable : true,
          key:'orgNpwp',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.orgNpwp)
        },
        {
          label: t.translate('words.nama'),
          searchable: true,
          sortable : true,
          key:'orgNama',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.orgNama)
        },
        {
          label: t.translate('words.year'),
          searchable: true,
          sortable : true,
          key:'sptTahun',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.sptTahun)
        },
        {
          label: t.translate('words.month'),
          searchable: true,
          sortable : true,
          key:'sptMasa',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.sptMasa)
        },
        {
          label: t.translate('words.rev'),
          searchable: true,
          sortable : true,
          key:'sptPembetulan',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.sptPembetulan)
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.status == "PROCESS"){
              return <Chip style={{ background: "#2196F3", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            } else if(item.status == "FINISH"){
              return <Chip style={{ background: "#4CAF50", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else if(item.status == "FAILED"){
              return <Chip style={{ background: "#f44336", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else {
              return <Chip style={{ background: "black", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }
          }
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            let message = item.message
            if (message) {
              message = message.replace('<', '(')
              message = message.replace('>', ')')
            }
            return message
          }
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.download')}`, 'mdi mdi-download', ()=> {download()}),
        new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', ()=> {hapus()}),
      ]}
      itemActions={[]}
      onFetchData={(params)=> {
        params = {
          ...search,
          ...params,
        }
        return (
          new Promise(async (resolve, reject)=> {
            try {
              await initData()
              var user = JSON.parse(localStorage.getItem('user'))
              var company = user.company.id
              let getPage = null
              getPage = await service.findAll(params, company)
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpLogPdfInduk))
