import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';
import AppMode from '../../AppMode'

let service = null
var serviceUrl = '/spt'
var serviceInduk = '/bp/induk'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/archive/spt');
  serviceUrl = '/api/eunifikasi/archive/spt'
  serviceInduk = '/api/eunifikasi/archive/bp/induk'
} else {
  service = new CrudService('/spt');
}

service.getAppInfo = async (query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/application-info`,
    query
  })
}

service.getState = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/state`,
    data
  })
}

service.pengajuanBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/pengajuan/bulk`,
    data
  })
}

service.actionBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/action/bulk`,
    data
  })
}

service.cancelBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/cancel/bulk`,
    data
  })
}

service.getOrganizationOp = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/eunifikasi/organization-users/organization`,
    query
  })
}

service.getCertStatus = async(npwp)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/cert/search?npwp=${npwp}`
  })
}

service.pembetulan = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/rev/${id}`
  })
}

service.checkSpt = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/check`,
    data,
  })
}

service.postingSpt = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/posting/${id}`,
  })
}

service.submitSpt = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/submit/${id}`,
  })
}

service.history = async(id, query) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`,
    query
  })
}

service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/spt/debt`,
    query,
  })
}

service.report = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceInduk}/report`,
    query
  })
}

service.donwloadId = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `/export/spt/pdf/${id}`
  })
}

service.downloadAll = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/download`,
    data,
  })
}

service.donwloadIdXls = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `/export/spt/summary/bp/${id}`
  })
}

service.downloadAllXls = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/spt/summary/bp`,
    data,
  })
}

service.downloadAllXlsOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/archive/spt/summary/bp`,
    data,
  })
}

service.downloadSearch = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/download`,
    data,
  })
}

service.opPdfBp = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/archive/spt/summary/bp/pdf`,
    data
  })
}

service.opPdfBpe = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/archive/spt/summary/bpe/pdf`,
    data
  })
}

service.opSptSummaryIndukPdf = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/archive/spt/summary/induk/pdf`,
    data
  })
}

service.opSptSummary = async(data)=>{
  return http.request({
    method : http.methods.POST,
    url : `/api/eunifikasi/archive/spt/summary`,
    data
  })
}

service.OpSptSummaryIdXls = async(data)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/archive/spt/summary`,
    data
  })
}

export default service;