import React from 'react';
import { inject, observer } from 'mobx-react';
import { FontIcon } from 'react-md';
import './Picture.scss';

const Picture = (props) => {
  const { user } = props.authStore;
  return (
    <div className="mpk-picture">
      { user.profilePic ? (
        <img src={user.profilePic} alt={``}/>
      ) : (
        <FontIcon className="mdi mdi-account"/>
      )}
    </div>
  )
}

export default inject('authStore')(observer(Picture))
