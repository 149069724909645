import React, {useEffect} from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import Spt from './Spt.routes'
import Company from './Company.routes'
import Index from './Index.routes'
import { inject, observer } from 'mobx-react'

const Product = ({history, navigationStore}) => {
  if(window.location.pathname === '/product') 
    setTimeout(() => navigationStore.redirectTo('/product/index'), 500)

  useEffect(() => {
    if(window.location.href.indexOf('index') != -1){
      try { 
        document.getElementsByClassName('mpk-margin-S margin-bottom mpk-full full-width')[0].style.display = "none"
      } catch(e) {
        console.log()
      }
    }
  })

  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/product/company/:companyId/daftar-spt/open/:sptId'
          render={props => (
            <Spt {...props}/>
          )}
        />
        <Route
          path='/product/company/:companyId'
          render={props => (
            <Company {...props}/>
          )}
        />
        <Route
          path='/product/index'
          render={props => (
            <Index {...props}/>
          )}
        />
      </Switch>
    </Router>
  )
}

export default inject('navigationStore')(observer(Product))