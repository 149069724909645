import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { sso } from '../libs/react-mpk/hocs';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import { TableSample, FormSample, DetailsSample, DashboardSample } from '../modules';


const Internal = ({history}) => {
  return (
    <PageWrapper
      sidebarCollapsible={true}
      inverseTheme={true}
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/internal/dashboard'
            render={props => (
              <DashboardSample {...props}/>
            )}
          />
          <Route
            path='/internal/table'
            render={props => (
              <TableSample {...props}/>
            )}
          />
          <Route
            path='/internal/form'
            render={props => (
              <FormSample {...props}/>
            )}
          />
          <Route
            path='/internal/details/:tab'
            render={props => (
              <DetailsSample {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/internal'
})(Internal);