import React from 'react'
import Flex from '../Flex'
import { FontIcon, Button } from 'react-md'
import renderHTML from 'react-render-html'
import './Hint.scss'

const Hint = ({
  children      = null,
  className     = '',
  iconClassName = 'mdi mdi-information',
  message       = null,
  more          = null,
  renderAsHtml  = false,
  showIcon      = true,
  ...props
}) => {
  return (
    <Flex 
      className={`mpk-hint mpk-padding-N padding-all ${className}`}
      align={Flex.properties.align.CENTER}
      justify={Flex.properties.justify.BETWEEN}
      {...props}
    >
      <Flex
        align={Flex.properties.align.CENTER} 
        fit
      >
        {showIcon && (
          <FontIcon
            iconClassName={iconClassName}
            className="mpk-margin-N margin-right"
          />
        )}
        <div className="flex">
          {renderAsHtml ? renderHTML(message || children) : (message || children)}
        </div>
        {more && (
          <FontIcon iconClassName="mdi mdi-dots-horizontal"/>
        )}
      </Flex>
    </Flex>
  )
}

export default Hint
