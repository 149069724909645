import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import axios from 'axios'

let service = new CrudService('/api/log-import-csv/page')

service.uploadSertel = async(data)=> {
    return axios({
      method: http.methods.POST,
      url: `/api/sso/cert/upload`,
      data
    })
}

service.getCertStatus = async(npwp)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/cert/search?npwp=${npwp}`
  })
}

service.downloadTemplate = async(id) => {
    return http.request({
        method : http.methods.GET,
        url : `${this.apiLocation}/spt4a2/bp-4a2/download-template/${id}`
    })
}


export default service