import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import axios from 'axios'

let service = new CrudService('/api/ebilling/log-import/page?category.equals=EBILLING_IMPORT_ESSP_EUNIFIKASI')

service.findAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/log-import/page?category.equals=EBILLING_IMPORT_ESSP_EUNIFIKASI`,
    query
  })
}
  
service.validation = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url : `/api/ebilling/log-import/validations`,
    query
  })
}

service.editById = async(data)=>{
  return http.request({
    method: http.methods.PUT,
    url: `/api/ebilling/log-import`,
    data,
  })
}

service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/ebilling/log-import/delete`,
    data,
  })
}

service.importSsp = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling/import`,
    data
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/ebilling/log-import/delete/${id}`,
  })
}

service.downloadTemplate = async(fileId) => {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling/log-import/download-template/EBILLING_IMPORT_ESSP_EUNIFIKASI`
  })
}


export default service