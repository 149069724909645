import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpSetContentEmail.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import MDEditor from '@uiw/react-md-editor';

const OpSetContentEmailForm = ({ 
  params, 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [editor, setEditor] = useState()
  
  const basePath = 'email-content'
  const baseId = 'mod-email-content-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        let res = null
        let user = JSON.parse(localStorage.getItem('user'))
        let id = null

        if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
          id = match.params.id
        } 
        if(window.location.href.indexOf('open') != -1){
          id = match.params.companyId
        }
        if(user.authorities[0].name == "ROLE_EPPT_OWNER") {
          id = user.company.id
        }

        res = await service.findAll(params, id)
        setEditor(res.data.body)
        setData({loading: false, content: res.data})
      }
    }
    initData()
  }, [])
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }
  
  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{
        maxWidth: '100%'
      }}
      // backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.title'),
          key: 'subject',
          type: 'text',
          validation: 'required',
        },
        {
          render: (
            <MDEditor
              style={{
                height: 500,
                marginBottom: 20
              }}
              onChange={(value)=> {
                setEditor(value)
                data.content.body = value
              }}
              height={500}
              key = "body"
              value = {data.content.body}
            />
          )
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate('words.limitPerDay'),
          key: 'limitPerDay',
          type: 'text',
          valdate: 'required'
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          if(formData.type == 'true'){
            formData.type = true
          } else {
            formData.type = false
          }
          formData.body = editor
          formData.limitPerDay = formData.limitPerDay + ""
          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          if(window.location.href.indexOf('open') != -1){

          } else {
            navigationStore.redirectTo(`${backTo}`)  
          }    
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpSetContentEmailForm))
