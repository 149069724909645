import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './User.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'

const UserForm = ({ authStore, envStore, match, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [organization, setOrganization] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const basePath = 'user-op'
  const baseId = 'mod-user-op-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        // setDisabledKapKJS(true)
        const res = await service.getOne(match.params.id)
        res.data.type = res.data.type + ""
        setData({loading: false, content:res.data})
      }
    }
    initData()
  }, [])

  const getOrganizations = async ()=> {
    let organizations = UtilsService.getOrganizations(toJS(authStore))
    organizations.map((d)=> {
      d.label = d.npwp + " - " + d.name
    })
    setOrganization(organizations)
    return organizations
  }
  
  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {`/onpremise/${basePath}`}
      baseId={`mod-form-${basePath}`}
      // title={t.translate(`modules.${basePath}.title`)}
      hintMessage={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.uploadId`),
          key: 'id',
          type: 'text',
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.status'),
          key: 'status',
          type: 'text',
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.stepNumber`),
          key: 'step',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.line'),
          key: 'line',
          type: 'text',
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.message'),
          key: 'message',
          type: 'text',
          required: true,
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          if(formData.type == 'true'){
            formData.type = true
          } else {
            formData.type = false
          }
          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.put(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false)
          navigationStore.redirectTo(`/onpremise/${basePath}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(UserForm))
