import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpAbout.service'
import { toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import { Divider } from 'react-md'

const OpAboutForm = ({ 
  authStore,
  match, 
  navigationStore, 
  params 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const basePath = 'about'
  const baseId = 'mod-about-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        let user = JSON.parse(localStorage.getItem('user'))
        let companyId = null

        if(window.location.href.indexOf('open') != 1){
          companyId = match.params.companyId
        }
        if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
          companyId = user.company.id
        } 
        
        const res = await service.getOne(companyId)
        setData({loading: false, content: res.data})
      }
    }
    initData()
  }, [])
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style = {{
        maxWidth : '100%'
      }}
      // backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.name'),
          key: 'nama',
          type: 'text',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.email`),
          key: 'email',
          type: 'text',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.phone`),
          key: 'telp',
          type: 'text',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.address`),
          key: 'alamat',
          type: 'text',
          disabled: true,
        },
        {
          inputType: inputTypes.DIVIDER,
          label: t.translate(`words.sn`),
        },
        {
          inputType: inputTypes.INPUT,
          label: "",
          key: 'sn',
          type: 'text',
          disabled: true,
        },
      ]}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpAboutForm))
