import React, {useState} from 'react'
import { Flex, DataView, Commandbar } from '../components'
import { eventNames } from '../config/constant'
import t from 'counterpart'
import { chart, dashboardDefinition, chartData, summaryData } from '../class'

class summaries{
  constructor(onFetchData, onInitData){
    this.onFetchData = onFetchData
    this.onInitData = onInitData
  }
}

const DashboardWrapper = ({
  actions           = [],
  baseId            = 'mpk-dashboard-wrapper-id',
  className         = '',
  definitions       = [],
  showCommandbar    = true,
  title             = null,
  useFilter         = true,
  maxPeriodUnit     = 3,
  maxPeriodValue    = 'month' 
}) => {
  const [showFilter, setShowFilter] = useState(false)
  const eventName = `${baseId}-event`
  return (
    <>
      <Flex
        id={baseId}
        className={`mpk-dashboard-wrapper mpk-full full-width mpk-padding-S padding-right ${className}`}
        direction={Flex.properties.direction.COLUMN}
        scrollable
      >
        { showCommandbar && (
          <Commandbar 
            title={title}
            className="mpk-position position-sticky"
            actions={[...actions, ...(useFilter ? [
              {
                label: t.translate('mpk.column.filter'),
                iconClassName: 'mdi mdi-filter',
                onClick: () => setShowFilter(!showFilter)
              }
            ] : [])]}
          />
        )}
        <div className={`mpk-full full-width ${showCommandbar ? '' : 'mpk-padding-N padding-top padding-bottom'}`}>
          {definitions.map((def, defI) => (
            <div 
              key={`${baseId}-definition-${defI}`}
              className="mpk-full full-width"
            >
              <Flex 
                className="summary mpk-full full-width mpk-margin-S margin-bottom"
                align={Flex.properties.align.CENTER}
                wrap
              >
                {def.summaries.map((sum, sumI) => (
                  <DataView.Summaries
                    key={`${baseId}-definition-${defI}-summary-${sumI}`}
                    eventName={eventName}
                    baseId={baseId}
                    onFetchData={sum.onFetchData}
                    onInitData={sum.onInitData}
                  />
                ))}
              </Flex>
              <Flex 
                className="chart mpk-full full-width"
                align={Flex.properties.align.CENTER}
                wrap
              >
                {def.charts.map((ch, chI) => (
                  <DataView.Chart
                    className="mpk-margin-S margin-right"
                    eventName={eventName}
                    key={`${baseId}-definition-${defI}-chart-${chI}`}
                    baseId={baseId}
                    label={ch.label}
                    onFetchData={ch.onFetchData}
                    onInitData={ch.onInitData}
                    chartConfig={ch.chartConfig}
                    dataConfig={ch.dataConfig}
                  />
                ))}
              </Flex>
            </div>
          ))}
        </div>
      </Flex>
      {useFilter && (
        <DataView.Filter
          baseId={`${baseId}-filter`}
          visible={showFilter}
          focusOnMount={false}
          onRequestClose={() => setShowFilter(false)}
          maxPeriodUnit={maxPeriodUnit}
          maxPeriodValue={maxPeriodValue}
          onSubmit={(query, callback) => {
            window.dispatchEvent(new CustomEvent(eventName, {detail:{
              eventName: eventNames.RELOAD,
              eventData: query
            }}))
            setTimeout(() => {
              setShowFilter(false)
              callback()
            }, 1000)
          }}
        />
      )}
    </>
  )
}

DashboardWrapper.definition = dashboardDefinition
DashboardWrapper.summaries = summaries
DashboardWrapper.summaryData = summaryData
DashboardWrapper.chart = chart
DashboardWrapper.chartData = chartData

export default DashboardWrapper
