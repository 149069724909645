import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpOwner.service'
import _ from 'lodash'
import ErrorService from '../../services/errorService'

const OpOwnerForm = ({ match, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [isShowBanned, setIsShowBanned] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  
  const basePath = 'owner'
  const baseId = 'mod-owner-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
          setIsShow(true)
        } catch(e){
          ErrorService(e)
        }
      } else {
        let res = JSON.parse(localStorage.getItem('owner'))
        setData({loading: false, content: res})
        setIsShowBanned(true)
      }
    }
    initData()
  }, [])
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.username'),
          key: 'login',
          type: 'text',
          validation: 'required',
          show : isShow,
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.password'),
          key: 'password',
          type: 'password',
          validation: 'required',
          show : isShow,
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.email'),
          key: 'email',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.firstName'),
          key: 'firstName',
          type: 'text',
          validation: 'required',
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.lastName'),
          key: 'lastName',
          type: 'text',
          validation: 'required',
          width : '50%'
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate('words.language'),
          key: 'langKey',
          validation: 'required',
          options: [
            { label: 'Indonesia', value: 'id' },
            { label: 'English', value: 'en' },
          ]
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.active`),
          key: 'activated',
          width: '50%'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.banned`),
          key: 'banned',
          width: '50%',
          show : isShowBanned
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.bannedReason'),
          key: 'bannedReason',
          type : 'text',
          show : isShowBanned
        }
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          formData.authorities = [{name: "ROLE_EPPT_USER", id: "ROLE_EPPT_USER"}]
          var user = JSON.parse(localStorage.getItem('user'))
          var company = JSON.parse(localStorage.getItem('company'))

          if(window.location.href.indexOf('open') != -1){
            formData.company = company
            formData.authorities = [{name: "ROLE_EPPT_OWNER", id: "ROLE_EPPT_OWNER"}]
          } else {
            formData.company = user.company
          }

          if(match.params.id == 'new') {
            res = await service.createdOne(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`${backTo}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpOwnerForm))
