import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/logged-in');

service.findAll = async(query, companyId)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/logged-in/${companyId}/company`,
    query
  })
}

service.findAllAdmin = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/logged-in`,
    query
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/logged-in/${id}`,
  })
}


export default service;