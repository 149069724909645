import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/ebilling-core/log/print/reports');

service.coreStatus = async(data)=> {
  return http.request({
    method : http.methods.GET,
    url: `/api/ebilling/coreStatus`,
    data
  })
}

service.deleteById = async(id)=> {
  return http.request({
    method : http.methods.DELETE,
    url : `/api/ebilling-core/log/print/reports/delete/${id}`
  })
}

service.deleteBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/reports/delete`,
    data
  })
}

service.generate = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/reports`,
    data
  })
}

service.download = async(id) => {
 return http.request({
   method: http.methods.GET,
   url : `/api/ebilling-core/log/print/reports/download/${id}`
 })
}

export default service;