import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import axios from 'axios';

let service = new CrudService('/api/eunifikasi/export-pdf-bp');

service.findAll = async(query, companyId)=>{
  return http.request({
    method : http.methods.GET,
    url :`/api/eunifikasi/export-pdf-bp`,
    query
  })
}

service.deleteAll = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/eunifikasi/export-pdf-bp/delete`,
    data
  })
}

service.download = async(id)=> {
  return axios.get(`/api/eunifikasi/export-pdf-bp/download/${id}`, {
    method: http.methods.GET,
    responseType: 'arraybuffer'
  })
}

export default service;