import http from './http.service';

class Crud{
  constructor(basePath='/', config={}, options={}){
    this.path = basePath
    this.config = config
    this.options = options
  }

  setPath(path){
    this.path = path
  }
  
  get(query={}){
    return http.request({
      method: http.methods.GET,
      url: `${this.path}`,
      query,
      options: this.options,
      config: this.config
    })
  }

  post(data){
    return http.request({
      method: http.methods.POST,
      url: `${this.path}`,
      data,
      options: this.options,
      config: this.config
    })
  }

  put(id, data){
    return http.request({
      method: http.methods.PUT,
      url: `${this.path}/${id}`,
      data,
      options: this.options,
      config: this.config
    })
  }

  delete(id){
    return http.request({
      method: http.methods.DELETE,
      url: `${this.path}/${id}`,
      options: this.options,
      config: this.config
    })
  }
}

export default Crud