import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpSettingEmail.service'
import { toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'

const OpSettingEmailForm = ({ 
  authStore,
  match, 
  navigationStore, 
  params 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [isShow, setIsShow] = useState(false)
  const [provider, setProvider] = useState('0')
  const basePath = 'email'
  const baseId = 'mod-email-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        let res = null
        let user = JSON.parse(localStorage.getItem('user'))
        let id = null

        if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
          id = match.params.id
        } 
        if(window.location.href.indexOf('open') != -1){
          id = match.params.companyId
        }
        if(user.authorities[0].name == "ROLE_EPPT_OWNER") {
          id = user.company.id
        }

        res = await service.findAll(params, id)
        res.data.authProvider += ""
        res.data.port += ""
        setData({loading: false, content: res.data})
      }
    }
    initData()
  }, [])
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style ={{
        maxWidth : '80%'
      }}
      //backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {
        if(key == "useProxy"){
          if(value == true){
            setIsShow(true)
          } 
          if(value == false){
            setIsShow(false)
          }
        }
        if(key == "authProvider"){
          setProvider(value)
        }
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate('words.provider'),
          key: 'authProvider',
          // validation: 'required',
          options: [
            {label: "General", value: '0'},
            {label: "Azure (Application)", value: '1'},
            {label: "Azure (Delegated)", value: '2'},
          ],
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.username'),
          key: 'username',
          type: 'text',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.host'),
          key: 'host',
          type: 'text',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.password'),
          key: 'password',
          type: 'password',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.port`),
          key: 'port',
          type: 'text',
          // validation: 'required',
          width: '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.enabledTls`),
          key: 'tls',
          width: '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.email'),
          key: 'from',
          type: 'text',
          // validation: 'required',
          width: '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.enabledAuth`),
          key: 'auth',
          width: '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.protocol'),
          key: 'protocol',
          type: 'text',
          // validation: 'required',
          width: '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.trustAllCert`),
          key: 'trustAllCert',
          width: '50%',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.useProxy`),
          key: 'useProxy',
          show: ()=> {
            return provider == '0' ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyHost'),
          key: 'proxyHost',
          type: 'text',
          width: '50%',
          show: ()=> {
            return (provider == '0' && isShow) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.proxyPort`),
          key: 'proxyPort',
          type: 'text',
          width: '50%',
          show: ()=> {
            return (provider == '0' && isShow) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyUsername'),
          key: 'proxyUsername',
          type: 'text',
          width: '50%',
          show: ()=> {
            return (provider == '0' && isShow) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPassword`),
          key: 'proxyPassword',
          type: 'password',
          width: '50%',
          show: ()=> {
            return (provider == '0' && isShow) ? true : false
          }
        },
        // {
        //   inputType: inputTypes.RADIO,
        //   label: t.translate(`words.http`),
        //   key: 'proxyType',
        //   name: 'proxyType',
        //   width: '50%',
        //   value: "HTTP",
        //   style: {
        //     width: '50%'
        //   },
        //   show: ()=> {
        //     return (provider == '0' && isShow) ? true : false
        //   }
        // },
        // {
        //   inputType: inputTypes.RADIO,
        //   label: t.translate(`words.sock`),
        //   key: 'proxyType',
        //   name: 'proxyType',
        //   width: '50%',
        //   value: "SOCKS",
        //   style: {
        //     width: '50%'
        //   },
        //   show: ()=> {
        //     return (provider == '0' && isShow) ? true : false
        //   }
        // },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.proxyType`),
          key: 'proxyType',
          name: 'proxyType',
          width: '50%',
          style: {
            width: '50%'
          },
          options: [
            { label: 'HTTP', value: 'HTTP' },
            { label: 'SOCKS', value: 'SOCKS' },
          ],
          show: ()=> {
            return (provider == '0' && isShow) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.clientId'),
          key: 'clientId',
          type: 'text',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return (provider == '1' || provider == '2' ) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.clientSecret'),
          key: 'clientSecret',
          type: 'text',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return (provider == '1' || provider == '2' ) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.tenantId'),
          key: 'tenantId',
          type: 'text',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return (provider == '1' || provider == '2' ) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.connectedAs'),
          key: 'connectedAs',
          type: 'text',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return (provider == '2' ) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.redirectUri'),
          key: 'redirectUri',
          type: 'text',
          // validation: 'required',
          width : '50%',
          show: ()=> {
            return (provider == '2' ) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.email'),
          key: 'from',
          type: 'text',
          // validation: 'required',
          width: '100%',
          show: ()=> {
            return (provider == '1' || provider == '2' ) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyHost'),
          key: 'proxyHost',
          type: 'text',
          width: '50%',
          show: ()=> {
            return ((provider == '1' || provider == '2' )) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.proxyPort`),
          key: 'proxyPort',
          type: 'text',
          width: '50%',
          show: ()=> {
            return ((provider == '1' || provider == '2' )) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyUsername'),
          key: 'proxyUsername',
          type: 'text',
          width: '50%',
          show: ()=> {
            return ((provider == '1' || provider == '2' )) ? true : false
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPassword`),
          key: 'proxyPassword',
          type: 'password',
          width: '50%',
          show: ()=> {
            return ((provider == '1' || provider == '2' )) ? true : false
          }
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.proxyType`),
          key: 'proxyType',
          name: 'proxyType',
          width: '50%',
          style: {
            width: '50%'
          },
          options: [
            { label: 'HTTP', value: 'HTTP' },
            { label: 'SOCKS', value: 'SOCKS' },
          ],
          show: ()=> {
            return ((provider == '1' || provider == '2' ) && isShow) ? true : false
          }
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          if(formData.type == 'true'){
            formData.type = true
          } else {
            formData.type = false
          }
          formData.port = parseInt(formData.port)
          formData.authProvider = parseInt(formData.authProvider)
          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`${backTo}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpSettingEmailForm))
