const base_url = '/api/v1'
export default {
    id: 75,
    username: 'sunny',
    name: 'Ismail Sunny',
    email: 'ismiletea@gmail.com',
    mobilePhone: '085221942971',
    activationKey: 'pjRLEy1vm0kwgH9g8qvD',
    expiredKey: '2019-11-14T11:22:52.469',
    servicePermission: [
      {
        id: 711,
        name: 'Get Dashboard Chart',
        code: 'DC-01',
        description: 'Get Dashboard Chart',
        method: 'GET',
        clientPath: '/dashboard/chart',
        url: '/dashboard/chart',
        baseUrl: base_url,
        backendServers: [
          {
            id: 313,
            applicationId: 234,
            name: 'Server Local',
            host: 'http://192.168.3.17:9100',
            baseUrl: '/api'
          }
        ],
        parameters: []
      }
    ],
    permission: [],
    role: {
      name: 'ROLE_USER',
      isAdmin: false
    },
    application: {
      id: 234,
      userId: 3,
      applicationTypeId: 6,
      name: 'Template SPA Internal',
      description: 'Template SPA Internal',
      baseUrl: base_url,
      createdBy: 'dwiargo',
      createdAt: '2020-12-15T10:48:57.609',
      updatedBy: 'dwiargo',
      updatedAt: '2021-01-07T12:33:43.684'
    },
    isActive: true,
    isDelete: false,
    createdBy: 'sunny',
    createdAt: '2019-11-13T11:22:52.469',
    updatedBy: 'sunny',
    updatedAt: '2020-07-01T11:27:41.104',
    menu: [],
    isSupport: false
  }
