var UtilsService = {
    getOrganizations: (auth)=> {
        var organizations = []
        try {
            auth.user.company.subscriptions.map((subscription)=> {
                subscription.organizations.map((organization)=> {
                    organizations.push(organization)
                })
            })
        } catch(e){

        }
        return organizations
    },
    getPermissions: ()=> {

    }
}

module.exports = UtilsService