import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/setting/email/page');

service.editById = async(id, data)=>{
	return http.request({
    method: http.methods.PUT,
    url: `/api/setting/email`,
    data,
	})
}

service.findAll = async(query, companyId)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/setting/email/company/${companyId}`,
    query
  })
}

service.findAll = async(query, companyId)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/setting/email/company/${companyId}`,
    query
  })
}

service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/setting/email/delete`,
    data,
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/setting/email/${id}`,
  })
}


export default service; 