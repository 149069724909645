import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpLogExportInquiry.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button, Chip} from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import fileSaver from 'file-saver'

const OpLogExportInquiry = ({
  className       = '',
  showCommandbar  = true,
  modalStore,

}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const baseId = 'mod-op-logExportInquiry'
  const basePath = 'logInquiry'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function initData(){}

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.deleteBulk([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.deleteById(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }

  async function downloadByIdExc(item) {
    try {
      let res = await service.download(item.id)
      const blob = new Blob([res.data], {type: 'application/zip'})
      fileSaver.saveAs(blob, res.headers.filename);
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function downloadById(item) {
    modalStore.showConfirm({
      title : t.translate('words.download'),
      children : t.translate('words.confirmDownload'),
      onSubmit : (callback) => {
        downloadByIdExc(item)
        callback()
      }
    })
  }

  return (
    <TableWrapper
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      selectable = {true}
      defaultData={[]}
      useFilter={false}
      defaultSortBy="createdDate"
      sideHeader={[]}
      columns={[
        {
          label: t.translate('words.total'),
          searchable: true,
          sortable : true,
          key:'total',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.total)
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.status == "WAITING"){
              return <Chip style={{ background: "#9e9e9e", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            } else if(item.status == "FINISH"){
              return <Chip style={{ background: "#4caf50", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else if(item.status == "FAILED"){
              return <Chip style={{ background: "#f44336", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else {
              return <Chip style={{ background: "black", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }
          }
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.message)
        },
        {
          label: t.translate('words.startDate'),
          searchable: true,
          sortable : true,
          key: 'startDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.startDate).format('DD/MM/YYYY hh:mm:ss'))
        },
        {
          label: t.translate('words.endDate'),
          searchable: true,
          sortable : true,
          key: 'endDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.endDate).format('DD/MM/YYYY hh:mm:ss'))
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', ()=> {deleteBulk()}) 
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.download')}`, 'mdi mdi-download', (item)=> {downloadById(item)}, (item)=>{
          if(item.status == 'FINISH'){
              return true
          }
          return false
        }),
        new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', (item)=> {deleteById(item)}, (item)=>{
          if(item.status == 'FINISH' || item.status == 'FAILED'){
              return true
          }
          return false
        }),
      ]}
      onFetchData={(params)=> {
        params = {
          ...search,
          ...params
        }
        return (
          new Promise(async (resolve, reject)=> {
            try {
              await initData()
              let getPage = null
              getPage = await service.get(params)
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpLogExportInquiry))
