import izitoast from 'izitoast'
import moment from 'moment'

var errorService = async function (e){
    window.error = e
    if(e && e.response && e.response.data && e.response.data.error_description){
        izitoast.warning({
            message: `${e.response.data.error_description}`
        })
    }
    if(e && e.response && e.response.data && e.response.data.error_msg){
        izitoast.warning({
            message: `${e.response.data.error_msg}`
        })
    }
    if(e && e.response && e.response.data && e.response.data.errorMessage){
        izitoast.warning({
            message: `${e.response.data.errorMessage}`
        })
    }
    if(e && e.response && e.response.data && e.response.data.detail){
        izitoast.warning({
            title: `${e.response.data.title} (${e.response.data.status})`,
            message: `${e.response.data.detail}`
        })
    } else if(e.response && e.response.status == 503){
        izitoast.error({
            message: `${e.response.status} ${e.response.data.requestId} ${e.response.data.error} ${e.response.data.message} ${e.response.data.path} ${moment(e.response.data.timestamp).format('DD/MM/YYYY hh:mm:ss')}`
        })
    } else {
        izitoast.error({
            message: `${e.message}`
        })
    }
}

export default errorService;

