import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';
import AppMode from '../../AppMode'

let service = null
var serviceUrl = '/mainFlow'
if(AppMode.onPremise){
  service = new CrudService('/api/mainFlow');
  serviceUrl = '/api/mainFlow'
} else {
  service = new CrudService('/mainFlow');
}

service.setDefault = async(data)=> {
  return http.request({
      method: http.methods.POST,
      url: `${serviceUrl}/setDefault`,
      data
  })
}

service.bulkDelete = async(data)=> {
  return http.request({
      method: http.methods.POST,
      url: `${serviceUrl}/delete`,
      data
  })
}

service.getCompany = async(query, nama)=> {
  var url = `/api/company/page`
  if(nama) url = `/api/company/page?nama.contains=${nama}`
  return http.request({
    method: http.methods.GET,
    url: `${url}`,
    query
  })
}

service.getUsers = async(query, login)=> {
  var url = `/api/users`
  if(login) url = `/api/users?login.contains=${login}`
  return http.request({
    method: http.methods.GET,
    url: `${url}`,
    query
  })
}

export default service;