import React, {useEffect, useState} from 'react'
import { TableWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpimportCredentialsEbilling.service'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import { Chip, ListItem, List, Switch } from 'react-md'
import ErrorService from '../../services/errorService'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from '../../services/errorService'
import iziToast from 'izitoast'
import { Dialog, DialogContent, Button} from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import fileSaver from 'file-saver';
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'

const OpimportCredentialsEbilling = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  temporaryStore
}) => {

  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  
  const baseId = 'mod-op-importCredentialsEbilling'
  const basePath= 'importCredentialsEbilling'

  async function initData() {}

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.deleteById(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }

  const addSertel = async (item) => {
    setDialogItem(item)
    setDialog(true)
  }
  
  async function downloadTemplate() {
    try {
      let res = await service.downloadTemplate('EBILLING_CREDENTIAL')
      const blob = new Blob([res.data], {type: res.type})
      fileSaver.saveAs(blob, 'EBILLING_CREDENTIAL.csv');
    } catch (e) {
      ErrorService(e)
    }
  }
  
  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <Dialog 
        disableFocusOnMount={true}
        initialFocus="sertel-dialog"
        id="sertel-dialog"
        onFocus={()=>{}}
        visible={dialog}
        onRequestClose={()=> {
          setDialog(false)
        }}
        style={{
          width: 1024,
          margin: '0px !important'
        }}
        width={1024}
        aria-labelledby="sertel-dialog"
      >
        <DialogContent>
          <FormWrapper 
            style={{
              margin: '0px !important',
              width: '100%',
              maxWidth: '100%'
            }}
            defaultData={{
              npwp: dialogItem.fileName
            }}
            hintMessage={t.translate(`modules.${basePath}.formTitle`)}
            showCommandbar={false}
            baseId = "mod-sertel-form"
            title={t.translate(`modules.${basePath}.title`)}
            definitions={[
              {
                inputType : inputTypes.FILE_INPUT,
                label : t.translate('words.file'),
                key : 'file',
                type : 'file',
                validation: 'required'
              },
            ]}
            onSubmit={async (values, callback)=> {
              var proses = t.translate('words.uploadSertel')
              setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
              try {
                var formData = new FormData()
                formData.append('file', values.file)
                formData.append('category', 'EBILLING_CREDENTIAL')
                formData.append('type', 'M')
                formData.append('no', false)
                formData.append('formatEspt', false)
                formData.append('pembetulan', false)
                formData.append('replace', true)
                formData.append('npwp', null)
                formData.append('customImportId', null)
                formData.append('migration', false)

                let user = JSON.parse(localStorage.getItem('user'))
                var companyId = user.company.id
                await service.importCredential(formData, companyId)
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } catch(e){
                var errors = []
                errors.push(await errorServiceGet(e, { id: dialogItem.npwp }, 0))
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              setDialog(false)
              setPloading({ loading: false, message: `` })
              document.getElementsByClassName('mdi-reload')[0].click()
            }}
          />
        </DialogContent>
      </Dialog>
    <TableWrapper
      selectable={true}
      baseId={baseId}
      title={t.translate(`modules.${basePath}.title`)}
      className={className}
      defaultData={[]}
      defaultSortBy="id"
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`${baseId}-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values['category.equals'] = 'EBILLING_CREDENTIAL'
            var company = localStorage.getItem('company')
            var companyId = company.id
            values['companyId.equals'] = companyId
            setSearch(values)
            localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.id`),
              key: 'upload.id.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.category`),
              key: 'category.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.customImport`),
              key: 'masterCustomImportHeaderName.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.fileName`),
              key: 'fileName.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.startDate`),
              key: 'startDate.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.endDate`),
              key: 'endDate.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.status`),
              key: 'status.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.message`),
              key: 'message.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.stepNumber`),
              key: 'step.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.count`),
              key: 'count.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.total`),
              key: 'total.equals',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.id'),
          searchable: true,
          sortable : true,
          key: 'upload.id',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.upload.id)
        },
        {
          label: t.translate('words.category'),
          searchable: true,
          sortable : true,
          key: 'category',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.category)
        },
        {
          label: t.translate('words.customImport'),
          searchable: true,
          sortable : true,
          key: 'process',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.process)
        },
        {
          label: t.translate('words.fileName'),
          searchable: true,
          sortable : true,
          key: 'upload',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            try {
              return JSON.stringify(item.upload.originalFilename)
            } catch(e) {
              return '-'
            }
          }
        },
        {
          label: t.translate('words.startDate'),
          searchable: true,
          sortable : true,
          key: 'startDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.startDate).format('DD/MM/YYYY hh:mm:ss'))
        },
        {
          label: t.translate('words.endDate'),
          searchable: true,
          sortable : true,
          key: 'endDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (moment(item.endDate).format('DD/MM/YYYY hh:mm:ss'))
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.status == "UPLOADING"){
              return <Chip style={{ background: "#FFC108", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            } else if(item.status == "COMPLETED"){
              return <Chip style={{ background: "#2096F3", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            } else if(item.status == "FINISH"){
              return <Chip style={{ background: "#8BC34A", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else if(item.status == "FAILED"){
              return <Chip style={{ background: "#F44336", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else if(item.status == "DELETED"){
              return <Chip style={{ background: "grey", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }else {
              return <Chip style={{ background: "black", color: 'white'}}>{(item.status+"").toUpperCase()}</Chip>
            }
          }
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.message)
        },
        {
          label: t.translate('words.stepNumber'),
          searchable: true,
          sortable : true,
          key: 'step',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.step)
        },
        {
          label: t.translate('words.count'),
          searchable: false,
          sortable : false,
          key: 'count',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.count)
        },
        {
          label: t.translate('words.total'),
          searchable: false,
          sortable : false,
          key: 'total',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.total)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
    actions={[
      new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
      new TableWrapper.action(`${t.translate('words.template')}`, 'mdi mdi-file-delimited', () => downloadTemplate()),
      new TableWrapper.action(`${t.translate('words.import')}`, 'mdi mdi-file-import', (item) => addSertel(item))
    ]}
    itemActions={[
      new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {
        temporaryStore.setProperties("credential", item)
        history.push(`${basePath}/${item.upload.id}`)}, (item)=>{
          if(item.status == "SUCCESS"){
            return false
          }
          return true
        }),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteById(item)}, (item)=>{
          if(item.status == "FINISH" || item.status == "FAILED"){
            return true
          }
          return false
        }),
    ]}
    onFetchData={ (params)=> {
      params = {
        ...search,
        ...params
      }
      return (
        new Promise(async (resolve, reject)=> {
          try {
            await initData()
            let user = JSON.parse(localStorage.getItem('user'))
            var companyId = user.company.id
            let getPage = null
            getPage = await service.findAll(params, companyId)
            getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
            setData(getPage.data)
            resolve(getPage)
          } catch(e){
            resolve({ data: [] })
            errorService(e)
          }
        })
      )
    }}
    showCommandbar={showCommandbar}
    showFilterPeriod={true}
    />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpimportCredentialsEbilling))
