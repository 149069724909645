import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/doppss/detail'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/archive/bp/doppss/detail');
  serviceUrl = '/api/eunifikasi/archive/bp/doppss/detail'
} else {
  service = new CrudService('/bp/doppss/detail');
}

export default service