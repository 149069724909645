import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/ssp/ebilling'
if(AppMode.onPremise){
  service = new CrudService('/api/ebilling/essp');
  serviceUrl = '/api/ebilling/essp'
} else {
  service = new CrudService('/ssp/ebilling/data-essp');
}

service.getOp = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/page`,
    query
  })
}

service.esspCalculate = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/calculate`,
    data
  })
}

service.esspCalculateOp = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/calculate/eunifikasi`,
    data
  })
}

service.esspGenerate = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/data-essp/generate`,
    data
  })
}

service.generate = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/billing-core/generate`,
    data
  })
}

service.generateAll = async(data)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/billing-core/generate/filter`,
    data
  })
}

service.save = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/billing-core/save`,
    data
  })
}

service.saveAll = async(data)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/billing-core/save/filter`,
    data
  })
}

service.csvBySelected = async(data)=>{
  return http.request({
    method : http.methods.POST,
    url : `${serviceUrl}/csv-checked`,
    data: {
      idList: data
    }
  })
}

service.csvByFilter = async(data)=>{
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/csv-filter`,
    data
  })
}

service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/delete`,
    data,
  })
}

service.esspPull = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/data-essp/pull`,
    data
  })
}

export default service;