import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/company/page');

service.createdOne= async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/company`,
    data,
  })
}

service.getOne= async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/company/${id}`,
  })
}
  
service.editById = async(id,data)=>{
  return http.request({
    method: http.methods.PUT,
    url: `/api/company`,
    data,
  })
}

  
service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/company/delete`,
    data,
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/company/${id}`,
  })
}

export default service;