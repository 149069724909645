import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrlMaster = '/master'
let serviceUrl = '/lt/spt'
let exportUrl = '/lt'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/lt');
  serviceUrlMaster = '/api/eunifikasi/master'
  serviceUrl = '/api/eunifikasi/lt/spt'
  exportUrl = '/api/eunifikasi/lt'
} else {
  service = new CrudService('/lt');
}

service.exportCurrent = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${exportUrl}/export`,
    data
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${exportUrl}/export/search`,
    query
  })
}

service.getBySpt = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query
  })
}

service.getNegara = async(name)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrlMaster}/country?size=200`,
      query: {
        'sort': 'name,ASC',
        'name.contains': name
      }
    })
  }

service.getProvince = async(name)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrlMaster}/province?size=1000`,
      query: {
        'sort': 'name,ASC',
        'name.contains': name
      }
    })
  }

service.getCity = async(name, provinceId)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrlMaster}/city?size=1000`,
      query: {
        'sort': 'name,ASC',
        'name.contains': name,
        'provinceId.equals': provinceId
      }
    })
  }

service.getDistrict = async(name, cityId)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrlMaster}/district?size=1000`,
      query: {
        'sort': 'name,ASC',
        'name.contains': name,
        'cityId.equals': cityId
      }
    })
  }

service.getSubDistrict = async(name, districtId)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrlMaster}/subdistrict?size=1000`,
      query: {
        'sort': 'name,ASC',
        'name.contains': name,
        'districtId.equals': districtId
      }
    })
}

export default service;