import React from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { sso } from '../../libs/react-mpk/hocs'
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import Companies from '../../modules/Companies'

const Index = ({history}) => {
  return (
    <PageWrapper 
      inverseTheme={true} 
      style={{background: '#D14C21'}}  
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/product/index/companies'
            render={props => (
              <Companies {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/product/index'
})(Index)