import React, {useEffect, useState} from 'react'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { Dialog, List, ListItem, DialogContent, DialogFooter  } from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import service from './OpPaymentOrder.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import errorServiceGet from './../../services/errorServiceGet'

const OpPaymentOrderForm = ({ 
  match, 
  navigationStore, 
  modalStore, 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  
  const basePath = 'paymentOrder'
  const baseId = 'mod-paymentOrder-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        const res = await service.getOne(match.params.id)
        setData({loading: false, content: res.data})
       
      }
    }
    initData()
  }, [])

  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }
  
  return (
    <>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate('words.npwp'),
          key: 'npwp',
          type: 'text',
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          disabled: true,
          width : "50%",
          validation : ['required', 'min:15']
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.name'),
          key: 'name',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.city'),
          key: 'city',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.address'),
          key: 'address',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.code'),
          key: 'code',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.idBilling'),
          key: 'idBilling',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.typeTax'),
          key: 'jpCode',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.jenisSetoran'),
          key: 'jsCode',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.month1'),
          key: 'bulan1',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.month2'),
          key: 'bulan2',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.year'),
          key: 'year',
          type: 'text',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate('words.total'),
          key: 'amount',
          disabled: true,
          width : "50%"
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.activeUntil'),
          key: 'activeUntil',
          type: 'text',
          disabled: true,
          width : "50%"
        },
      ]}
      // onSubmit={async (data, callback) => {
      //   try {
      //   //   var res = {}
      //   //   var formData = Object.assign({}, data)
      //   //   formData.kpp = {id: 1}
      //   //   var company = JSON.parse(localStorage.getItem('company'))

      //   //   if(window.location.href.indexOf('open') != -1){
      //   //     formData.company = company
      //   //   }
      //   //   formData.npwp = `${formData.npwp}`
      //   //   delete data.company.value
      //   //   if(match.params.id == 'new') {
      //   //     res = await service.createdOne(formData)
      //   //   } else {
      //   //     res = await service.editById(formData.id, formData)
      //   //   }

      //   //   callback(t.translate('sentences.tersimpan'), false, false, false)
      //     navigationStore.redirectTo(`${backTo}`)  
             
      //   } catch(e){
      //     ErrorService(e)
      //     callback("", false, false, false)
      //     return
      //   } 
      // }}
    />
  </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore', 'modalStore')(observer(OpPaymentOrderForm))
