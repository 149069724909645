import React from 'react'
import { Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter } from 'react-md'

const Modal = (props) => {
  let { 
    baseId                = 'react-mpk-modal',
    title                 = null, 
    children              = null, 
    footer                = null, 
    noFooter              = false,
    noPadding             = false,
    disableFocusOnMount   = false,
    visible               = false,
    onRequestClose        = () => {},
    ...dialogProps 
  } = props;
  return (
    <Dialog
      {...{
        visible,
        onRequestClose, 
        disableFocusOnMount,
        ...dialogProps
      }}
    >
      { title && (
        <DialogHeader>
          <DialogTitle id={`${baseId}-title`}>{title}</DialogTitle>
        </DialogHeader>
      )}
      <DialogContent className={noPadding ? 'mpk-padding-NONE padding-all' : ''}>{children}</DialogContent>
      { footer && <DialogFooter>{footer}</DialogFooter>}
    </Dialog>
  )
}

export default Modal
