import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/doppss'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/archive/bp/doppss');
  serviceUrl = '/api/eunifikasi/archive/bp/doppss'
} else {
  service = new CrudService('/bp/doppss');
}

service.saveData = async(data)=> {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}`,
        data,
    })
}

export default service;