import React, { useState } from 'react'
import Modal from './Modal';
import { Button } from 'react-md';
import t from 'counterpart';

function Confirm(props) {
  let {
    baseId          = 'react-mpk-modal-confirm',
    onRequestClose  = () => {console.warn('[REACT-MPK][MODAL-CONFIRM] on request close is not yet define')},
    onSubmit        = () => {},
    submitLabel     = null,
    cancelLabel     = null,
    ...dialogProps
  } = props;

  const [inProgress, setInProgress] = useState(false)

  const handleSubmit = (params) => {
    setInProgress(true)
    onSubmit((callback) => {
      setInProgress(false)
    })
  }

  return (
    <Modal 
      modal={true}
      onRequestClose={onRequestClose}
      footer={(
        <>
          <Button 
            id={`${baseId}-no`}
            onClick={onRequestClose}
            disabled={inProgress}
          >
            {cancelLabel || t.translate('mpk.column.no')}
          </Button>
          <Button 
            id={`${baseId}-yes`}
            onClick={handleSubmit}
            theme={ inProgress ? 'disabled' : 'primary'}
            disabled={inProgress}
          >
            {submitLabel || t.translate('mpk.column.yes')}
          </Button>
        </>
      )}
      {...dialogProps}
    />
  )
}

export default Confirm;
