import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/ebilling-core/paymentAccounts');

service.coreStatus = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/coreStatus`,
    query
  })
}

service.updateById = async(id, data)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/api/ebilling-core/paymentAccounts`,
    data
  })
}

service.getBank = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/bank`,
    query
  })
}

service.getBankCore = async(query)=>{
  return http.request({
    method: http.methods.GET,
    url : `/api/ebilling-core/banks`
  })
}

service.getPersepsiByBankId = async(query, id)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/paymentAccounts/persepsiByBankId/${id}`,
    query
  })
}

export default service;