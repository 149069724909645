import { makeAutoObservable } from 'mobx'
import { merge, find } from 'lodash'
import t from 'counterpart'

class EnvStore{
  locale = {
    code: 'id',
    options: [
      {label: 'Bahasa', value: 'id'},
      {label: 'English', value: 'en'}
    ]
  }

  env = {
    host              : '',
    baseUrl           : null,
    clientId          : null,
    applicationType   : 'product',
    appConsole        : []
  }

  widget = {
    active            : false,
    clientId          : null,
    parentOrigin      : null,
    redirectUri       : null,
    updateUri         : null
  }

  constructor(){
    makeAutoObservable(this)
  }

  setEnv(env={}){
    this.env = merge(this.env, env);
  }

  setWidget(widget={}){
    this.widget = merge(this.widget, widget);
  }

  setLocale(locale){
    this.locale = locale;
  }

  setLocaleCode(code){
    const existsCode = find(this.locale.options, {value: code});
    if(existsCode){
      this.locale.code = code;
      localStorage.setItem('lang', code)
      window.open(window.location.href, '_self')
    } else window.alert(`Locale code ${code} is not registered`);
  }

  setLocaleOptions(codes=['id', 'en']){
    this.locale.codes = codes;
  }
}

export default EnvStore;