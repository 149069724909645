import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import axios from 'axios';

let service = new CrudService('/api/organization');

service.uploadSertel = async(data)=> {
  return axios({
    method: http.methods.POST,
    url: `/api/eunifikasi/cert/upload`,
    data
  })
}

service.findAll = async(query, companyId)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/organization/company/${companyId}`,
    query
  })
}

service.createdById = async(query, companyId)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/organization/company/${companyId}`,
    query
  })
}

service.getCompany = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/company/page`,
    query
  })
}

service.getKpp = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/master/kpp`,
    query
  })
}
  
service.createdOne= async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/organization`,
    data,
  })
}

service.csvBulk= async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/users/csv/bulk`,
    data,
  })
}

service.csvParam= async(data) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/organization/csv/param`,
    data,
  })
}

service.editById = async(id, data)=>{
  return http.request({
    method: http.methods.PUT,
    url: `/api/organization`,
    data,
  })
}

service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/organization/delete`,
    data,
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/organization/${id}`,
  })
}

service.exportCsvBulk = async(data) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/organization/csv/bulk`,
    data
  })
}

service.exportCsvBulkOrg = async(data, companyId) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/organization/user/csv/bulk`,
    data
  })
}

service.exportCsvBulkParam = async(data, companyId) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/organization/user/csv/param`,
    data
  })
}

service.exportSummary = async(data) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/organization/summary/csv/bulk`,
    data
  })
}

service.exportSummaryParam = async(data) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/organization/summary/csv/param`,
    data
  })
}

service.updateKey = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/key/update`,
    data,
  })
}

export default service;