import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import Script from "react-load-script";
import routes from "./routes";
import "./App.scss";
import { init } from "./libs/react-mpk/hocs";
import qs from 'query-string';
import customInit from "./libs/custom/customInit"
import AppMode from './AppMode'

const App = ({envStore, navigationStore}) => {
  const [ checked, setChecked ] = useState(false);

  useEffect(() => {
    if(!checked && window.location.pathname === '/'){
      const parsedUrl = qs.parseUrl(window.location.href);
      navigationStore.redirectTo(`/${envStore.env.applicationType}${Object.keys(parsedUrl.query).length > 0 ? `?${qs.stringify(parsedUrl.query)}` : ''}`)
      setChecked(true);
    }
  }, [checked])

  return (
    <div className="App mpk-full viewport-height">
      {Object.keys(envStore.env.widgetInterface).map((key) => (
        <Script 
          key={`widget-interface-${key}`}
          url={envStore.env.widgetInterface[key].url} 
        />
      ))}
      <Router history={navigationStore.browserHistory}
        className="mpk-position relative"
      >
        {routes()}
      </Router>
      {/* {envStore.kbs && envStore.kbs.length > 0 ? (
        <SupportMpk appName={appInfo.name} kbs={kbs} />
      ) : null} */}
    </div>
  );
}

var initOnPremise = AppMode.onPremise
var initApp = init
if(initOnPremise){
  initApp = customInit
}

// SSO or OnPremise
export default initApp()(App);