import React, {useEffect, useState} from 'react'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { Dialog, List, ListItem, DialogContent, DialogFooter, TableCell} from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import service from './OpPaymentNotification.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import errorServiceGet from './../../services/errorServiceGet'

const OpFileTransaksiForm = ({ 
  match, 
  navigationStore, 
  modalStore, 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  
  const basePath = 'paymentNotification'
  const baseId = 'mod-file-transaksi-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        const res = await service.getInvoice(match.params.id)
        res.data.companyId = res.data.order.paymentAccount.companyId
        res.data.name = res.data.order.paymentAccount.name
        res.data.accountNumber = res.data.order.paymentAccount.accountNumber
        setData({loading: false, content: res.data})
      }
    }
    initData()
  }, [])

  async function resendExc(item) {
    var errors = []
    try {
      var proses = t.translate('words.resend')
      let res = await service.resendOrderFileByOrderId(item.id)
    } catch(e) {
      ErrorService(e)
      errors.push(await errorServiceGet(e,))
    }
    if(errors.length == 0){
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      document.getElementsByClassName('mdi-reload')[0].click()
    } else {
      modalStore.showInfo({
        title: '',
        children: (
          <List>
            {errors.map((er)=> {
              return <ListItem primaryText={''} secondaryText={er}></ListItem>
            })}
          </List>
        )  
      })
    }
  }

  async function resend(item) {
    modalStore.showConfirm({
      title : t.translate('words.resend'),
      children : t.translate('words.confirmResend'),
      onSubmit : (callback) => {
        resendExc(item)
        callback()
      }
    })
  }

  // var actions = [
  //   new TableWrapper.action(`${t.translate('words.updateKey')}`, 'mdi mdi-email-sync', (item) => resend(item), true),
  // ]

  // var edit = false
  // if(match.params.id == 'new') {
  //   actions = []
  // } else {
  //   edit = true
  // }

  var backTo = `/onpremise/${basePath}/${match.params.id}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }
  
  return (
    <>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.DIVIDER,
          label: t.translate('words.infoAccountPayment'),
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.companyId'),
          key: 'companyId',
          type: 'text',
          validation: 'required',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.userId'),
          key: 'name',
          type: 'text',
          validation: 'required',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.accountNumber'),
          key: 'accountNumber',
          type: 'text',
          validation: 'required',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.intructionDate'),
          key: 'instructionDate',
          type: 'text',
          validation: 'required',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.approval'),
          key: 'approval',
          type: 'text',
          validation: 'required',
          disabled : true
        },
        {
          inputType: inputTypes.DIVIDER,
          label: t.translate('words.fileStatus'),
        },
        
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.billFilename'),
          key: 'billFilename',
          type: 'text',
          validation: 'required',
          width : '50%',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.billStatus'),
          key: 'billStatus',
          type: 'text',
          validation: 'required',
          width : '50%',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.sspFilename'),
          key: 'sspFilename',
          type: 'text',
          validation: 'required',
          width : '50%',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.sspStatus'),
          key: 'sspStatus',
          type: 'text',
          validation: 'required',
          width : '50%',
          disabled : true
        },
      ]}
      submitLabel ={`${t.translate('words.resend')}`}
      onSubmit={async (data, callback) => {
        try {
          resend(data)
          // navigationStore.redirectTo(`${backTo}`)  
          callback("", false, false, false)
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore', 'modalStore')(observer(OpFileTransaksiForm))
