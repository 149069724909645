import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/ebilling/log-export-inquiry');

service.deleteById = async(id)=> {
  return http.request({
    method : http.methods.DELETE,
    url : `/api/ebilling/log-export-inquiry/delete/${id}`
  })
}

service.deleteBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling/log-export-inquiry/delete`,
    data
  })
}

service.download = async(id) => {
 return http.request({
   method: http.methods.GET,
   url : `/api/ebilling/log-export-inquiry/download/${id}`
 })
}

export default service;