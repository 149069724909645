import React, {useState, useEffect} from 'react'
import LoaderInfo from '../LoaderInfo'
import t from 'counterpart'
import { toast } from '../../services'
import { groupBy, merge, sortBy, cloneDeep } from 'lodash'
import { eventNames } from '../../config/constant'
import Plot from 'react-plotly.js';

const Chart = ({
  className     = '',
  eventName     = '',
  label         = null,
  onFetchData   = null,
  onInitData    = () => (new Promise(resolve => resolve([]))),
  chartConfig   = {},
  dataConfig    = {}
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [plotlyConfig, setPlotlyConfig] = useState(merge({
    layout:{
      title: label,
      autosize: true,
      height: 480,
    }
  }, chartConfig))

  const handleFetchData = async (query={}) => {
    if(onFetchData){
      setLoading(true)
      try {
        let res = await onFetchData(query)
        let initData = onInitData ? await onInitData(res.data) : res.data 
        
        let groupByCategory = groupBy(initData, d => (d.category))
        let finalData = []
        
        for(let key of Object.keys(groupByCategory)){
          let group = merge(cloneDeep(dataConfig), {
            x: [],
            y: [],
            name: key
          })

          groupByCategory[key] = sortBy(groupByCategory[key], (d) => (d.key))
          for(let item of groupByCategory[key]){
            group.x.push(item.key)
            group.y.push(item.value)
          }

          if(dataConfig.type === 'pie') {
            group.labels = group.x;
            group.values = group.y;
          }
          
          finalData.push(group)
        }

        setData(finalData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.errorRequest(error)
      }
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [onFetchData])

  useEffect(() => {
    if(eventName) window.addEventListener(eventName, e => {
      const { eventName, eventData } = e.detail
      switch(eventName){
        case eventNames.RELOAD:
          handleFetchData(eventData);
          break;
        default:
          break;
      }
    })

    return function cleanup(){
      if(eventName) window.removeEventListener(eventName, () => {}, false)
    }
  }, [eventName])

  return (
    <div className={`mpk-view-data-chart mpk-paper mpk-full full-width ${className}`}>
      {loading ? <LoaderInfo>{t.translate('mpk.sentence.loadingData')}</LoaderInfo> : (
        <Plot
          className="mpk-full full-width"
          data={data}
          {...plotlyConfig}
        />
      )}
    </div>
  )
}

export default Chart
