import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpPerusahaan.service'
import { toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'

const OpPerusahaan = ({ 
  authStore, 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})

  const basePath = 'company'
  const baseId = 'mod-company-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        let res = await service.getOne(match.params.id)
        setData({loading: false, content: res.data})
      }
    }
    initData()
  }, [])

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {`/onpremise/${basePath}`}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.name'),
          key: 'nama',
          type: 'text',
          validation: 'required',
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.email'),
          key: 'email',
          type: 'email',
          validation: 'required',
          validation: 'email',
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.phone'),
          key: 'telp',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.address'),
          key: 'alamat',
          type: 'text',
          validation: 'required',
        }
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          formData.active = false
          formData.kpp = {id : 1}
          console.log(data)
          if(match.params.id == 'new') {
            res = await service.createdOne(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`/onpremise/${basePath}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpPerusahaan))
