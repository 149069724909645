import React, {useEffect, useState} from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../libs/react-mpk/wrapper'
import { sso } from '../libs/react-mpk/hocs'
import { DashboardSample, TableSample, FormSample, ImportLogCsv, OpApprovalFlowForm, OpApprovalFlowTable, OpOrganization, OpOrganizationForm, OpUser, OpUserForm, OpOwner, OpOwnerForm, OpSettingEmailForm, OpSettingEmail, OpScheduleEmail, OpScheduleEmailForm, OpSettingEmailContent, OpSetEunifikasiForm, OpRoleTemplate, OpRoleTemplateForm, OpAboutForm,} from '../modules'
import { autorun, toJS } from 'mobx';
import { List, ListItem, FontIcon } from 'react-md'
import t from 'counterpart'
import onp from '../libs/custom/onp';
import OpLoginService from './../modules/OpLogin/OpLogin.service'
import axios from 'axios'
import AppMode from '../AppMode'
import OpSetContentEmailForm from '../modules/OpSetContentEmail/OpSetContentEmail.form'

const OnpremiseSpt = ({match, history}) => {

    let [user, setUser] = useState({})
    let [activeSidebar, setActiveSidebar] = useState([])

    useEffect(async () => {
        try {
          // set header
          var res = JSON.parse(localStorage.getItem('token'))
          axios.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`
          axios.defaults.withCredentials = true;
          let me = await OpLoginService.me()
          setUser(me.data)
          localStorage.setItem('user', JSON.stringify(me.data))
        } catch(e){
          window.location.href = '/#/login'
        }
        try {
          document.getElementsByClassName('mpk-appbar-shortcut')[0].style.display = 'none'
        } catch(e){}
    }, [])
    
    useEffect(async () => {
      sidebarInit()
    }, [user])

    var sidebarInit = ()=> {
        var sidebarMenuActive = [
            {
                "label":`${t.translate('menu.mainMenu')}`
            },
            {
                "label": `${t.translate('menu.mainMenu')}`,
                "children":[
                    {
                        "label":`${t.translate('menu.organization')}`,
                        "index":"dashboard",
                        "resourceUri":"",
                        "iconClassName":"mdi mdi-layout",
                        "path":`/onpremise/company/open/${companyId}/organization-op`,
                        "childPath":[]
                    },
                    {
                        "label":`${t.translate('menu.user')}`,
                        "index":"dashboard",
                        "resourceUri":"",
                        "iconClassName":"mdi mdi-layout",
                        "path":`/onpremise/company/open/${companyId}/user-op`,
                        "childPath":[]
                    },
                    {
                        "label":`${t.translate('menu.owner')}`,
                        "index":"dashboard",
                        "resourceUri":"",
                        "iconClassName":"mdi mdi-layout",
                        "path":`/onpremise/company/open/${companyId}/owner`,
                        "childPath":[]
                    },
                    {
                        "label":`${t.translate('menu.email')}`,
                        "index":"dashboard",
                        "resourceUri":"",
                        "iconClassName":"mdi mdi-layout",
                        "path":`/onpremise/company/open/${companyId}/email`,
                        "childPath":[]
                    },
                    {
                        "label":`${t.translate('menu.contentEmail')}`,
                        "index":"dashboard",
                        "resourceUri":"",
                        "iconClassName":"mdi mdi-layout",
                        "path":`/onpremise/company/open/${companyId}/contentEmail`,
                        "childPath":[]
                    },
                    {
                        "label":`${t.translate('menu.scheduleEmail')}`,
                        "index":"dashboard",
                        "resourceUri":"",
                        "iconClassName":"mdi mdi-layout",
                        "path":`/onpremise/company/open/${companyId}/schedule-email`,
                        "childPath":[]
                    },
                    {
                        "label":`${t.translate('menu.pengaturanUnifikasi')}`,
                        "index":"dashboard",
                        "resourceUri":"",
                        "iconClassName":"mdi mdi-layout",
                        "path":`/onpremise/company/open/${companyId}/pengaturanUnifikasi`,
                        "childPath":[]
                    },
                    {
                      "label": `${t.translate('menu.roleTemplate')}`,
                      "index": "role, template, pengaturan, setting, bupot",
                      "resourceUri": "",
                      "iconClassName": "mdi mdi-layout",
                      "path": `/onpremise/company/open/${companyId}/templateRole`,
                      "childPath": []
                    },
                    {
                      "label": `${t.translate('menu.about')}`,
                      "index": "tentang, company, about, setting, bupot",
                      "resourceUri": "",
                      "iconClassName": "mdi mdi-layout",
                      "path": `/onpremise/company/open/${companyId}/about`,
                      "childPath": []
                    }
                ]
            },
        ]

        setActiveSidebar(sidebarMenuActive)
    }

    const historys = toJS(history)
    var companyId = match.params.companyId
    var company = ''
    try {
        var getcompany = JSON.parse(localStorage.getItem('company'))
        if(getcompany){
            company  = getcompany.nama
        }
    } catch(e){
        console.log(e, 'getSpt')
    }

    return (
        <PageWrapper
            sidebarCollapsible={true}
            sidebarDefaultCollapse={false}
            inverseTheme={true}
            style={{background: '#D14C21'}}
            sidebarMenu={activeSidebar}
            useSidebar={true}
            username={user.login}
            handleLogout={async ()=> {
                let res = await OpLoginService.logout();
                localStorage.removeItem('token')
                window.location.href = '/#/login'
            }}
            sidebarHeader={(
              <List style={{ width: '100%', background: 'white', padding: '0px', height: '65px' }}>
                <ListItem style={{ marginTop: '-3px' }} secondaryText={`${company}`} rightAddon={<FontIcon onClick={()=>{
                  window.location.href = `/#/onpremise/daftar-spt`
                  //history.push(`/product/company/${companyId}/daftar-spt`)
                }} iconClassName="mdi mdi-close"></FontIcon>}>
                </ListItem>
              </List>
            )}
            handleProfile={()=> {
              window.location.href = `/#/onpremise/profile`
            }}
        >
            <Router history={history}>
                <Switch>
                  <Route 
                    path='/onpremise/company/open/:companyId/organization-op/:id'
                    render={props => (
                        <OpOrganizationForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/organization-op'
                    render={props => (
                      <OpOrganization {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/user-op/:id'
                    render={props => (
                      <OpUserForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/user-op'
                    render={props => (
                      <OpUser {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/owner/:id'
                    render={props => (
                      <OpOwnerForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/owner'
                    render={props => (
                      <OpOwner {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/email'
                    render={props => (
                      <OpSettingEmailForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/contentEmail'
                    render={props => (
                      <OpSetContentEmailForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/schedule-email'
                    render={props => (
                      <OpScheduleEmailForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/pengaturanUnifikasi'
                    render={props => (
                      <OpSetEunifikasiForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/templateRole/:id'
                    render={props => (
                      <OpRoleTemplateForm {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/templateRole'
                    render={props => (
                      <OpRoleTemplate {...props}/>
                    )}
                  />
                  <Route 
                    path='/onpremise/company/open/:companyId/about'
                    render={props => (
                      <OpAboutForm {...props}/>
                    )}
                  />
                </Switch>
            </Router>
        </PageWrapper>
    )
}


export default onp({
  basePath: '/onpremise/company/open/:companyId'
})(OnpremiseSpt);
