import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './ImportBp.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, TextField } from 'react-md'
import ErrorService from './../../services/errorService'
import { format } from '../../libs/react-mpk/services/number.service'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import AppMode from '../../AppMode'

const ImportBpCsv = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  modalStore,
  temporaryStore
}) => {

  const basePath = `import-bp-csv`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const baseId = `mod-import-bp-csv`
  let [search, setSearch] = useState({})
  let [dialog, setDialog] = useState(false)
  let [dialogTemplate, setDialogTemplate] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  const [exportDialog, setExportDialog] = useState(false);

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function importDataExec(item) {
    
  }

  async function importData(item) {
    setDialogItem(item)
    setDialog(true)
  }

  async function hapusExc(item) {
    try {
        var proses = t.translate('words.hapus')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.delete(di.id)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function downloadTemplate(){
    setDialogTemplate(true);
    // try {
    //   let res = await service.download({
    //     type: 'BP'
    //   })
    //   window.open(res.data.data.url)
    // } catch(e){
    //   errorService(e)
    // }
  }

  async function downloadTemplateFile(item){
    try {
      let res = await service.downloadFile(item.id)
      window.open(res.data.data.url)
    } catch(e){
      errorService(e)
    }
  }

  var exportData = () => {
    setExportDialog(true)
  }

  var exportSelected = () => {

  }

  var exportAll = () => {

  }
  
  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <Dialog 
          disableFocusOnMount={true}
          initialFocus="file-import-excel-dialog"
          id="file-import-excel-dialog"
          onFocus={()=>{}}
          visible={dialog}
          onRequestClose={()=> {
            setDialog(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="file-import-excel-dialog"
        >
          <DialogContent>
            <FormWrapper 
              style={{
                margin: '0px !important',
                width: '100%',
                maxWidth: '100%'
              }}
              defaultData={{
                npwp: dialogItem.npwp
              }}
              hintMessage={'Pastikan isi File Import anda sudah benar dan format file sesuai dengan template file.csv !'}
              showCommandbar={false}
              baseId = "mod-file-import-excel-form"
              title={t.translate('modules.organization.title')}
              definitions={[
                {
                  inputType : inputTypes.SELECT,
                  label : t.translate('words.type'),
                  key : 'type',
                  validation: 'required',
                  options: [
                    { label: 'BP', value: 'BP' },
                    { label: 'Setor Sendiri', value: 'SSP-SS' },
                    { label: 'DOSS/DOPP', value: 'DOSSPP' },
                    { label: 'SSP/PBK', value: 'SSP' },
                    { label: 'E-Billing ESSP', value: 'EBILLING_ESSP' }
                  ]
                },
                {
                  inputType : inputTypes.FILE_INPUT,
                  label : t.translate('words.file'),
                  key : 'file',
                  type : 'file',
                  validation: 'required',
                  multiple: true,
                  multiline: true,
                  accept: '.csv'
                }
              ]}
              onSubmit={async (values, callback)=> {
                try {
                    var proses = t.translate('words.import')
                    var selected = JSON.parse(TableWrapper.getSelected(baseId))
                    var selectedData = values.file
                    selected.map((d)=> selectedData.push(data[d]))
                    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                    var errors = []
                    bluebird.mapSeries(values.file, async (di, di2)=> {
                      setTimeout(async ()=> {
                        try {
                          var formData = new FormData()
                          formData.append('file', di.file)
                          formData.append('type', values.type)
                          await service.importData(formData)
                        } catch(e){
                          errors.push(await errorServiceGet(e, di, di2))
                        }
                        setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
                        if((di2+1) == selectedData.length){
                          setPloading({ loading: false, message: `` })
                          if(errors.length == 0){
                            iziToast.success({
                              title: proses,
                              message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                            })
                          } else {
                            modalStore.showInfo({
                              title: '',
                              children: (
                                <List>
                                  {errors.map((er)=> {
                                    return <ListItem primaryText={''} secondaryText={er}></ListItem>
                                  })}
                                </List>
                              )  
                            })
                          }
                          setDialog(false)
                          try{
                            var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                            if(checkboxRoot.checked){
                              if(checkboxRoot.getAttribute('aria-checked')){
                                checkboxRoot.click()
                                checkboxRoot.click()
                              } else {
                                checkboxRoot.click()
                              }
                            }
                            document.getElementsByClassName('mdi-reload')[0].click()
                          }catch(e){}
                        }
                      }, 1000 * di2)
                    })
                } catch(e) {
                    setDialog(false)
                    errorService(e)
                }
              }}
            />
          </DialogContent>
      </Dialog>
      <Dialog 
          disableFocusOnMount={true}
          initialFocus="file-import-template-dialog"
          id="file-import-template-dialog"
          onFocus={()=>{}}
          visible={dialogTemplate}
          onRequestClose={()=> {
            setDialogTemplate(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="file-import-template-dialog"
        >
          <DialogContent>
            <FormWrapper 
              style={{
                margin: '0px !important',
                width: '100%',
                maxWidth: '100%'
              }}
              defaultData={{
                npwp: dialogItem.npwp
              }}
              showCommandbar={false}
              baseId = "mod-file-import-excel-form"
              title={t.translate('modules.organization.title')}
              definitions={[
                {
                  inputType : inputTypes.SELECT,
                  label : t.translate('words.type'),
                  key : 'type',
                  validation: 'required',
                  options: [
                    { label: 'BP', value: 'BP' },
                    { label: 'Setor Sendiri', value: 'SSP-SS' },
                    { label: 'DOSS/DOPP', value: 'DOSSPP' },
                    { label: 'SSP/PBK', value: 'SSP' },
                    { label: 'E-Billing ESSP', value: 'EBILLING_ESSP' }
                  ]
                },
              ]}
              submitLabel={t.translate('words.download')}
              onSubmit={async (values, callback)=> {
                try {
                  let res = await service.download({
                    type: values.type
                  })
                  window.open(res.data.data.url)
                  setDialogTemplate(false)
                  callback('', true, false, false, false)
                } catch(e) {
                  setDialogTemplate(false)
                  errorService(e)
                  callback('', true, false, false, false)
                }
              }}
            />
          </DialogContent>
      </Dialog>
      <TableWrapper
        baseId={baseId}
        title={t.translate('menu.importCsv')}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        onFetchData={(params)=> {
          params = {
            ...search,
            ...params
          }
          // params['type.equals'] = 'BP'
          return (
            new Promise(async (resolve, reject)=> {
              try {
                let getPage = null
                if(window.location.href.indexOf('open') != -1){
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  params.npwpProfile = spt.npwp
                  getPage = await service.getBySpt(params)
                } else {
                  getPage = await service.get(params)
                }
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                setData(getPage.data)
                resolve(getPage)
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    localStorage.removeItem(`${baseId}-saved-search`)
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              if(values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
              if(values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
              setSearch(values)
              localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              {
                inputType : inputTypes.SELECT,
                label : t.translate('words.type'),
                key : 'type.equals',
                validation: 'required',
                options: [
                  { label: 'BP', value: 'BP' },
                  { label: 'Setor Sendiri', value: 'SSP-SS' },
                  { label: 'DOSS/DOPP', value: 'DOSSPP' },
                  { label: 'SSP/PBK', value: 'SSP' },
                  { label: 'E-Billing ESSP', value: 'EBILLING_ESSP' }
                ]
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.sptNpwp`),
                key: 'sptNpwp.contains',
                type: 'text',
                mask: '##.###.###.#-###.###',
                maskChar: '_',
                maskValue: 'string',
                width: '100%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.filename`),
                key: 'originalFilename.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.message`),
                key: 'message.contains',
                type: 'text'
              },
              {
                label: t.translate('words.progress'),
                searchable: true,
                sortable : true,
                width : 'auto',
                key: 'progress',
                type: TableWrapper.dataTypes.NUMBER,
                render: (item) => {
                  return (
                    <Progress 
                      percent={item.progress}
                    />
                  )
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.createdBy`),
                key: 'createdBy.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.lastModifiedBy`),
                key: 'lastModifiedBy.contains',
                type: 'text'
              },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.tanggal1`),
              //   key: 'createdDate.greaterOrEqualThan',
              //   type: 'text'
              // },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.tanggal2`),
              //   key: 'createdDate.lessOrEqualThan',
              //   type: 'text'
              // },
            ]}
          />
        }
        columns={[
          {
            label: t.translate('words.success'),
            searchable: true,
            sortable : true,
            key: 'success',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.success){
                return <Chip style={{ background: "#8BC34A", color: 'white'}}>{t.translate(`words.ya`)}</Chip>
              } else {
                return <Chip style={{ background: "#F44336", color: 'white'}}>{t.translate(`words.tidak`)}</Chip>
              }
            }
          },
          {
            label: t.translate('words.type'),
            searchable: true,
            sortable : true,
            key: 'type',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.type)
          },
          {
            label: t.translate('words.status'),
            searchable: true,
            sortable : true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => (<Chip>{item.status.toUpperCase()}</Chip>)
          },
          {
            label: t.translate('words.npwp'),
            searchable: true,
            sortable : true,
            key: 'npwp',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.npwp)
          },
          {
            label: t.translate('words.filename'),
            searchable: true,
            sortable : true,
            key: 'originalFilename',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.originalFilename)
          },
          {
            label: t.translate('words.md5'),
            searchable: true,
            sortable : true,
            key: 'md5',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.md5)
          },
          {
            label: t.translate('words.fileSize'),
            searchable: true,
            sortable : true,
            key: 'fileSize',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.fileSize)
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            sortable : true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
          {
            label: t.translate('words.createdBy'),
            searchable: true,
            sortable : true,
            key: 'createdBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          {
            label: t.translate('words.createdDate'),
            searchable: true,
            sortable : true,
            key: 'createdDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => ((item.createdDate))
          },
          {
            label: t.translate('words.lastModifiedBy'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedBy)
          },
          {
            label: t.translate('words.lastModifiedDate'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedDate)
          },
        ]}
        actions={[
          new TableWrapper.action(`${t.translate('words.import')}`, 'mdi mdi-file-import', (item) => {importData(item)}, true),
          new TableWrapper.action(`${t.translate('words.downloadTemplate')}`, 'mdi mdi-file-delimited', (item) => {downloadTemplate(item)}, true),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { hapus(item)}, true),
        ]}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.importDetail')}`, 'mdi mdi-history', (item) => {
            history.push(`import/${item.id}/import-detail-csv`)
          }),
          new TableWrapper.action(`${t.translate('words.download')}`, 'mdi mdi-download', (item) => { downloadTemplateFile(item) }, true),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}
export default inject('temporaryStore', 'modalStore')(observer(ImportBpCsv))