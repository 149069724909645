import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Bp26.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, TextField, Table, TableBody, TableRow, TableCell } from 'react-md'
import { format } from '../../libs/react-mpk/services/number.service'
import errorService from '../../services/errorService'
import ErrorService from './../../services/errorService'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'
import SptService from '../DaftarSpt/DaftarSpt.service'
import UtilsService from './../../services/utilsService'
import Bluebird from 'bluebird'

const Bp26 = ({
  className = '',
  showCommandbar = true,
  history,
  match,
  modalStore,
  temporaryStore,
  authStore
}) => {

  const basePath = `bp26`
  const [totalLnBruto, setTotalLnBruto] = useState(0)
  const [totalLnPph, setTotalLnPph] = useState(0)
  const [totalLnBruto2, setTotalLnBruto2] = useState(0)
  const [totalLnPph2, setTotalLnPph2] = useState(0)
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const baseId = 'mod-bp26'
  let [search, setSearch] = useState({})
  const [selectable, isSelectable] = useState(true)
  const [statusSpt, setStatusSpt] = useState("")
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogEmailBulk, setShowDialogEmailBulk] = useState(false)
  const [taxobjectCode, setTaxObjectCode] = useState([])
  const [dialogData, setDialogData] = useState({})
  const [organization, setOrganization] = useState([])
  const [input, setInput] = useState('');
  const [tags, setTags] = useState([]);
  const [error, setError] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [exportDialog, setExportDialog] = useState(false);
  const [fetchParam, setFetchParam] = useState([])

  var options = [
    { label: 'COMPLETED', value: 'COMPLETED' },
    { label: 'FINISH', value: 'FINISH' },
    { label: 'DELETED', value: 'DELETED' },
  ]

  useEffect(() => {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    if (window.location.href.indexOf('open') != -1) {
      isSelectable(true)
    }
    try {
      let getSpt = JSON.parse(localStorage.getItem('spt'))
      setStatusSpt(getSpt.status)
    } catch (e) { }

    if (AppMode.onPremise) {
      getOrganizationsOp()
    } else {
      getOrganizations()
    }
    try {
      document.getElementById('mod-bp26-send-email-form-trigger').setAttribute('type', 'button')
    } catch (e) { }
  }, [])

  async function initData() {
    try {
      if (window.location.href.indexOf('open') != -1) {
        let spt = JSON.parse(localStorage.getItem('spt'))
        let totalLn = await service.sptSummary({
          sptNpwp: spt.npwp,
          sptYear: spt.year,
          sptMonth: spt.month,
          sptRev: spt.rev,
        })
        setDialogData({
          "npwp.contains": spt.npwp,
          "sptMonth.greaterOrEqualThan": spt.month + "",
          "sptMonth.lessOrEqualThan": spt.month + "",
          "sptYear.greaterOrEqualThan": spt.year,
          "sptYear.lessOrEqualThan": spt.year
        })
        setTotalLnBruto(totalLn.data.bruto)
        setTotalLnPph(totalLn.data.pph)
      } else {
        let totalLn = await service.summary()
        setTotalLnBruto(totalLn.data.bruto)
        setTotalLnPph(totalLn.data.pph)
      }
    } catch (e) {
      errorService(e)
    }
  }

  const getOrganizations = async () => {
    let organizations = UtilsService.getOrganizations(toJS(authStore))
    organizations.map((d) => {
      d.label = d.npwp + " - " + d.name
      try {
        delete d.permission
      } catch (e) { }
    })
    var getAll = await Bluebird.mapSeries(organizations, async (org, OrgIndex) => {
      return new Promise(async (resolve) => {
        setTimeout(async () => {
          try {
            let getSearch = await SptService.getCertStatus(org.npwp)
            if (getSearch && getSearch.data && getSearch.data.data) {
              org = {
                ...org,
                ...getSearch.data.data
              }
              resolve(org)
            } else {
              resolve(org)
            }
          } catch (e) {
            resolve(org)
          }
        }, 50 * OrgIndex)
      })
    })
    var organizationsFilter = []
    try {
      getAll.map((og) => {
        if (og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch (e) {
      organizations.map((d) => {
        organizationsFilter.push({
          value: d.npwp,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
  }

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      errorService(e)
      return []
    }
  }

  async function reportBulkExc(item) {
    try {
      var proses = t.translate('words.report')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.postReport(selectedData)
            window.open(proccedThis.data.data.url)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == 1) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function reportExc(item) {
    try {
      var proses = t.translate('words.report')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.getReport(di.id)
            window.open(proccedThis.data.data.url)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function report(item) {
    modalStore.showConfirm({
      title: t.translate('words.cetak'),
      children: t.translate('words.confirmReport'),
      onSubmit: (callback) => {
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d) => selectedData.push(data[d]))
        if (selectedData.length == 1) {
          reportExc(item)
        } else {
          reportBulkExc(item)
        }
        callback()
      }
    })
  }

  async function reuploadExc(item) {
    try {
      var proses = t.translate('words.reupload')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.getReupload(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function reupload(item) {
    modalStore.showConfirm({
      title: t.translate('words.reupload'),
      children: t.translate('words.confirmReupload'),
      onSubmit: (callback) => {
        reuploadExc(item)
        callback()
      }
    })
  }

  async function requestExc(item) {
    try {
      var proses = t.translate('words.request')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.getRequestReport(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function request(item) {
    modalStore.showConfirm({
      title: t.translate('words.request'),
      children: t.translate('words.confirmRequest'),
      onSubmit: (callback) => {
        requestExc(item)
        callback()
      }
    })
  }

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.delete(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate('words.delete'),
      children: t.translate('words.hapusIni'),
      onSubmit: (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function email(item) {
    setShowDialog(true)
  }

  async function getTaxObjectCode(type) {
    let res = await service.getTaxObjectCode(type)
    var taxobjectCode = []
    res.data.map((d) => {
      d.label = `${d.code} - ${d.description}`
      taxobjectCode.push({ value: d.code, label: `${d.code} - ${d.description}`, rates: d.rates })
      setTaxObjectCode(taxobjectCode)
    })
    return taxobjectCode
  }

  async function emailBulk(item) {
    await getTaxObjectCode("PPH26")
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setShowDialogEmailBulk(true)
  }

  var columns = [
    {
      label: t.translate('words.status'),
      searchable: true,
      sortable: true,
      key: 'status',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.status == "UPLOADING") {
          return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "COMPLETED") {
          return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FINISH") {
          return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FAILED") {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "DELETED") {
          return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else {
          return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        }
      }
    },
    {
      label: t.translate('words.no'),
      searchable: true,
      sortable: true,
      key: 'no',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.no) {
          return item.no
        } else {
          return "-"
        }
      }
    },
    {
      label: t.translate('words.revNo'),
      searchable: true,
      sortable: true,
      key: 'revNo',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.revNo) {
          return item.revNo
        } else {
          return "0"
        }
      }
    },
    {
      label: t.translate('words.type'),
      searchable: true,
      sortable: true,
      key: 'type',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.type)
    },
    {
      label: t.translate('words.objectCode'),
      searchable: true,
      sortable: true,
      key: 'objectCode',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.objectCode)
    },
    {
      label: t.translate('menu.report'),
      searchable: true,
      sortable: true,
      key: 'report',
      type: TableWrapper.dataTypes.BOOLEAN,
      render: item => {
        if (item.report) {
          return <Chip>YA</Chip>
        } else {
          return <Chip>TIDAK</Chip>
        }
      }
    },
    {
      label: t.translate('words.npwpProfile'),
      searchable: true,
      sortable: true,
      key: 'sptNpwp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptNpwp)
    },
    {
      label: t.translate('words.name'),
      searchable: true,
      sortable: true,
      key: 'name',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.name)
    },
    {
      label: t.translate('words.identity'),
      searchable: true,
      sortable: true,
      key: 'identity',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.identity)
    },
    {
      label: t.translate('words.email'),
      searchable: true,
      sortable: true,
      key: 'email',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.email)
    },
    {
      label: t.translate('words.reference'),
      searchable: true,
      sortable: true,
      key: 'reference',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.reference)
    },
    {
      label: t.translate('words.bruto'),
      searchable: true,
      sortable: true,
      key: 'bruto',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (format(item.bruto))
    },
    {
      label: t.translate('words.rates'),
      searchable: true,
      sortable: true,
      key: 'rates',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (format(item.rates))
    },
    {
      label: t.translate('words.pph'),
      searchable: true,
      sortable: true,
      key: 'pph',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (format(item.pph))
    },
    {
      label: t.translate('words.date'),
      searchable: true,
      sortable: true,
      key: 'date',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.date)
    },
    {
      label: t.translate('words.sptMonth'),
      searchable: true,
      sortable: true,
      key: 'sptMonth',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptMonth)
    },
    {
      label: t.translate('words.sptYear'),
      searchable: true,
      sortable: true,
      key: 'sptYear',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptYear)
    },
    {
      label: t.translate('words.revisiSpt'),
      searchable: true,
      sortable: true,
      key: 'sptRev',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptRev)
    },
    {
      label: t.translate('words.message'),
      searchable: true,
      sortable: true,
      key: 'message',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.message)
    },
    {
      label: t.translate('words.createdBy'),
      searchable: true,
      sortable: true,
      key: 'createdBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdBy)
    },
    {
      label: t.translate('words.createdDate'),
      searchable: true,
      sortable: true,
      key: 'createdDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdDate)
    },
    {
      label: t.translate('words.lastModifiedBy'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedBy)
    },
    {
      label: t.translate('words.lastModifiedDate'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedDate)
    },
  ]

  if (window.location.href.indexOf('open') != -1) {
    // delete columns[5]
    // delete columns[6]
    // delete columns[7]
  }

  var insideSpt = false
  var actions = [
    // new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
  ]
  if (window.location.href.indexOf('open') != -1) {
    insideSpt = true
    actions = [
      new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
      new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => { report(item) }, true),
      new TableWrapper.action(`${t.translate('words.request')}`, 'mdi mdi-file', (item) => { request(item) }, true),
      new TableWrapper.action(`${t.translate('words.reupload')}`, 'mdi mdi-file-upload', (item) => { reupload(item) }, true),
    ]
    if (statusSpt != 'FINISH') {
      actions.push(
        new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item) }, true),
        new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => {
          localStorage.removeItem('bpform')
          history.push(`${basePath}/new`)
        }, true),
      )
    }
  } else {
    actions.push(new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true))
    actions.push(new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => { report(item) }, true))
  }

  //if(AppMode.onPremise == false){
  // actions.push(new TableWrapper.action(`${t.translate('words.kirimEmailBulk')}`, 'mdi mdi-email-multiple', (item) => { emailBulk(item)}, true))
  actions.push(new TableWrapper.action(`${t.translate('words.kirimEmail')}`, 'mdi mdi-email', (item) => { email(item) }, true))
  actions.push(new TableWrapper.action(`${t.translate('words.kirimEmailBulk')}`, 'mdi mdi-email-multiple', (item) => { emailBulk(item) }, true))
  //}

  var itemActions = [
    new TableWrapper.action(`${t.translate('words.history')}`, 'mdi mdi-history', (item) => {
      localStorage.setItem('bp', JSON.stringify(item))
      history.push(`${basePath}/${item.id}/history`)
    }, true),
    // new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true)
  ]
  if (window.location.href.indexOf('open') != -1) {
    itemActions = [
      new TableWrapper.action(`${t.translate('words.Edit')}`, 'mdi mdi-pencil', (item) => history.push(`${basePath}/${item.id}`), (item) => {
        if (item.status == "DELETED" || item.status == "CANCELED" || statusSpt == "FINISH") {
          return false
        }
        return true
      }),
      new TableWrapper.action(`${t.translate('words.view')}`, 'mdi mdi-eye', (item) => history.push(`${basePath}/${item.id}`), (item) => {
        if (item.status == "DELETED" || item.status == "CANCELED" || statusSpt == "FINISH") {
          return true
        }
        return false
      }),
      new TableWrapper.action(`${t.translate('words.history')}`, 'mdi mdi-history', (item) => {
        localStorage.setItem('bp', JSON.stringify(item))
        history.push(`${basePath}/${item.id}/history`)
      }, true),
      // new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => {report(item)}, true),
      // new TableWrapper.action(`${t.translate('words.reupload')}`, 'mdi mdi-file-upload', (item) => {reupload(item)}, true),
      // new TableWrapper.action(`${t.translate('words.request')}`, 'mdi mdi-file', (item) => { request(item)}, true),
      // new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
    ]
  }

  var searchDef = [
    {
      render: (
        <div className="mpk-data-filter">
          <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
            <p>{t.translate(`words.pencarian`)}</p>
          </div>
        </div>
      )
    },
    // {
    //   render: (
    //     <div style={{ width: '100%' }}>
    //       {!AppMode.onPremise &&
    //         <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
    //       }
    //     </div>
    //   )
    // },
    {
      inputType: inputTypes.CHECKBOX,
      label: t.translate(`words.allNpwps`),
      key: 'allNpwps',
      type: 'text',
      show : AppMode.onPremise ? false : true
    },
    {
      inputType: inputTypes.REACT_SELECT,
      label: t.translate(`words.npwp`),
      key: 'npwp.in',
      labelKey: 'label',
      valueKey: 'label',
      data: organization,
      options: organization,
      async: true,
      defaultOptions: organization,
      isMulti: true,
      loadOptions: async (inputValues) => {
        if (AppMode.onPremise) {
          if (inputValues.length % 2) {
            var options = await getOrganizationsOp(inputValues)
            return options
          } else {
            return organization
          }
        } else {
          var filter = organization.filter((org) => {
            return org.label.indexOf(inputValues) != -1
          })
          return filter
        }
      },
      show: !insideSpt
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month1`),
      key: 'month.greaterOrEqualThan',
      type: 'text',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month2`),
      key: 'month.lessOrEqualThan',
      type: 'text',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.year`),
      key: 'year.equals',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.rev`),
      key: 'rev.equals',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.name`),
      key: 'name.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.identity`),
      key: 'identity.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.reference`),
      key: 'reference.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.objectCode`),
      key: 'objectCode.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.no`),
      key: 'no.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.DATEPICKER,
      label: t.translate(`words.date`),
      key: 'date.equals',
      type: 'date'
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.status`),
      key: 'status.equals',
      type: 'text',
      options: [
        { label: 'CREATED', value: 'CREATED' },
        { label: 'POSTING', value: 'POSTING' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'FINISH', value: 'FINISH' },
        { label: 'FAILED', value: 'FAILED' },
        { label: 'DELETED', value: 'DELETED' },
      ]
    },
  ]

  if (window.location.href.indexOf('open') != -1) {
    searchDef = [
      {
        render: (
          <div className="mpk-data-filter">
            <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
              <p>{t.translate(`words.pencarian`)}</p>
            </div>
          </div>
        )
      },
      // {
      //   render: (
      //     <div style={{ width: '100%' }}>
      //       {!AppMode.onPremise &&
      //         <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
      //       }
      //     </div>
      //   )
      // },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.name`),
        key: 'name.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.identity`),
        key: 'identity.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.reference`),
        key: 'reference.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.objectCode`),
        key: 'objectCode.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.no`),
        key: 'no.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.DATEPICKER,
        label: t.translate(`words.date`),
        key: 'date.equals',
        type: 'date'
      },
      {
        inputType: inputTypes.SELECT,
        label: t.translate(`words.status`),
        key: 'status.equals',
        type: 'text',
        options: [
          { label: 'CREATED', value: 'CREATED' },
          { label: 'POSTING', value: 'POSTING' },
          { label: 'COMPLETED', value: 'COMPLETED' },
          { label: 'FINISH', value: 'FINISH' },
          { label: 'FAILED', value: 'FAILED' },
          { label: 'DELETED', value: 'DELETED' },
        ]
      },
    ]
  }

  var hintMessage = t.translate('words.belumDiPosting')
  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if (window.location.href.indexOf('open') != -1) {
      var c = spt.postingDate
      if (c) {
        hintMessage = t.translate('words.tanggalPosting') + spt.postingDate
      }
    } else {
      hintMessage = ''
    }
  } catch (e) { }

  //Tag Email
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  //validasi email
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function isValid(email) {
    let error = null;

    if (isInList(email)) {
      error = `${email} Sudah diinputkan`;
    }

    if (!validateEmail(email)) {
      error = `${email} bukan alamat email yang valid`;
    }

    if (error) {
      setError(error)

      return false;
    }

    setError(null)
    return true;
  }
  

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    
    if(key === "Enter" || key === ',' || key == 'Tab'){
      e.preventDefault();
      if(isValid(trimmedInput) && trimmedInput){
        setTags(prevState => [...prevState, trimmedInput]);
        setInput('');
      }
      // document.getElementById('mod-bp-send-email-form-trigger').setAttribute('type', 'button')
      // if(validateEmail(trimmedInput) && !isInList(trimmedInput)){
      //   setTags(prevState => [...prevState, trimmedInput]);
      //   setInput('');
      // }else{
      //   iziToast.error({
      //     icon: 'mdi mdi-information',
      //     message: t.translate('words.validateEmail')
      //   })
      //   return
      // }
    }
    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  const deleteTag = (index) => {
    setTags(prevState => prevState.filter((tag, i) => i !== index))
  }

  const onPaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));
      setTags(prevState => [...prevState, ...toBeAdded]);
      setInput('');
    }
  }

  function isInList(email) {
    return tags.includes(email);
  }

  var exportData = () => {
    setExportDialog(true)
  }

  var exportSelected = async (dataMod) => {
    setExportDialog(false)
    var exportData = []
    if(dataMod){
      exportData = dataMod
    } else {
      exportData = data
    }
    try {
      var proses = t.translate('words.exportData')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.exportCurrent(exportData)
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      window.open(res.data.url)
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  var exportAll = async () => {
    setExportDialog(false)
    var exportAllExc = false
    var dataSelected = []
    try {
      var cQuery = JSON.parse(localStorage.getItem(`${baseId}-query`))
      var params = fetchParam; params.size = 200;
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })

      // INIT DATA
      if (window.location.href.indexOf('open') != -1) {
        let spt = JSON.parse(localStorage.getItem('spt'))
        params.sptNpwp = spt.npwp
        params.sptYear = spt.year
        params.sptMonth = spt.month
        params.sptRev = spt.rev
        let getPage = await service.getBySpt(params)
        getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        dataSelected = getPage.data
        if(parseInt(getPage.headers['x-total-count']) > 100){
          exportAllExc = true
        } else {
          exportAllExc = false
        }
      } else {
        let getPage = await service.get(params)
        getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        dataSelected = getPage.data
        if(parseInt(getPage.headers['x-total-count']) > 100){
          exportAllExc = true
        } else {
          exportAllExc = false
        }
      }
      // INIT DATA
      if(exportAllExc){
        var proses = t.translate('words.exportData')
        
        let res = await service.exportAll(fetchParam)
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
        })
      } else {
        exportSelected(dataSelected)
      }
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  } 

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setError(null)
          setTags([])
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={showDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setShowDialog(false)
        }}
        style={{
          width: 800,
          // height: AppMode.onPremise ? 200 : 400
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.kirimEmail')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-send-email`}
            defaultData={{}}
            hintShowIcon={true}
            hintMessage={t.translate('words.askEmail')}
            submitLabel={t.translate('words.kirim')}
            definitions={[
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.sendOneByOne`),
                key: 'sendOneByOne',
                width: '50%',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                render: (
                  <>
                    <TextField
                      value={input}
                      key='email'
                      style={{ width: '100%', height: '55px' }}
                      label="Email"
                      onKeyDown={onKeyDown}
                      onKeyUp={onKeyUp}
                      onChange={onChange}
                      onPaste={onPaste}
                      className={'tx '+ (error && " has-error")}
                    />
                    {error && <p className="error">{error}</p>}
                  </>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
              {
                render: (
                  <div className="tags">
                    {tags.map((tag, index) => (
                      <div className="tag">
                        {tag}
                        <button type='button' onClick={() => deleteTag(index)}>x</button>
                      </div>
                    ))}
                  </div>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setShowDialog(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var selected = JSON.parse(TableWrapper.getSelected(baseId))
                var selectedData = []
                selected.map((d) => selectedData.push(data[d].id))
                if (tags && tags.length > 0) {
                  var stringIn = ""
                  tags.map((d) => {
                    stringIn += d + ","
                  })
                  values.email = stringIn
                }
                setTags([])
                if(AppMode.onPremise){
                  let res = await service.sendMail(selectedData, { customEmail: values.email, sendOneByOne: values.sendOneByOne })
                  if(res.status == 200) setTags([])
                } else {
                  let res = await service.sendMail(selectedData, { email: values.email, sendOneByOne: values.sendOneByOne })
                  if(res.status == 200) setTags([])
                }
                callback(`${t.translate('words.kirimEmail')} ${t.translate('words.prosesDone')}`, false, false, false)
                setShowDialog(false)
              } catch (e) {
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.kirimEmail')} ${t.translate('words.prosesDone')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={showDialogEmailBulk}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setShowDialogEmailBulk(false)
          setTags([])
          setError(null)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.kirimEmailBulk')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-send-email`}
            defaultData={dialogData}
            hintShowIcon={true}
            hintMessage={t.translate('words.askEmail')}
            submitLabel={t.translate('words.kirim')}
            definitions={[
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.sendOneByOne`),
                key: 'sendOneByOne',
                width: '50%',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                render: (
                  <TextField
                    value={input}
                    key='email'
                    style={{ width: '100%', height: '55px' }}
                    label="Email"
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onChange={onChange}

                  />
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
              {
                render: (
                  <div className="tags">
                    {tags.map((tag, index) => (
                      <div className="tag">
                        {tag}
                        <button onClick={() => deleteTag(index)}>x</button>
                      </div>
                    ))}
                  </div>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.npwp`),
                key: 'npwp.contains',
                type: 'text',
                width: '50%',
                show: insideSpt
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '100%',
                show: !insideSpt
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp.in',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues) => {
                  if (AppMode.onPremise) {
                    if (inputValues.length % 2) {
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    var filter = organization.filter((org) => {
                      return org.label.indexOf(inputValues) != -1
                    })
                    return filter
                  }
                },
                show: !insideSpt
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.identity`),
                key: 'identity.contains',
                type: 'text',
                width: '100%'
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.kodeObjekPajak`),
                key: 'objectCode.contains',
                type: 'text',
                width: '100%',
                options: taxobjectCode
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'sptMonth.greaterOrEqualThan',
                type: 'text',
                width: '25%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'sptMonth.lessOrEqualThan',
                type: 'text',
                width: '25%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.dariTahun`),
                key: 'sptYear.greaterOrEqualThan',
                type: 'text',
                width: '25%'
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.keTahun`),
                key: 'sptYear.lessOrEqualThan',
                type: 'text',
                width: '25%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allStatus`),
                key: 'allStatus',
                type: 'text',
                width: '100%',
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.status`),
                key: 'status.in',
                type: 'text',
                width: '100%',
                isMulti: true,
                options: options
              }
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setShowDialogEmailBulk(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var formData = Object.assign({}, values)
                formData['sptMonth.lessOrEqualThan'] = parseInt(formData['sptMonth.lessOrEqualThan'])
                formData['sptMonth.greaterOrEqualThan'] = parseInt(formData['sptMonth.greaterOrEqualThan'])
                if (formData['status.in'] && formData['status.in'].length > 0) {
                  var stringIn = ""
                  formData['status.in'].map((d) => {
                    stringIn += d.value + ","
                  })
                  formData['status.in'] = stringIn
                }
                if(values.allStatus){
                  var allStatusString = ""
                  options.map((d) => {
                    allStatusString += d.value + ","
                  })
                  formData['status.in'] = allStatusString
                  delete formData.allStatus
                }
                if (formData['npwp.in'] && formData['npwp.in'].length > 0) {
                  var stringIn = ""
                  formData['npwp.in'].map((d) => {
                    if (AppMode.onPremise) {
                      stringIn += d.npwp + ","
                      delete formData.allNpwp
                    } else {
                      stringIn += d.value.npwp + ","
                    }
                  })
                  formData['npwp.in'] = stringIn
                }
                if(values.allNpwp){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  formData['npwp.in'] = allNpwpString
                  if(AppMode.onPremise) delete formData['npwp.in']
                }
                if (tags && tags.length > 0) {
                  var stringIn = ""
                  tags.map((d) => {
                    stringIn += d + ","
                  })
                  values.email = stringIn
                }
                delete formData.email
                if (AppMode.onPremise) {
                  formData['month.greaterOrEqualThan'] = formData['sptMonth.greaterOrEqualThan']
                  delete formData['sptMonth.greaterOrEqualThan']
                  formData['month.lessOrEqualThan'] = formData['sptMonth.lessOrEqualThan']
                  delete formData['sptMonth.lessOrEqualThan']
                  formData['year.greaterOrEqualThan'] = formData['sptYear.greaterOrEqualThan']
                  delete formData['sptYear.greaterOrEqualThan']
                  formData['year.lessOrEqualThan'] = formData['sptYear.lessOrEqualThan']
                  delete formData['sptYear.lessOrEqualThan']
                }
                setTags([])
                if (AppMode.onPremise) {
                  formData.customEmail = values.email
                  let res = await service.sendMailBulkOp(formData, values.email)
                  if(res.status == 200) setTags([])
                } else {
                  let res = await service.sendMailBulk(formData, values.email)
                  if(res.status == 200) setTags([])
                }
                callback(`${t.translate('words.kirimEmailBulk')} ${t.translate('words.prosesDone')}`, false, false, false)
                setShowDialogEmailBulk(false)
              } catch (e) {
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.kirimEmailBulk')} ${t.translate('words.prosesFail')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <TableWrapper
        tableFooter={(
          <Table style={{ width: '100%' }} dense disableBorders>
            <TableBody>
              <TableRow>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpPph')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnPph2)}</b></TableCell>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpBruto')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnBruto2)}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpPph2')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnPph)}</b></TableCell>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpBruto2')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnBruto)}</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        hintMessage={hintMessage}
        baseId="mod-bp26"
        title={t.translate('modules.bp26.title')}
        className={className}
        selectable={selectable}
        //   hintMessage={t.translate('modules.bp26.hint.message')}
        //   hintIconClassName="mdi mdi-information"
        //   hintMore={t.translate('modules.bp26.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        onFetchData={(params) => {
          params = {
            ...search,
            ...params
          }
          return (
            new Promise(async (resolve, reject) => {
              try {
                await initData()
                let getPage = null
                if (window.location.href.indexOf('open') != -1) {
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  params.sptNpwp = spt.npwp
                  params.sptYear = spt.year
                  params.sptMonth = spt.month
                  params.sptRev = spt.rev
                  getPage = await service.getBySpt(params)
                } else {
                  getPage = await service.get(params)
                }
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                try {
                  if(getPage.headers['x-summary'] == "true"){
                    setTotalLnBruto2(parseFloat(getPage.headers['x-bruto']))
                    setTotalLnPph2(parseFloat(getPage.headers['x-pph']))
                  }
                } catch(e){}
                setFetchParam(params)
                setData(getPage.data)
                resolve(getPage)
              } catch (e) {
                setFetchParam(params)
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName='mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            onChange={(formData, key, val)=> {
              setFetchParam(formData)
            }}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => {
                    setSearch({})
                    localStorage.removeItem(`${baseId}-saved-search`)
                    setTimeout(() => {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback) => {
              if (values['date.equals']) values['date.equals'] = moment(new Date(values['date.equals'])).format('YYYY-MM-DD')
              if (AppMode.onPremise) {
                if (values['npwp.in'] && values['npwp.in'].length > 0) {
                  var stringIn = ""
                  values['npwp.in'].map((d) => {
                    stringIn += d.value + ","
                  })
                  values['npwp.in'] = stringIn
                }
              } else {
                if (values['npwp.in'] && values['npwp.in'].length > 0) {
                  var stringIn = ""
                  values['npwp.in'].map((d) => {
                    stringIn += d.value.npwp + ","
                  })
                  values['npwp.in'] = stringIn
                }
                if(values.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  values['npwp.in'] = allNpwpString
                  delete values.allNpwps
                }
              }
              
              setSearch(values)
              localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
              setTimeout(() => {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={searchDef}
          />
        }
        columns={columns}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(Bp26))