import React, {useEffect, useState} from 'react'
import { TableWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpImportInquiry.service'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import { Chip, ListItem, List, Switch, TableBody, TableRow,Table, TableCell } from 'react-md'
import errorService from '../../services/errorService'
import iziToast from 'izitoast'
import { Dialog, DialogContent, Button} from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import fileSaver from 'file-saver';
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'
import {toJS}from 'mobx'

const OpImportInquiryDetail = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  temporaryStore,
  match
}) => {

  const [data, setData] = useState([])
  const [file, setFile] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  
  const baseId = 'mod-op-importInquiry'
  const basePath= 'importInquiry'

  async function initData() {}

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    var file = toJS(temporaryStore).properties.importInquiry
    setFile(file)
  }, [])

  return (
    <>
    <TableWrapper
      tableHeader={(
        <List style={{width: '100%'}}>
          <ListItem rightAddon = {"Status : "+ file.status}>{"File ID : "+ file.fileId}</ListItem>
          <ListItem rightAddon = {Math.round(file.count/file.total * 100)+ " %"}>{"Step "+ file.step+"/2 - "+file.count+"/1" }</ListItem>
        </List>
      )}
      baseId={baseId}
      title={t.translate(`modules.${basePath}.title`)}
      className={className}
      defaultData={[]}
      defaultSortBy="line"
      columns={[
        {
          label: t.translate('words.line'),
          searchable: true,
          sortable : true,
          key: 'line',
          type: TableWrapper.dataTypes.STRING,
          render: item =>(item.line)
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item =>(
            item.messages.map((d)=>{
              return <div>
               {d.message}
              </div>
            })
          )
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.back')}`, 'mdi mdi-arrow-left', ()=>{
          history.push(`/onpremise/${basePath}`)
        })
      ]}
      itemActions={[]}
      onFetchData={ (params)=> {
        params = {
          ...search,
          ...params,
          fileId : match.params.id
        }
        return (
          new Promise(async (resolve, reject)=> {
            try {
              await initData()
              let getPage = await service.validation(params)
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data[0])
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
      useFilter = {false}
    />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpImportInquiryDetail))
