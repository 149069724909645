import React from 'react';
import { withRouter } from 'react-router-dom';
import cookies from 'react-cookies';
import { inject, observer } from 'mobx-react';
import http from '../services/http.service';
import qs from 'query-string';
import _ from 'lodash';
import { LoaderInfo } from '../components';
import t from 'counterpart';

const errorTypes = {
  noAccessToken: 'NO_ACCESS_TOKEN'
}

const requestOptions = {
  useDefaultHost: false,
  useDefaultBaseUrl: false,
  useDefaultHeader: false,
}

const ssoWrapper = (options={}) => WrappedComponent => {
  let opt = _.merge({
     url: {
        login                 : '/api/sso/login',
        exchangeToken         : '/api/sso/exchangeToken/{code}',
        refreshToken          : '/api/sso/refreshToken/{refreshToken}',
        me                    : '/api/sso/me'
      },      
      onNoAccessToken         : null,
      accessTokenName         : 'SP_ACCESS_TOKEN',
      refreshTokenName        : 'SP_REFRESH_TOKEN',
      showErrorUnauthorized   : true,
      onComplete              : () => {},
      basePath                : '/'
  }, options)
  
  class sso extends React.Component{
    constructor(){
      super();
      this.state = {
        inProgress: true,
        isRefreshingToken: false,
        resolveQueue: []
      }
    }
    
    async componentDidMount(){
      if(!this.props.authStore.isLoggedIn){
        await this.setErrorHandler();
        await this.setLogoutAction()
        this.checkingAuth();
        if(this.props.router)
          this.props.navigationStore.setRouter(this.props.router);
      } else {
        this.getProfile()
      }
    }

    setErrorHandler = () => {
      return new Promise(resolve => {
        http.setErrorHanlder((err) => {
          if(err.response){
            const { data } = err.response;
            err.message = data.message || data.error_description || data.error || data.errmsg || data.errorMessage || data;
            err.message = typeof err.message === 'object' ? (
              err.message[this.props.envStore.locale.code] ? err.message[this.props.envStore.locale.code] : err.message
             ) : err.message;

            if(err.response.status === 500){
              this.props.navigationStore.errorRequest(err);
            } else {
              let authenticate = err.response.headers['www-authenticate'] || err.response.headers['WWW-authenticate'];
              if(authenticate){
                authenticate = authenticate.replace(/,/g, '&').replace(/"/g, '');
                let parsed = qs.parse(authenticate);
                err.response.message = parsed.error_description || parsed.error;
                err.message = err.message || err.response.headers['www-authenticate'];
                err.response.statusText = err.response.status === 401 ? 'Unauthorized' : 'Bad Request';
              }

              if(err.message && typeof err.message === 'string' && err.message.match(new RegExp('(session timeout|token expired)', 'ig'))){
                // this.props.dialogActions.showAlert(
                //   t.translate('word.message'),
                //   t.translate('sentence.confirm.acccessTokenExpired')
                // )
                this.props.navigationStore.errorRequest(err)
              } else {
                if(err.response.status === 401){
                  if(opt.showErrorUnauthorized) this.props.navigationStore.errorRequest(err);
                  else this.props.authStore.logout(window.location.href);
                } else {
                  return err;
                }
              }
            }
          } else err.message = 'Request Error. Check your connection!'
          return err;
        })
        resolve();
      })
    }

    setLogoutAction = () => {
      return new Promise(resolve => {
        this.props.authStore.setLogoutAction((redirectUri, callback) => {
          const { clientId, state } = this.props.envStore.env.apiGateway;
          const accessToken = this.props.authStore.getAccessToken();
          callback();
          window.open(`${this.props.envStore.env.sso.host}/auth/oauth/logout?${qs.stringify({
            client_id: clientId,
            access_token: accessToken,
            redirect_uri: redirectUri,
            state
          })}`, '_self')
        })

        resolve();
      })
    }

    checkingAuth = async () => {
      let { onNoAccessToken } = opt;
      try{
        let accessToken = await this.checkToken();
        if(!accessToken) accessToken = await this.exchangeToken();
        this.endProcess();
      }catch(error){
        switch(error.errorType){
          case errorTypes.noAccessToken:
            if(onNoAccessToken) onNoAccessToken(this.endProcess, this.props);
            else{
              this.login();
            }
            break;
          default:
            this.props.navigationStore.errorRequest(error)
            break;
        }
      }
    }

    login = () => {
      http.request({
        method: http.methods.GET, 
        url: `${opt.url.login}?${qs.stringify({
          redirect_uri: window.encodeURIComponent(window.location.href)
        })}`,
        options: requestOptions
      }).then(res => {
        window.open(res.data, '_self');
      })
    }

    endProcess = (directToLogin=false) => {
      if(directToLogin) this.login();
      else {
        this.setHttpInterceptors();
        this.getProfile();
      }
    }

    getProfile = async () => {
      let { params } = this.props.match
      let url = opt.url.me 
      let basePath = opt.basePath

      for(let key of Object.keys(params)){
        url = url.replace(`:${key}`, params[key])
        basePath = basePath.replace(`:${key}`, params[key])
      }
      try {
        let res = await http.get(url, {}, {}, requestOptions);
        this.props.authStore.setProfile(res.data);
        
        const { application, product } = res.data;
        const baseUrl = product ? product.baseUrl : (
          application ? application.baseUrl : null
        )

        http.setBaseUrl(baseUrl)
        http.setCompanyId(params.companyId)

        let { menu } = res.data;
        if(menu){
          let defaultMenu;
          if(basePath === window.location.pathname){
            for(let item of menu){
              if(item.children){
                defaultMenu = item.children[0];
                break;
              }
            }
            setTimeout(() => this.props.navigationStore.redirectTo(defaultMenu ? defaultMenu.path : opt.basePath));
          }
        }

        this.setState({inProgress:false})
        opt.onComplete(this.props, res.data);
      } catch (error) {
        console.log(error);
      }
    }

    checkToken = () => {
      return new Promise(async (resolve, reject) => {
        let accessToken = cookies.load(opt.accessTokenName);
        let refreshToken = cookies.load(opt.refreshTokenName);
        if(!accessToken) {
          if(refreshToken) {
            try{
              let res = await http.get(opt.url.refreshToken.replace('{refreshToken}', refreshToken), {}, {}, {...requestOptions, ignoreInterceptor: true});
              this.saveAccessToken(res.data);
              resolve(res.data.access_token)
            }catch(error){
              reject(error)
            }
          } else {
            resolve(null);
          }
        } else resolve(accessToken);
      })
    }

    exchangeToken = () => {
      return new Promise(async (resolve, reject) => {
        let parsedUrl = qs.parseUrl(window.location.href);
        if(parsedUrl.query.code){
          try{
            let url = opt.url.exchangeToken.replace('{code}', parsedUrl.query.code);
            let res = await http.get(url, {}, {}, requestOptions);
            this.saveAccessToken(res.data);

            let currentUrl = window.location.pathname;
            delete parsedUrl.query.code;
            delete parsedUrl.query.redirect_uri;
            delete parsedUrl.query.state;
          
            this.props.navigationStore.redirectTo(`${currentUrl}?${qs.stringify(parsedUrl.query)}`);
            resolve(res.data.access_token);
          }catch(error){
            reject(error);
          }
        }else reject({errorType: errorTypes.noAccessToken});
      })
    }

    saveAccessToken = ({access_token, refresh_token, expires_in}) => {
      // const cookieOptions = {path: '/', maxAge: expires_in, httpOnly: false}
      // cookies.save(opt.accessTokenName, access_token, cookieOptions);
      // cookies.save(opt.refreshTokenName, refresh_token, cookieOptions);
      this.props.authStore.setHasAccessToken(true);
    }

    setHttpInterceptors = () => {
      const setHeaders = (config) => {
        let accessToken = cookies.load(opt.accessTokenName);
        config.headers = config.headers || {};
        if(accessToken) config.headers.Authorization = 'Bearer ' + accessToken
      }

      http.setRequestInterceptor((config, url) => {
        return new Promise(async (resolve, reject) => {
          let accessToken = cookies.load(opt.accessTokenName);
          let refreshToken = cookies.load(opt.refreshTokenName);

          if(!accessToken && refreshToken){
            let { isRefreshingToken, resolveQueue } = this.state;
            if(isRefreshingToken){
              resolveQueue.push(resolve);
              this.setState({resolveQueue});
            } else {
              this.setState({isRefreshingToken: true});
              try{
                await this.checkToken();
                setHeaders(config);
                resolve(config);
                if(resolveQueue.length > 0){
                  for(let i = resolveQueue.length - 1 ; i >= 0; i--){
                    let q = resolveQueue[i];
                    q(config);
                    resolveQueue.splice(i, 1);
                    this.setState({resolveQueue});
                  }
                }
                this.setState({isRefreshingToken: false});
              }catch(error){
                resolve();
              }
            }
          } else {
            setHeaders(config);
            resolve(config);
          }
        })
      });
    };

    render(){
      return(
        <div className="mpk-sso mpk-full viewport-width viewport-height">
          {this.state.inProgress ? (
            <LoaderInfo>
              {t.translate('mpk.sentence.gettingUserInformation')}
            </LoaderInfo>
          ) : (
            <WrappedComponent {...this.props}/>
          )}
        </div>
      )
    }
  }

  return inject('authStore', 'envStore', 'navigationStore', 'temporaryStore', 'modalStore')(observer(withRouter(sso)))
}

export default ssoWrapper;