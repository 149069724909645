import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from '../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from 'react-md'
import ErrorService from '../../services/errorService'

const DoppDokumenLainForm = ({ authStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [isDisabled, setIsDisabled] = useState(false)

  const baseName = `doppDokumenLain-archive`
  const basePath = `doppDokumenLain-archive`

  useEffect(() => {
    const initialData = {
      loading: false,
      content: {
        // jmlBruto7a: 0,
        // jmlBruto7b: 0,
        // jmlPph7a: 0,
        // jmlPph7b: 0,
        // jmlPph7c: 0,
        // jmlPph7d: 0,
        // jmlPph7e: 0,
      }
    }
    async function initData(){
      try {
        setData({ loading: false, content: props.mergeForm.dopp })
      } catch(e){
        ErrorService(e)
        setData({ loading: false, content: initialData })
      }
    }
    initData()

    let getSpt = JSON.parse(localStorage.getItem('spt'))
    if(getSpt.status == 'FINISH' || getSpt.status == 'SUBMIT'){
      setIsDisabled(true)
    }

  }, [props.mergeForm])

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}
  
  return (
    <>
      <FormWrapper
        tableForm={true}
        showCommandbar={false}
        style={{
          maxWidth: '100%'
        }}
        className="fullWidth"
        loading={data.loading}
        onChange={async (formData, key, value)=> {
          // if(key != 'dppTotal' || key != 'pphTotal'){
          //   formData['dppTotal'] = clearFormat(formData['a1Dpp']) + clearFormat(formData['b1Dpp']) + clearFormat(formData['c1Dpp']) + clearFormat(formData['d1Dpp'])
          //   formData['pphTotal'] = clearFormat(formData['a1Pph']) + clearFormat(formData['b1Pph']) + clearFormat(formData['c1Pph']) + clearFormat(formData['d1Pph'])
          // }
          props.setMergeForm({
            ...props.mergeForm,
            dopp: formData
          })
        }}
        baseId={`mod-form-${baseName}`}
        title={t.translate(`modules.${baseName}.formTitle`)}
        hintMessage={t.translate(`modules.${baseName}.formTitle`)}
        defaultData={data.content}
        definitions={{
          header: [],
          body: [
            [
              {
                render: (
                  <TableCell style={{ width: '33%' }}><strong>{t.translate(`words.doppDokumenLain1`)}</strong></TableCell>
                )
              },
              {
                render: (
                  <TableCell style={{ width: '33%' }}><strong>{t.translate(`words.doppDokumenLain2`)}</strong></TableCell>
                )
              },
              {
                render: (
                  <TableCell style={{ width: '33%' }}><strong>{t.translate(`words.doppDokumenLain3`)}</strong></TableCell>
                )
              },
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.doppDokumenLain4`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlBruto34',
                type: 'text',
                disabled : isDisabled
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlPph34',
                type: 'text',
                disabled : isDisabled
              },
            ],
            [
              {
                render: (
                  <TableCell colSpan={3}>{t.translate(`words.doppDokumenLain5`)}</TableCell>
                )
              }
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.doppDokumenLain6`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlBruto35a',
                type: 'text',
                disabled : isDisabled
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlPph35a',
                type: 'text',
                disabled : isDisabled
              },
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.doppDokumenLain7`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlBruto35b',
                type: 'text',
                disabled : isDisabled
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlPph35b',
                type: 'text',
                disabled : isDisabled
              },
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.doppDokumenLain8`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlBruto36',
                type: 'text',
                disabled : isDisabled
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlPph36',
                type: 'text',
                disabled : isDisabled
              },
            ],
            // [
            //   {
            //     render: (
            //       <TableCell><b>{t.translate(`words.doppDokumenLain9`)}</b></TableCell>
            //     )
            //   },
            //   {
            //     inputType: inputTypes.INPUT_MASK_NUMBER,
            //     thousandSeparator: thousandSeparator,
            //     decimalSeparator: decimalSeparator,
            //     isNumericString: true,
            //     label: '',
            //     key: 'dppTotal',
            //     type: 'text',
            //     disabled: true
            //   },
            //   {
            //     inputType: inputTypes.INPUT_MASK_NUMBER,
            //     thousandSeparator: thousandSeparator,
            //     decimalSeparator: decimalSeparator,
            //     isNumericString: true,
            //     label: '',
            //     key: 'pphTotal',
            //     type: 'text',
            //     disabled: true
            //   },
            // ],
          ]
        }}
        // onSubmit={async (data, callback) => {
        //   var res ={}
        //   try {
        //     res = await service.put(data.id, data)
        //     callback(t.translate('sentences.tersimpan'), false)   
        //   } catch(e){
        //     ErrorService(e)
        //     callback("", false, false)
        //     return
        //   } 
        // }}
      />
    </>
  )
}

export default inject('authStore', 'envStore')(observer(DoppDokumenLainForm))
