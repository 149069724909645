import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error from './pages/Error';
import Internal from './pages/Internal.routes';
import Product from './pages/Product/Product.routes';
import Onpremise from './pages/Onpremise.routes';
import OnpremiseSpt from './pages/Onpremise.spt.routes';
import OnpremiseSptArchive from './pages/Onpremise.sptArchive.routes';
import OnpremiseCompany from './pages/Onpremise.company.routes';

import OpLogin from './modules/OpLogin/OpLogin'
import OpForgotPasswword from './modules/OpLogin/OpResetPassword'

const routes = () => (
  <Switch>
    <Route path="/internal" render={(props) => (
      <Internal {...props}/>
    )}/>
    <Route path="/product" render={(props) => (
      <Product {...props}/>
    )}/>
    <Route path="/onpremise/company/open/:companyId" render={(props) => (
      <OnpremiseCompany {...props}/>
    )}/>
    <Route path="/onpremise/daftar-spt-archive/open/:sptId" render={(props) => (
      <OnpremiseSptArchive {...props}/>
    )}/>
    <Route path="/onpremise/daftar-spt/open/:sptId" render={(props) => (
      <OnpremiseSpt {...props}/>
    )}/>
    <Route path="/onpremise" render={(props) => (
      <Onpremise {...props}/>
    )}/>
    <Route path="/error" render={(props) => (
      <Error {...props}/>
    )}/>
    <Route path="/login/forgetPassword/" render={(props) => (
      <OpForgotPasswword {...props}/>
    )}/>
    <Route path="/login" render={(props) => (
      <OpLogin {...props}/>
    )}/>
  </Switch>
);

export default routes