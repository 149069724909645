import React, {useEffect, useState} from 'react'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { Dialog, List, ListItem, DialogContent, DialogFooter, Card, Divider, FontIcon, TableCell, Table, TableBody, TableRow, TableHeader, TableFooter, Button, CardTitle} from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import service from './OpPaymentOrder.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import errorServiceGet from './../../services/errorServiceGet'
import { autorun, toJS } from 'mobx';
import { format } from '../../libs/react-mpk/services/number.service'
import Modal from '../../libs/react-mpk/components/Modal'
import OpPaymentOrderTable from '../OpPaymentAccount/OpPaymentAccount.table'

const OpMandiriCmsForm = ({ 
  match, 
  navigationStore, 
  modalStore, 
  history
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [order, setOrder] = useState([])
  const [akun, setAkun] = useState([])
  const basePath = 'paymentOrder'
  const baseId = 'mod-paymentOrder-form'
  const [orderAll, setOrderAll] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [billingList, setBillingList] = useState([])
  const [sspList, setSspList] = useState([])
  const [total, setTotal] = useState(0)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    async function initData(){
      setData({loading: false, content: {}})
      try {
        var temp = JSON.parse(localStorage.getItem('paymentOrder'))
        setOrderAll(temp.orderAll)
        setSelectedData(temp.sspList)
        var total = 0
        var bilList = []
        var spList = []
        temp.sspList.map((d)=> {
          total += d.amount
          if(d.idBilling){
            bilList.push(d)
          } else {
            spList.push(d)
          }
        })
        setBillingList(bilList)
        setSspList(spList)
        setTotal(total)
      } catch(e){}
    }
    initData()
  }, [])

  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }
  
  return (
    <>
    <Modal
          disableFocusOnMount={true}
          id="payment-account-form"
          aria-label="payment-account-form"
          visible={showModal}
          onRequestClose={()=> {
            setShowModal(false)
          }}
          style={{
            width: 1024, 
            height: 600
          }}
        >
          <OpPaymentOrderTable match={match} onSearch={true} setShowModal={setShowModal} setData={setData} data={data} history={history} />
    </Modal>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style = {{
        maxWidth : '100%'
      }}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.mandiriCms`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      submitLabel={`${t.translate('words.proses')}`}
      definitions={[
        {
          render: (
            <>
              {!orderAll &&
                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableRow>
                        <TableCell>{t.translate('words.no')}</TableCell>
                        <TableCell>{t.translate('words.code')}</TableCell>
                        <TableCell>{t.translate('words.idBilling')}</TableCell>
                        <TableCell>{t.translate('words.npwp')}</TableCell>
                        <TableCell>{t.translate('words.name')}</TableCell>
                        <TableCell>{t.translate('words.typeTax')}</TableCell>
                        <TableCell>{t.translate('words.jenisSetoran')}</TableCell>
                        <TableCell>{t.translate('words.masaPajak')}</TableCell>
                        <TableCell>{t.translate('words.jumlahSetoran')}</TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {selectedData.map((d, k)=> {
                      try {
                        return (
                          <TableRow key={k}>
                            <TableCell>{k+1}</TableCell>
                            <TableCell>{d.code}</TableCell>
                            <TableCell>{d.idBilling}</TableCell>
                            <TableCell>{d.npwp}</TableCell>
                            <TableCell>{d.name}</TableCell>
                            <TableCell>{d.jpCode}</TableCell>
                            <TableCell>{d.jsCode}</TableCell>
                            <TableCell>{d.bulan1 + ' - ' + d.bulan2 + ' ' + d.year}</TableCell>
                            <TableCell>{format(d.amount)}</TableCell>
                        </TableRow>
                        )
                      } catch(e){
                        return (<div></div>)
                      }
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                        <TableCell colSpan={8}><b>{t.translate('words.total')}</b></TableCell>
                        <TableCell colSpan={8}><b>{format(total)}</b></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              }
            </>
          )
        },
        {
          render: (
            <div style={{ width: '100%' }}>
            <Button theme="primary" themeType="contained" style={{ margin: 15, width: '25%' }} onClick={()=> setShowModal(true)}><FontIcon iconClassName="mdi mdi-magnify" />
              {t.translate('words.cariSigner')}
            </Button>
            </div>
          )
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.id'),
          key: 'paymentAccount',
          type: 'text',
          disabled: true,
          width : '100%',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.briCmsCompanyId'),
          key: 'companyId',
          type: 'text',
          disabled: true,
          width : '50%',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.briCmsName'),
          key: 'name',
          type: 'text',
          disabled: true,
          width : '50%',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.briCmsAccountNumber'),
          key: 'accountNumber',
          type: 'text',
          disabled: true,
          validation: 'required',
        },
        // {
        //   inputType: inputTypes.REACT_SELECT,
        //   label: t.translate('words.persetujuanTransaksi'),
        //   key: 'approval',
        //   type: 'text',
        //   options: [
        //     { value: 'S', label: 'Summary - Virtual Account' },
        //     { value: 'D', label: 'Detail - Giro ' }
        //   ],
        //   validation: 'required',
        // },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)

          if(orderAll){
            let res = await service.validateOrderAll({
              paymentAccount: formData.paymentAccount,
              // paymentAccount: "BNIDIRECT",
              // approval: formData.approval,
              // companyId: formData.companyId,
              // name: formData.name,
              accountNumber: formData.accountNumber,
            })
            callback("", false, false, false)
            modalStore.showConfirm({
              dialogstyle: {
                width: '100px',
              },
              children: `Jumlah SSP yang akan diorder adalah ${res.data.totalItem} (${res.data.billingCount} SSP dengan ID billing dan ${res.data.sspCount} SSP tanpa ID billing) dengan total jumlah setor ${format(res.data.totalAmount)}. Apakah anda yakin melanjutkan proses order ?`,
              onSubmit: async (callback)=> {
                try {
                  let order = await service.order(res.data)
                  callback(`${t.translate('words.transaksi')} ${t.translate('words.prosesDone')}`, false, false, false)
                  navigationStore.redirectTo(`/onpremise/paymentNotification`) 
                } catch(e){
                  callback("", false, false, false)
                  ErrorService(e)
                  return
                }
              }
            })
          } else {
            let res = await service.validateOrder({
              paymentAccount: formData.paymentAccount,
              // companyId: formData.companyId,
              // name: formData.name,
              // accountNumber: formData.accountNumber,
              // approval: formData.approval,
              billingList: billingList,
              // paymentAccount: "BNIDIRECT",
              sspList: sspList,
              totalAmount: total,
              totalItem: selectedData.length,
            })
            callback("", false, false, false)
            modalStore.showConfirm({
              dialogstyle: {
                width: '100px',
              },
              children: `Jumlah SSP yang akan diorder adalah ${res.data.totalItem} (${res.data.billingCount} SSP dengan ID billing dan ${res.data.sspCount} SSP tanpa ID billing) dengan total jumlah setor ${format(res.data.totalAmount)}. Apakah anda yakin melanjutkan proses order ?`,
              onSubmit: async (callback)=> {
                try {
                  let order = await service.order(res.data)
                  callback("", false, false, false)
                  navigationStore.redirectTo(`/onpremise/paymentNotification`) 
                } catch(e){
                  callback("", false, false, false)
                  ErrorService(e)
                  return
                }
              }
            })
          }         
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore', 'modalStore', 'temporaryStore')(observer(OpMandiriCmsForm))
