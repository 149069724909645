import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/setting/emailContent/page');

service.editById = async(id, data)=>{
	return http.request({
    method: http.methods.PUT,
    url: `/api/setting/emailContent`,
    data,
	})
}

service.findAll = async(query, companyId)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/setting/emailContent/company/${companyId}`,
    query
  })
}

export default service;