import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './LogExport.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import Hint from '../../libs/react-mpk/components/Hint/Hint'
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
import errorService from '../../services/errorService'
import { TableCheckbox } from 'react-md'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import ClockLoader from 'react-spinners/ClockLoader'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import AppMode from '../../AppMode'

const LogExport = ({
  modalStore,
  className       = '',
  showCommandbar  = true,
  history,
  match
}) => {

  const basePath = `log-export`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  const baseId = 'mod-log-export'
  const [exportDialog, setExportDialog] = useState(false);

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function hapusExc(item) {
    try {
        var proses = t.translate('words.hapus')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.delete(di.id)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  var exportData = () => {
    setExportDialog(true)
  }

  var exportSelected = () => {

  }

  var exportAll = () => {

  }

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <TableWrapper
        baseId={baseId}
        title={t.translate(`modules.${basePath}.title`)}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              try{
                params = {
                  ...search,
                  ...params
                }
                var getPage;
                if(window.location.href.indexOf('open') != -1){
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  // params.sptNpwp = spt.npwp
                  // params.sptYear = spt.year
                  // params.sptMonth= spt.month
                  // params.sptRev  = spt.rev
                  getPage = await service.getBySpt(params, spt.id)
                } else {
                  getPage = await service.get(params)
                }
                setData(getPage.data)
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch(e){
                resolve()
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    localStorage.removeItem(`${baseId}-saved-search`)
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              if(values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
              if(values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
              setSearch(values)
              localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              // {
              //   render: (
              //     <div style={{ width: '100%', marginBottom: '24px' }}>
              //       {!AppMode.onPremise &&
              //         <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
              //       }
              //     </div>
              //   )
              // },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.status`),
                key: 'status.equals',
                type: 'text',
                options: [
                    { label: 'PROCESS', value: 'PROCESS' },
                    { label: 'COMPLETED', value: 'COMPLETED' },
                    { label: 'FAILED', value: 'FAILED' },
                    { label: 'DELETED', value: 'DELETED' },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.type`),
                key: 'type.equals',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.objectName`),
                key: 'objectName.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.message`),
                key: 'message.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.createdBy`),
                key: 'createdBy.contains',
                type: 'text'
              },

              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal1`),
                key: 'createdDate.greaterOrEqualThan',
                type: 'date'
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal2`),
                key: 'createdDate.lessOrEqualThan',
                type: 'date'
              },
            ]}
          />
        }
        columns={[
          {
            label: t.translate('words.status'),
            searchable: true,
            sortable : true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.status && item.status == "COMPLETED"){
                return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
              } else {
                return <Chip>{(item.status+"").toUpperCase()}</Chip>
              } 
            }
          },
          {
            label: t.translate('words.type'),
            searchable: true,
            sortable : true,
            key: 'type',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.type)
          },
          {
            label: t.translate('words.progress'),
            searchable: true,
            sortable : true,
            key: 'progress',
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => {
              return (
                <div className='progress'>
                  <Progress 
                    percent={item.progress}
                    strokeWidth ={6}
                    theme={{
                      success: {
                        color: '#049e51',
                        trailColor : '#e0e0e0'
                      },
                      active: {
                        color: 'yellow',
                        trailColor : '#e0e0e0'
                      },
                      default: {
                        color: 'blue',
                        trailColor : '#e0e0e0'
                      }
                    }}
                  />
                </div>
              )
            }
          },
          {
            label: t.translate('words.objectName'),
            searchable: true,
            sortable : true,
            key: 'objectName',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.objectName)
          },
          {
            label: t.translate('words.counter'),
            searchable: true,
            sortable : true,
            key: 'counter',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.counter)
          },
          {
            label: t.translate('words.total'),
            searchable: true,
            sortable : true,
            key: 'total',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.total)
          },
          {
            label : t.translate('words.message'),
            searchable : true,
            sortable : true,
            key : 'message',
            type : TableWrapper.dataTypes.STRING,
            render : item => (item.message)
          },
          {
            label: t.translate('words.createdBy'),
            searchable: true,
            sortable : true,
            key: 'createdBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          {
            label: t.translate('words.createdDate'),
            searchable: true,
            sortable : true,
            key: 'createdDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => ((item.createdDate))
          },
          {
            label: t.translate('words.lastModifiedBy'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedBy)
          },
          {
            label: t.translate('words.lastModifiedDate'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedDate)
          },
        ]}
        actions={[
          new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true)
        ]}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item)=>{
            history.push(`${basePath}/${item.id}`)
          }, true),
          new TableWrapper.action(`${t.translate('words.download')}`, 'mdi mdi-download', (item) => {
            // localStorage.setItem('spt', JSON.stringify(item))
            // history.push(`${basePath}/${item.id}/history`)
            if(item.url){
              window.open(item.url)
            } else {
              iziToast.info({
                title: "",
                message: 'File belum tersedia.'
              })
            }
          })
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('modalStore')(observer(LogExport))
