import { makeAutoObservable } from 'mobx'; 
import cookies from 'react-cookies';

class AuthStore {
  user = {};
  isLoggedIn = false;
  logoutAction = null;
  tokenNames = {
    accessToken: 'SP_ACCESS_TOKEN',
    refreshToken: 'SP_REFRESH_TOKEN',
    guestToken: 'MPK_GUEST_TOKEN'
  }
  hasAccessToken = cookies.load(this.tokenNames.accessToken);

  constructor(){
    makeAutoObservable(this)
  }

  setTokenNames(accessToken, refreshToken, guestToken){
    this.tokenNames.accessToken = accessToken;
    this.tokenNames.refreshToken = refreshToken;
    this.tokenNames.guestToken = guestToken;
    this.hasAccessToken = cookies.load(this.tokenNames.accessToken);
  }

  setHasAccessToken(hasAccessToken){
    this.hasAccessToken = hasAccessToken;
  }

  setProfile(user){
    this.user = user;
    this.isLoggedIn = true;
  }

  setLogoutAction(logoutAction){
    this.logoutAction = logoutAction
  }

  clearCookie = () => {
    cookies.remove(this.tokenNames.accessToken, {path:'/'});
    cookies.remove(this.tokenNames.refreshToken, {path:'/'});
  }

  logout = (redirectUri = window.location.origin) => {
    this.user = {};
    this.isLoggedIn = false;
    if(this.logoutAction) this.logoutAction(window.encodeURIComponent(redirectUri), () => {
      this.clearCookie();
    }); 
    else this.clearCookie();
  }

  setAccessToken = (accessToken) => {
    cookies.save(this.tokenNames.accessToken, accessToken);
  }

  getAccessToken = () => {
    let accessToken = cookies.load(this.tokenNames.accessToken);
    return accessToken;
  }

  getGuestToken = () => {
    let guestToken = cookies.load(this.tokenNames.guestToken);
    return guestToken;
  }
}

export default AuthStore;