import CrudService from './../../services/crudService'
import {http} from './../../libs/react-mpk/services'

let service = new CrudService(`/api/templateRoleEunifikasi`)

service.findAll = async(query, companyId)=>{
  return http.request({
    method : http.methods.GET,
    url :`/api/templateRoleEunifikasi/page?companyId.equals=${companyId}`,
    query
  })
}

service.getCompany = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url :`/api/company/page`,
    query
  })
}

service.getById = async(query,companyId, name)=>{
  return http.request({
    method : http.methods.GET,
    url :`/api/templateRoleEunifikasi/page?companyId.equals=${companyId}&name.equals=${name}`,
    query
  })
}

service.editById = async(id, data)=>{
  return http.request({
    method: http.methods.PUT,
    url: `/api/templateRoleEunifikasi`,
    data,
  })
}

service.createdOne = async(data)=>{
  return http.request({
    method: http.methods.POST,
    url: `/api/templateRoleEunifikasi`,
    data,
  })
}

service.deleteBulk = async(data)=>{
  return http.request({
    method: http.methods.POST,
    url: `/api/templateRoleEunifikasi/delete`,
    data,
  })
}

service.deleteById = async(id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/templateRoleEunifikasi/${id}`,
  })
}

service.permission = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/permissionEunifikasis`,
    query
  })
}

service.access = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/accessEunifikasi`,
    query
  })
}

service.getCompany = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/company/page`,
    query
  })
}

export default service;