import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/master/kpp');

service.createdOne= async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/master/kpp`,
    data,
  })
}
  
service.editById = async(id, data)=>{
  return http.request({
    method: http.methods.PUT,
    url: `/api/master/kpp`,
    data,
  })
}

  
service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/master/kpp/delete`,
    data,
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/master/kpp/${id}`,
  })
}

export default service;