import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpUser.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'

const OpUserForm = ({ 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [organization, setOrganization] = useState([])
  const [auth, setauth] = useState([])
  const [isShow, setisShow] = useState(true)
  const [isShowBanned, setisShowBanned] = useState(false)
  const [isShowOrg, setisShowOrg] = useState(true)
  const [isShowAuth, setisShowAuth] = useState(true)
  const [customRules, setCustomRules] = useState({})

  const basePath = 'user-op'
  const baseId = 'mod-user-op-form'

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await service.getCompany(params)
      res.data.map((d)=> {
        d.label = d.nama
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
    }
  }

  const getAuth = async()=>{
    var authoritieOptions= [
      {name: 'ROLE_EPPT_ADMIN', id: "ROLE_EPPT_ADMIN"},
      {name: 'ROLE_EPPT_USER', id: "ROLE_EPPT_USER"},
      {name: 'ROLE_EPPT_OWNER', id: "ROLE_EPPT_OWNER"}
    ]

    try{
      authoritieOptions.map((d)=>{
        d.label = d.name
        d.value = d
      })
      setauth(authoritieOptions)
      return authoritieOptions
    }catch(e){

    }
  }

  useEffect(() => {
    async function initData(){
      var user = JSON.parse(localStorage.getItem('user'))

      if(match.params.id == 'new') {
        try {
          setData({loading: false})

          if(user.authorities[0].name == "ROLE_EPPT_OWNER" || window.location.href.indexOf('open') != -1){
            setisShowAuth(false)
            // setisShow(false)
            setisShowOrg(false)
          }
        } catch(e){
          ErrorService(e)
        }
        
      } else {
        let res = JSON.parse(localStorage.getItem('userOp'))
        var organizations = await getOrganizationsOp()
        if(res.company !== null){
          organizations.map((d)=> {
            if(res.company.id == d.id) 
            res.company = d.nama
          })
        }
        
        try{
          res.authorities.map((d)=>{
            d.id = d.name
            d.label = d.name
          })
        }catch(e){}

        setData({loading: false, content: res})
        setisShowBanned(true)
        setisShow(false)

        if(user.authorities[0].name == "ROLE_EPPT_OWNER" || window.location.href.indexOf('open') != -1){
          setisShowAuth(false)
          setisShowOrg(false)
        }
      }
      getOrganizationsOp()
      getAuth()
    }
    initData()
  }, [])

  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }
  
  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {backTo}
      baseId={`${baseId}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'company',
          labelKey: 'label', 
          valueKey: 'label',
          data: organization,
          options : organization,
          show : isShowOrg,
          async : true,
          defaultOptions : organization,
          loadOptions : async(inputValues)=>{
            var filter = organization.filter((org)=> {
              return org.nama.indexOf(inputValues) != -1
            })
            return filter
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.username'),
          key: 'login',
          type: 'text',
          validation: 'required',
          show : isShow,
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.password`),
          key: 'password',
          type: 'password',
          validation: 'required',
          show : isShow,
          width : '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.email'),
          key: 'email',
          type: 'email',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.firstName'),
          key: 'firstName',
          type: 'text',
          width : '50%',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.lastName'),
          key: 'lastName',
          width : '50%',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate('words.language'),
          key: 'langKey',
          validation: 'required',
          options: [
            { label: 'Indonesia', value: 'id' },
            { label: 'English', value: 'en' },
          ]
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.active`),
          key: 'activated',
          type : 'text',
          width: '50%'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.adminView`),
          key: 'adminView',
          type : 'text',
          width: '50%'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.banned`),
          key: 'banned',
          type : 'text',
          width: '50%',
          show : isShowBanned
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.bannedReason'),
          key: 'bannedReason',
          type: 'text',
          show : isShowBanned
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate('words.authoroties'),
          key: 'authorities',
          show : isShowAuth,
          data : auth,
          options: auth,
          isMulti : true,
          async : true,
          defaultOptions : auth,
          loadOption : (value)=>{
            if(value.length % 2){
              let options = getAuth(value)
              return options
            } else {
              return auth
            }
          }
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var user = JSON.parse(localStorage.getItem('user'))
          var company = JSON.parse(localStorage.getItem('company'))
          
          if(!data.authorities){
            data.authorities = [{name : "ROLE_EPPT_USER", id : "ROLE_EPPT_USER"}]
          }

          if(data.authorities[0].label == ""){
            data.authorities.shift()
            console.log("cek")
          }
          
          if(!data.company){
            if(!user.company){
              data.company = company
            }else{
              data.company = user.company
            }
          }

          var formData = Object.assign({}, data)

          if(formData.company !== Object(data.company)){
            var company = await getOrganizationsOp()
            company.map((d)=> {
              if(formData.company == d.nama) 
              formData.company = d
            })
          }

          delete formData.company.value
          if(data.authorities.length > 0){
            data.authorities.map((d)=> {
              delete d.value
            })
          }
          if(match.params.id == 'new') {
            res = await service.createdOne(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`${backTo}`)     
        } catch(e){
          console.log(e)
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpUserForm))
