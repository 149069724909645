import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/ebilling-core/etaxs');

service.findAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling-core/etaxs`,
    query
  })
}

service.getBank = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling-core/banks?page=0`,
    query
  })
}

service.coreStatus = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/coreStatus`,
    query
  })
}

service.findByInvoice = async(query, id)=>{
  return http.request({
    method: http.methods.GET,
    url : `/api/ebilling-core/etaxs/byInvoice/${id}`,
    query
  })
}

service.cancel = async(query, id) => {
  return http.request({
    method : http.methods.PUT,
    url : `/api/ebilling-core/etaxs/cancel/${id}`,
    query
  })
}

service.cancelBulk = async(query) => {
  return http.request({
    method : http.methods.PUT,
    url : `/api/ebilling-core/etaxs/cancel/checked`,
    query
  })
}

service.deleteBulk = async(query) => {
  return http.request({
    method : http.methods.PUT,
    url : `/api/ebilling-core/etaxs/delete/checked`,
    query
  })
}

service.retry = async(data) => {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/retry`,
    data
  })
}

service.generate = async(data) => {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/reports`,
    data
  })
}

service.generatePo = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/essps`,
    data
  })
}

service.generatePoBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/essps`,
    data
  })
}

service.validateOrderAll = async(query)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/validate/all`,
    query
  })
}

service.validateOrder = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/validate`,
    data
  })
}

service.order = async (data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices`,
    data
  })
}

export default service;