import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import axios from 'axios'

let service = new CrudService('/api/log-import-csv/page?category.equals=USER')

service.findAll = async(query)=> {
  return http.request({
      method: http.methods.GET,
      url: `/api/log-import-csv/page?category.equals=USER`,
      query
  })
}

service.validation = async(query, uploadId)=> {
  return http.request({
      method: http.methods.GET,
      url: `/api/validation-csv/page/${uploadId}`,
      query
  })
}
  
service.editById = async(data)=>{
  return http.request({
    method: http.methods.PUT,
    url: `/api/log-import-csv`,
    data,
  })
}

service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/log-import-csv/delete`,
    data,
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/log-import-csv/delete/${id}`,
  })
}

service.importPengguna = async(data, id)=> {
  return axios({
    method: http.methods.POST,
    url: `/api/import/company/${id}`,
    data
  })
}

service.getCertStatus = async(npwp)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/cert/search?npwp=${npwp}`
  })
}

service.downloadTemplate = async() => {
  return http.request({
    method : http.methods.GET,
    url : `/api/log-import-csv/download-template/USER`
  })
}

export default service