import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService(`/api/setting/eunifikasi-core/company`);

service.editById = async(id, data)=>{
	return http.request({
    method: http.methods.PUT,
    url: `/api/setting/eunifikasi-core`,
    data,
	})
}

export default service; 