import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/setting/emailEunifikasiSchedule/page');

service.editById = async(id, data)=>{
	return http.request({
    method: http.methods.PUT,
    url: `/api/setting/emailEunifikasiSchedule`,
    data,
	})
}

service.findAll = async(query, companyId)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/setting/emailEunifikasiSchedule/company/${companyId}`,
    query
  })
}

service.findById = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/setting/emailEunifikasiScheduleDetails/emailEunifikasiSchedule/${id}`
  })
}

export default service;