import React, { useState, useEffect } from 'react'
import t from 'counterpart'
import './DataFilter.scss'
import Label from '../Label'
import { Button, TextField, FontIcon, Select } from 'react-md'
import { defaultQueryParameter, strCriterias } from '../../config/constant'
import CustomTextField from '../CustomTextField'
import CustomInput from '../CustomInput'
import moment from 'moment'


const DataFilter = ({
  baseId          = 'mpk-data-filter',
  columns         = [],
  defaultQuery    = {},
  onSubmit        = () => console.log(`[react-mpk][component][data-filter] The onSubmit props has not been defined yet`),
  showPeriod      = true,
  useCriteria     = true,
  children        = null
}) => {
  const [query, setQuery] = useState(defaultQueryParameter(defaultQuery))

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    onSubmit(query)
  }

  const handleChange = (key, value) => {
    if(key === 'endDate' && moment(value).isBefore(moment(query.startDate))) value = query.startDate
    if(key === 'startDate' && moment(value).isAfter(moment(query.endDate))) value = query.endDate

    let obj = {}; obj[key] = value
    setQuery(q => ({...q, ...obj}))
  }

  useEffect(() => {
    setQuery(q => ({...q, ...defaultQuery}))
  }, [defaultQuery])

  return (
    <form 
      className="mpk-data-filter mpk-paper mpk-padding-N padding-all"
      onSubmit={handleSubmit}
    >
      <Label>{t.translate('mpk.sentence.dataFilter')}</Label>
      <Select
        id={`${baseId}-column-options`}
        label={t.translate('mpk.column.searchColumn')}
        className="mpk-margin-N margin-bottom"
        value={query.column}
        onChange={ value => handleChange('column', value)}
        options={columns}
      />
      {useCriteria && (
        <Select
          id={`${baseId}-column-criteria`}
          label={t.translate('mpk.column.criteria')}
          className="mpk-margin-N margin-bottom"
          value={query.columnCriteria}
          onChange={value => handleChange('columnCriteria', value)}
          options={strCriterias.map(d => ({
            label: t.translate(`mpk.column.${d}`),
            value: d
          }))}
        />
      )}
      <CustomInput
        id={`${baseId}-keyword`}
        label={t.translate('mpk.column.keyword')}
        className="mpk-margin-N margin-bottom"
        placeholder={t.translate('mpk.sentence.inputKeyword')}
        value={query.keyword}
        onChange={(e) => handleChange('keyword', e.target.value)}
        rightChildren={
          <FontIcon iconClassName="mdi mdi-magnify"/>
        }
        helpText={useCriteria && query.columnCriteria === 'in' && t.translate('mpk.sentence.criteriaInHelp')}
        dense
      />
      {showPeriod && (
        <>
          <Label>{t.translate('mpk.column.period')}</Label>{}
          <TextField
            id={`${baseId}-start-date`}
            className="mpk-margin-N margin-bottom"
            label={t.translate('mpk.column.startDate')}
            onChange={(e) => handleChange('startDate', e.target.value)}
            value={query.startDate}
            type="date"
          />
          <TextField
            id={`${baseId}-end-date`}
            className="mpk-margin-N margin-bottom"
            label={t.translate('mpk.column.endDate')}
            onChange={(e) => handleChange('endDate', e.target.value)}
            value={query.endDate}
            type="date"
          />
        </>
      )}
      <div className="actions">
        <Button
          theme="primary"
          themeType="outline"
          type="submit"
        >
          {t.translate('mpk.column.submit')}
        </Button>
      </div>
      {children && (
        <div className="mpk-margin-N margin-bottom">
          {children}
        </div>
      )}
    </form>
  )
}

export default DataFilter
