import React from 'react';
import './Product.scss';

import developerPM_L from '../../assets/product/developer-picturemark-l.svg';
import developerLT_L from '../../assets/product/developer-logotype-l.svg';
import developerPM_D from '../../assets/product/developer-picturemark-d.svg';
import developerLT_D from '../../assets/product/developer-logotype-d.svg';
import efilingPM_L from '../../assets/product/efiling-picturemark-l.svg';
import efilingLT_L from '../../assets/product/efiling-logotype-l.svg';
import efilingPM_D from '../../assets/product/efiling-picturemark-d.svg';
import efilingLT_D from '../../assets/product/efiling-logotype-d.svg';
import epptPM_L from '../../assets/product/eppt-picturemark-l.svg';
import epptLT_L from '../../assets/product/eppt-logotype-l.svg';
import epptPM_D from '../../assets/product/eppt-picturemark-d.svg';
import epptLT_D from '../../assets/product/eppt-logotype-d.svg';
import halonaPM_L from '../../assets/product/halona-picturemark-l.svg';
import halonaLT_L from '../../assets/product/halona-logotype-l.svg';
import halonaPM_D from '../../assets/product/halona-picturemark-d.svg';
import halonaLT_D from '../../assets/product/halona-logotype-d.svg';
import ebillingPM_L from '../../assets/product/ebilling-picturemark-l.svg';
import ebillingLT_L from '../../assets/product/ebilling-logotype-l.svg';
import ebillingPM_D from '../../assets/product/ebilling-picturemark-d.svg';
import ebillingLT_D from '../../assets/product/ebilling-logotype-d.svg';
import h2hPM_L from '../../assets/product/h2h-picturemark-l.svg';
import h2hLT_L from '../../assets/product/h2h-logotype-l.svg';
import h2hPM_D from '../../assets/product/h2h-picturemark-d.svg';
import h2hLT_D from '../../assets/product/h2h-logotype-d.svg';
import tarraPM_L from '../../assets/product/tarra-picturemark-l.svg';
import tarraLT_L from '../../assets/product/tarra-logotype-l.svg';
import tarraPM_D from '../../assets/product/tarra-picturemark-d.svg';
import tarraLT_D from '../../assets/product/tarra-logotype-d.svg';
import tarraH2hPM_L from '../../assets/product/tarra-h2h-picturemark-l.svg';
import tarraH2hLT_L from '../../assets/product/tarra-h2h-logotype-l.svg';
import tarraH2hPM_D from '../../assets/product/tarra-h2h-picturemark-d.svg';
import tarraH2hLT_D from '../../assets/product/tarra-h2h-logotype-d.svg';
import ebupotPM_L from '../../assets/product/ebupot-picturemark-l.svg';
import ebupotLT_L from '../../assets/product/ebupot-logotype-l.svg';
import ebupotPM_D from '../../assets/product/ebupot-picturemark-d.svg';
import ebupotLT_D from '../../assets/product/ebupot-logotype-d.svg';
import payjakPM_L from '../../assets/product/payjak-picturemark-l.svg';
import payjakLT_L from '../../assets/product/payjak-logotype-l.svg';
import payjakPM_D from '../../assets/product/payjak-picturemark-d.svg';
import payjakLT_D from '../../assets/product/payjak-logotype-d.svg';
import mpnPM_L from '../../assets/product/mpn-picturemark-l.svg';
import mpnLT_L from '../../assets/product/mpn-logotype-l.svg';
import mpnPM_D from '../../assets/product/mpn-picturemark-d.svg';
import mpnLT_D from '../../assets/product/mpn-logotype-d.svg';
import sipPM_L from '../../assets/product/sip-picturemark-l.svg';
import sipLT_L from '../../assets/product/sip-logotype-l.svg';
import sipPM_D from '../../assets/product/sip-picturemark-d.svg';
import sipLT_D from '../../assets/product/sip-logotype-d.svg';
import unifikasiPM_L from '../../assets/product/unifikasi-picturemark-l.svg';
import unifikasiLT_L from '../../assets/product/unifikasi-logotype-l-nebula.svg';
import unifikasiPM_D from '../../assets/product/unifikasi-picturemark-d-nebula.svg';
import unifikasiLT_D from '../../assets/product/unifikasi-logotype-d-nebula.svg';
import opkuPM_L from '../../assets/product/opku-picturemark-l.svg';
import opkuLT_L from '../../assets/product/opku-logotype-l.svg';
import opkuPM_D from '../../assets/product/opku-picturemark-d.svg';
import opkuLT_D from '../../assets/product/opku-logotype-d.svg';
import { constant } from 'lodash';
// import mpnPM_L from '../../assets/product/mpn-logo.png';
// import mpnLT_L from '../../assets/product/mpn-logo.png';
// import mpnPM_D from '../../assets/product/mpn-logo.png';
// import mpnLT_D from '../../assets/product/mpn-logo.png';


let appLogo = {
  developer:{
    inverse:[developerPM_L, developerLT_L],
    default:[developerPM_D, developerLT_D]
  },
  efiling:{
    inverse:[efilingPM_L, efilingLT_L],
    default:[efilingPM_D, efilingLT_D]
  },
  eppt:{
    inverse:[epptPM_L, epptLT_L],
    default:[epptPM_D, epptLT_D]
  },
  halona:{
    inverse:[halonaPM_L, halonaLT_L],
    default:[halonaPM_D, halonaLT_D]
  },
  ebilling:{
    inverse:[ebillingPM_L, ebillingLT_L],
    default:[ebillingPM_D, ebillingLT_D]
  },
  h2h:{
    inverse:[h2hPM_L, h2hLT_L],
    default:[h2hPM_D, h2hLT_D]
  },
  tarra:{
    inverse:[tarraPM_L, tarraLT_L],
    default:[tarraPM_D, tarraLT_D]
  },
  tarraH2h:{
    inverse:[tarraH2hPM_L, tarraH2hLT_L],
    default:[tarraH2hPM_D, tarraH2hLT_D]
  },
  ebupot:{
    inverse:[ebupotPM_L, ebupotLT_L],
    default:[ebupotPM_D, ebupotLT_D]
  },
  payjak:{
    inverse:[payjakPM_L, payjakLT_L],
    default:[payjakPM_D, payjakLT_D]
  },
  mpn:{
    inverse:[mpnPM_L, mpnLT_L],
    default:[mpnPM_D, mpnLT_D]
  },
  sip:{
    inverse:[sipPM_L, sipLT_L],
    default:[sipPM_D, sipLT_D]
  },
  unifikasi:{
    inverse:[unifikasiPM_L, unifikasiLT_L],
    default:[unifikasiPM_D, unifikasiLT_D]
  },
  opku:{
    inverse:[opkuPM_L, opkuLT_L],
    default:[opkuPM_D, opkuLT_D]
  },
};

const Product = ({
  appname     = null, 
  inverse     = false, 
  style       = {}, 
  subname     = null, 
  className   = '', 
  typeOnly    = false
}) => {
  const getSource = (idx) => {
    return appLogo[appname][inverse ? 'inverse' : 'default'][idx]
  };

  return typeOnly ? (
    <img
      style={style}
      src={getSource(1)}
      alt="logo"
    />
  ) : (
    <div 
      className={`mpk-product-logo mpk-flex align-center ${inverse ? 'inverse' : ''} ${className}`}
      style={style}
    >
      <img
        className="picture-mark"
        src={getSource(0)}
        alt="logo"
        />
      <div className="mpk-flex align-center">
        <img
          className="logo-type"
          src={getSource(1)}
          alt="logo"
          />
          { subname ? (
            <div className="mpk-margin-N left mpk-font size-NS" style={{opacity:.64}}>{subname}</div>
          ) : (null)}
      </div>
    </div>
  )
}

Product.names = {
  DEVELOPER   : 'developer',
  EFILING     : 'efiling',
  EPPT        : 'eppt',
  HALONA      : 'halona',
  EBILING     : 'ebilling',
  H2H         : 'h2h',
  TARRA       : 'tarra',
  TARRA_H2H   : 'tarraH2h',
  EBUPOT      : 'ebupot',
  PAYJAK      : 'payjak',
  MPN         : 'mpn',
  SIP         : 'sip',
  UNIFIKASI   : 'unifikasi',
  OPKU        : 'opku'
}

export default Product;