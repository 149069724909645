import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Bp26.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Divider, Button, FontIcon } from 'react-md'
import Modal from '../../libs/react-mpk/components/Modal'
import LawanTrasaksiTable from './../LawanTransaksi/LawanTransaksi.table'
import ErrorService from './../../services/errorService'

const Bp261Form = ({ authStore, envStore, temporaryStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [customRules, setCustomRules] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [negara, setNegara] = useState([])
  const [isHidden, setIsHidden] = useState(false)

  const baseName = `bp`
  const basePath = `bp`

  useEffect(() => {
    async function initData(){
      await getNegara()
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      setData({loading: false, content: {
        ...bpform,
      }})
    }
    initData()
    if(match.params.id != 'new'){
      setIsHidden(true)
    }
  }, [])

  async function getNegara(query){
    try {
      var negara = await service.getNegara(query)
      setNegara(negara.data)
    } catch(e){
      ErrorService(e)
    }
  }

  function _closeDialog() {
    setShowModal(false)
  }

  var onSubmit = async (data, callback) => {
    data.form1valid = true
    // filterCountry 
    negara.map((d)=> {
      if(d.name == data.countryName) data.country = d.alias
    })
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    bpform = Object.assign(bpform, data)
    localStorage.setItem('bpform', JSON.stringify(bpform))
    props.activePanel()
  }
  try {
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(bpform.status == 'CANCELED' || bpform.status == 'DELETED' || spt.status == 'SUBMIT' || spt.status == 'FINSIH'){ 
      onSubmit = false
    }
  } catch(e){}

  return (
    <>
      <Modal
        disableFocusOnMount={true}
        id="lt-form"
        aria-label="lt-form"
        visible={showModal}
        onRequestClose={_closeDialog}
        style={{
          width: 1024, 
          height: 600
        }}
      >
        <LawanTrasaksiTable match={match} onSearch={true} setShowModal={setShowModal} setData={setData} data={data} />
      </Modal>
      <Button theme="primary" themeType="contained" style={{ margin: 15, width: '25%' }} onClick={()=> setShowModal(true)} hidden={isHidden}><FontIcon iconClassName="mdi mdi-magnify" />
        {t.translate('words.cariLT')}
      </Button>
      <FormWrapper
        submitLabel={t.translate('words.next')}
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        onChange={(formData, key, value)=> {}}
        additionalAction={[]}
        customRules={customRules}
        definitions={[
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.identity`),
            key: 'identity',
            type: 'text',
            width: '50%',
            validation: 'required',
          },
          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.dob`),
            key: 'dob',
            type: 'date',
            width: '50%',
            // validation: 'required',
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.name`),
            key: 'name',
            type: 'text',
            validation: 'required',
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.email`),
            key: 'email',
            type: 'text',
            width: '50%',
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.phone`),
            key: 'phone',
            type: 'text',
            width: '50%'
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.address`),
            key: 'address',
            type: 'text',
            validation: 'required',
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.country`),
            key: 'countryName',
            validation: 'required',
            colSpan: 3,
            data: negara,
            labelKey: 'name', 
            valueKey: 'name'
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.passportNo`),
            key: 'passportNo',
            type: 'text',
            width: '100%',
            // validation: 'required',
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.kitasNo`),
            key: 'kitasNo',
            type: 'text',
            // validation: 'required',
          },
        ]}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(Bp261Form))
