import React, {useEffect, useState} from 'react'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { Dialog, List, ListItem, DialogContent, DialogFooter, Divider, TableRow, Table, TableBody, TableCell, Button, TableHeader } from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import service from './OpImporteSsp.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import errorServiceGet from './../../services/errorServiceGet'

const OpImporteSspForm = ({ 
  match, 
  navigationStore, 
  modalStore, 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [message, setMessage] = useState([])
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  
  const basePath = 'importEssp'
  const baseId = 'mod-importEssp-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          ErrorService(e)
        }
      } else {
        let file = JSON.parse(localStorage.getItem('inquiry'))
        const params = {
          endDate: "",
          page: 0,
          size: 20,
          sort: "line,DESC",
          startDate: "",
          fileId:file.fileId
        }
        const res = await service.validation(params)
        res.data[0].fileId = res.data[0].ebillingLogImport.fileId
        res.data[0].status = res.data[0].ebillingLogImport.status
        res.data[0].step = res.data[0].ebillingLogImport.step
        res.data[0].count = res.data[0].ebillingLogImport.count
        setData({loading: false, content: res.data[0]})
        setMessage(res.data[0].messages)
      }
    }
    initData()
  }, [])

  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }
  
  return (
    <>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{
        maxWidth: '100%'
      }}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        // {
        //   inputType: inputTypes.INPUT,
        //   label: t.translate('words.id'),
        //   key: 'fileId',
        //   type: 'text',
        //   width : '50%',
        //   disabled: true,
        // },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: t.translate('words.status'),
        //   key: 'status',
        //   type: 'text',
        //   width : '50%',
        //   disabled: true,
        // },
        {
          render: (
            <Table style={{ width: '100%' }}>
              <TableRow>
                <TableCell><b>{'File ID : '+data.content.fileId}</b></TableCell>
                <TableCell colSpan={3}><b>{'Status : '+data.content.status}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>{'Step '+data.content.step+'/2 - '+ data.content.count +'/1'}</b></TableCell>
                <TableCell colSpan={3}><b>{data.content.count+'%'}</b></TableCell>
              </TableRow>
            </Table>
          )
        },
        {
          render: (
            <div style={{ width: '100%' }}>
              <Table style={{ width: '100%' }}>
                <TableHeader>
                  <TableRow>
                    <TableCell>{t.translate('words.line')}</TableCell>
                    <TableCell>{t.translate('words.message')}</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {message.map((d, index)=> {
                    return (  
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{d.message}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          )
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          navigationStore.redirectTo(`${backTo}`)  
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore', 'modalStore')(observer(OpImporteSspForm))
