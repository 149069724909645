import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services'
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/spt/bpe'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/spt/bpe');
  serviceUrl = '/api/eunifikasi/spt/bpe'
} else {
  service = new CrudService('/spt/bpe');
}

service.getReport = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/${id}`,
  })
}

service.postReport = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/report`,
    data
  })
}


service.getRequestReport = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/request/${id}`
  })
}

service.getOrganizationOp = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/eunifikasi/organization-users/organization`,
    query
  })
}

export default service;