import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './SspArchive.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import _ from 'lodash'
import ErrorService from '../../services/errorService'
import AppMode from '../../AppMode'

const SspArchiveForm = ({ authStore, envStore, match, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [kapCode, setKapCode] = useState([])
  const [kap, setKap] = useState([])
  const [kjs, setKjs] = useState([])
  const [showKjs, setShowKjs] = useState(true)
  const [disabledKapKJS, setDisabledKapKJS] = useState(false)
  const [key, setKey] = useState('total') 
  const [disabledNpwpSsp, setDisabledNpwpSsp] = useState(true) 

  const baseName = `ssp`
  const basePath = `ssp`

  useEffect(() => {
    async function initData(){
      let taxCodeGlobal = []
      try {
        let taxCodes = await service.getTaxObjectCode();
        let taxCode  = _.groupBy(taxCodes.data, 'kap')
        taxCodeGlobal = taxCode;
        let taxCodeList = Object.keys(taxCode)
        let kapSelect = []
        taxCodeList.map((d)=> {
          kapSelect.push({ label: d, value: d })
        })
        setKap(kapSelect)
        setKapCode(taxCode)
      } catch(e){}
      if(match.params.id == 'new' || match.params.id == undefined) {
        let essp = JSON.parse(localStorage.getItem('essp'))
        if(essp != null) {
          try {
            let spt = JSON.parse(localStorage.getItem('spt'))
            essp.npwpSsp = spt.npwp
          } catch(e){}
          setData({loading: false, content : essp})
          if(match.params.id == undefined){ 
            setDisabledKapKJS(true)
          } else {
            setDisabledKapKJS(false)
          }
          setKey('debt')
        } else {
          try {
            let spt = JSON.parse(localStorage.getItem('spt'))
            setData({loading: false, content: {
              type: 'true',
              sptNpwp: spt.npwp,
              sptYear: spt.year,
              sptMonth: spt.month,
              sptRev: spt.rev,
              npwpSsp: spt.npwp,
            }})
          } catch(e){
            ErrorService(e)
          }
        } 
      } else {
        setDisabledKapKJS(true)
        const res = await service.getOne(match.params.id)
        res.data.type = res.data.type + ""
        try {
          setShowKjs(false)
          var kjs = taxCodeGlobal[res.data.kap]
          kjs.map((d)=> {
            d.label = d.kjs + " " + d.description
          })
          setShowKjs(true)
          setKjs(kjs)
        } catch(e){}
        setData({loading: false, content:res.data})
      }
    }
    initData()
  }, [])

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}

  var backTo = `/product/company/${match.params.companyId}/daftar-spt/open/${match.params.sptId}/${basePath}`
  if(AppMode.onPremise) backTo = `/onpremise/daftar-spt-archive/open/${match.params.sptId}/${basePath}`
  
  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{
        maxWidth: '80%'
      }}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.title`)}
      // hintMessage={t.translate(`modules.${baseName}.formTitle`)}
      onChange={(formData, key, value)=> {
        if(key == "no"){
          if(value.length > 16){
            formData["no"] = value.slice(0,16)
          }
        }
        if(key == "kap"){
          try {
            setShowKjs(false)
            // var kjs = kapCode[value]
            // kjs.map((d)=> {
            //   d.label = d.kjs + " - " + d.description
            // })
            var kjs;
            if(value == '411122'){
              kjs = [
                { label: '100', value: '100'},
                { label: '401', value: '401'},
                { label: '403', value: '403'}
              ]
            } else if(value == '411124'){
              kjs = [
                { label: '100', value: '100'},
                { label: '101', value: '101'},
                { label: '102', value: '102'},
                { label: '103', value: '103'},
                { label: '104', value: '104'}
              ]
            } else if(value == '411127'){
              kjs = [
                { label: '100', value: '100'},
                { label: '101', value: '101'},
                { label: '102', value: '102'},
                { label: '103', value: '103'},
                { label: '104', value: '104'},
                { label: '105', value: '105'}
              ]
            } else if(value == '411128'){
              kjs = [
                { label: '402', value: '402'},
                { label: '403', value: '403'},
                { label: '404', value: '404'},
                { label: '409', value: '409'},
                { label: '410', value: '410'},
                { label: '411', value: '411'},
                { label: '413', value: '413'},
                { label: '423', value: '423'},
                { label: '499', value: '499'}
              ]
            } else if(value == '411129'){
              kjs = [
                { label: '101', value: '101'}
              ]
            } else {
              return []
            }
            setKjs(kjs)
            setTimeout(()=> {
              setShowKjs(true)
            }, 200)
          } catch(e){}
        }
        if(key == "kjs"){
          var code = value.split(" - ")[0]
          if(code == "423"){
            setDisabledNpwpSsp(false)
            formData.sptNpwp = null
          } else {
            setDisabledNpwpSsp(true)
            try {
              var spt = JSON.parse(localStorage.getItem('spt'))
              formData.sptNpwp = spt.npwp
            } catch(e){}
          }
        }
      }}
      defaultData={data.content}
      definitions={[
        // {
        //   render: (
        //     <div style={{ width: '100%' }}>
        //       <p>{t.translate(`words.jenisBuktiPenyetoran`)}</p>
        //     </div>
        //   )
        // },
        
        // {
        //   inputType: inputTypes.RADIO,
        //   label: t.translate(`words.suratSetoranPajak`),
        //   key: 'type',
        //   type: 'boolean',
        //   name: 'type',
        //   value: 'true'
        // },
        // {
        //   inputType: inputTypes.RADIO,
        //   label: t.translate(`words.pemindahbukuan`),
        //   key: 'type',
        //   type: 'boolean',
        //   name: 'type',
        //   value: 'false'
        // },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.nomorBuktiPenyetoran`),
          key: 'no',
          type: 'text',
          validation: 'required|min:16|max:16',
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.kodeAkunPajak`),
          key: 'kap',
          validation: 'required',
          labelKey: 'label', 
          valueKey: 'value',
          data: kap,
          disabled: disabledKapKJS
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.kodeJenisSetoran`),
          key: 'kjs',
          validation: 'required',
          labelKey: 'label', 
          valueKey: 'label',
          data: kjs,
          show: showKjs,
          disabled: disabledKapKJS
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          thousandSeparator: thousandSeparator,
          decimalSeparator: decimalSeparator,
          isNumericString: true,
          label: t.translate(`words.total`),
          key: key,
          type: 'text',
          validation: 'required',
          disabled: disabledKapKJS
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: t.translate(`words.tanggal`),
          key: 'date',
          type: 'date',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.npwpSsp`),
          key: 'npwpSsp',
          type: 'text',
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          disabled: disabledNpwpSsp
        },
      ]}
      // onSubmit={async (data, callback) => {
      //   var res = {}
      //   var formData = Object.assign({}, data)
      //   if(formData.type == 'true'){
      //     formData.type = true
      //   } else {
      //     formData.type = false
      //   }
      //   // var getKjs = kjs.map((d)=> {
      //   //   if(d.label == formData.kjs){
      //   //     formData.kjs = d.kjs
      //   //   }
      //   // })
      //   formData.type = true
      //   try {
      //     if(match.params.id == 'new') {
      //       res = await service.post(formData)
      //     } else {
      //       res = await service.put(formData.id, formData)
      //     }
      //     callback(t.translate('sentences.tersimpan'), false)
      //     navigationStore.redirectTo(backTo)     
      //   } catch(e){
      //     ErrorService(e)
      //     callback("", false, false)
      //     return
      //   } 
      // }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(SspArchiveForm))
