import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/ebilling/inquiry');

service.findOne = async(query, id)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/inquiry/${id}`,
    query
  })
}

service.checkNpwp = async(npwp)=>{
  return http.request({
    method: http.methods.GET,
    url : `/api/check-npwp?npwp=${npwp}`
  })
}

service.coreStatus = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/coreStatus`,
    query
  })
}

service.getPage = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/inquiry/page`,
    query
  })
}

service.deleteBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling/inquiry/delete`,
    data
  })
}

service.print = async(data, id)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling/inquiry/report/${id}`
  })
}

service.printBulk = async(data, companyId)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling/inquiry/report/${companyId}`,
    data
  })
}

service.requestInquiry = async(data) => {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/inquiry`,
    data
  })
}

service.refreshInquiry = async(data) => {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/inquiry/refresh`,
    data
  })
}

service.checkInquiry = async(data) => {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/inquiry/check`,
    data
  })
}

service.csvBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url :  `/api/ebilling/inquiry/csv-checked`,
    data
  })
}

service.csvFilter = async(data)=> {
  return http.request({
    method : http.methods.GET,
    url :  `/api/ebilling/inquiry/csv-filter`,
    data
  })
}

export default service;