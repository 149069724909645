import React, {useState, useEffect} from 'react'
import { toast } from '../../services'
import LoaderInfo from '../LoaderInfo'
import t from 'counterpart'
import { eventNames } from '../../config/constant'
import Summary from './Summary'

const Summaries = ({
  baseId        = 'mpk-data-view-summaries',
  className     = '',
  eventName     = null,
  onFetchData   = null,
  onInitData    = () => (new Promise(resolve => resolve([])))
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const handleFetchData = async (query={}) => {
    if(onFetchData){
      setLoading(true)
      try {
        let res = await onFetchData(query)
        let data = onInitData ? await onInitData(res.data) : res.data 
        setData(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.errorRequest(error)
      }
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [onFetchData])

  useEffect(() => {
    if(eventName) window.addEventListener(eventName, e => {
      const { eventName, eventData } = e.detail
      switch(eventName){
        case eventNames.RELOAD:
          handleFetchData(eventData);
          break;
        default:
          break;
      }
    })

    return function cleanup(){
      if(eventName) window.removeEventListener(eventName, () => {}, false)
    }
  }, [eventName])

  return (
    <div className={`mpk-data-view-summaries mpk-full full-width mpk-flex align-center wrap ${className}`}>
      {loading ? 
        <div className="mpk-paper mpk-full full-width">
          <LoaderInfo>{t.translate('mpk.sentence.loadingData')}</LoaderInfo> 
        </div>
      : (
        data.map((d, i) => (
          <Summary 
            key={`${baseId}-summary-${i}`}
            className="flex mpk-margin-S margin-right"
            data={d}
          />
        ))
      )}
    </div>
  )
}

export default Summaries
