import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/induk'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/induk');
  serviceUrl = '/api/eunifikasi/bp/induk'
} else {
  service = new CrudService('/bp/induk');
}

service.report = async(query)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/report`,
      query
    })
}

export default service;