import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './EmailConfig.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import AppMode from '../../AppMode'

const DaftarSptForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {
    useProxy: false
  }})
  const [useProxy, setUseProxy] = useState(false)

  const baseName = `emailConfig`
  const basePath = `emailConfig`

  useEffect(() => {
    async function initData(){
      try {
        let res = await service.getByCompany(match.params.companyId)
        if(!res.data.port) res.data.port = 0
        if(!res.data.proxyPort) res.data.proxyPort = 0
        res.data.port = res.data.port + ""
        res.data.proxyPort = res.data.proxyPort + ""
        setUseProxy(res.data.useProxy)
        setData({ loading: false, content: res.data })
      } catch(e){
        setData({ loading: false, content: {} })
        ErrorService(e)
      }
    }
    initData()
  }, [])

  var backTo = `/product/company/${match.params.companyId}/${basePath}`
  if(AppMode.onPremise){
    backTo = `/onpremise/${basePath}`
  }

  return (
    <FormWrapper
      style={{
        maxWidth: '100%'
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.formTitle`)}
      defaultData={data.content}
      onChange={(formData, key, value)=> {
        if(key == "useProxy"){
          setUseProxy(value)
        }
      }}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.host`),
          key: 'host',
          width: '50%',
          type: 'text',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.username`),
          key: 'username',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.port`),
          key: 'port',
          width: '50%',
          type: 'text',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.password`),
          key: 'password',
          width: '50%',
          type: 'password',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.email`),
          key: 'from',
          width: '50%',
          type: 'text',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.protocol`),
          key: 'protocol',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.auth`),
          key: 'auth',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.tls`),
          key: 'tls',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.sendMailPassword`),
          key: 'sendMailPassword',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.useProxy`),
          key: 'useProxy',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyHost`),
          key: 'proxyHost',
          width: '50%',
          type: 'text',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyUsername`),
          key: 'proxyUsername',
          width: '50%',
          type: 'text',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPort`),
          key: 'proxyPort',
          width: '50%',
          type: 'text',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPassword`),
          key: 'proxyPassword',
          width: '50%',
          type: 'password',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyType`),
          key: 'proxyType',
          width: '50%',
          type: 'text',
          show: useProxy
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var formData = Object.assign({}, data)
          formData.port = parseInt(formData.port)
          formData.proxyPort = parseInt(formData.proxyPort)
          var res = await service.updateEmail(formData)
          callback(t.translate('sentences.tersimpan'), false, false, false)   
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DaftarSptForm))
