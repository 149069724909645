class chart{
  constructor(
    label         = 'string', 
    onFetchData   = null, 
    onInitData    = null,
    dataConfig    = {},
    chartConfig   = {}
  ){
    this.label = label 
    this.onFetchData = onFetchData
    this.onInitData = onInitData
    this.dataConfig = dataConfig
    this.chartConfig = chartConfig
  }
}

export default chart