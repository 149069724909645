import izitoast from 'izitoast'
import moment from 'moment'

var errorServiceGet = async function (e, di, d2){
    var message = e.message
    try {
        if(!di.id) di = {id: ""}
        if(!e.response.data.code) e.response.data.code = ""
    } catch(e){}
    if(e.response && e.response.data) message = `${e.response.data}`
    if(e.response && e.response.data && e.response.data.message) message = e.response.data.message
    if(e.response && e.response.data && e.response.data.detail) message = `${e.response.data.code} ${e.response.data.detail}`
    window.hahaha = e
    var pesan = "Pesan Error !, "
    if(d2){
        pesan += `Baris: ${d2 + 1} `
    }
    if(di.id && di.id != ""){
        pesan += `ID: ${di.id}, `
    }
    if(message){
        pesan += `${message}`
    }
    return pesan
}

export default errorServiceGet;