import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import axios from 'axios';

let service = new CrudService('/api/ebilling/log-export-essp');

service.deleteById = async(id)=> {
  return http.request({
    method : http.methods.DELETE,
    url : `/api/ebilling/log-export-essp/delete/${id}`
  })
}

service.deleteBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling/log-export-essp/delete`,
    data
  })
}

service.download = async(id) => {
 return axios.get(`/api/ebilling/log-export-essp/download/${id}`, {
  method: http.methods.GET,
  responseType: 'arraybuffer'
})
}

export default service;