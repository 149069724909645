import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpInquiry.service'
import _ from 'lodash'
import ErrorService from '../../services/errorService'
import iziToast from 'izitoast'

const OpInquiryForm = ({ match, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [isShowBanned, setIsShowBanned] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  
  const basePath = 'inquiry'
  const baseId = 'mod-inquiry-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
          setIsShow(true)
        } catch(e){
          ErrorService(e)
        }
      } else {
        const res = await service.findOne(null, match.params.id)
        const npwp = await service.checkNpwp(res.data.npwp)
        if(npwp.valid){
            res.data.npwpNotValid =  false
        } else {
            res.data.npwpNotValid =  true

            iziToast.info({
              message:"NWPW tidak Valid"
            })
        }
        setData({loading: false, content: res.data})
        setIsShowBanned(true)
      }
    }
    initData()
  }, [])
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate('words.npwp'),
          key: 'npwp',
          type: 'text',
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          validation : ['required', 'min:15']
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.nama'),
          key: 'name',
          type: 'text',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.city'),
          key: 'city',
          type: 'text',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.address'),
          key: 'address',
          type: 'text',
          disabled: true,
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          formData.authorities = [{name: "ROLE_EPPT_USER", id: "ROLE_EPPT_USER"}]
          var user = JSON.parse(localStorage.getItem('user'))
          var company = JSON.parse(localStorage.getItem('company'))

          if(window.location.href.indexOf('open') != -1){
            formData.company = company
            formData.authorities = [{name: "ROLE_EPPT_OWNER", id: "ROLE_EPPT_OWNER"}]
          } else {
            formData.company = user.company
          }

          if(match.params.id == 'new') {
            res = await service.createdOne(formData)
          } else {
            res = await service.put(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false)
          navigationStore.redirectTo(`${backTo}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpInquiryForm))
