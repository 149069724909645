import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpPaymentNotification.service'
import { Chip, ListItem, List, Button, TableRow, TableCell, Divider, Table, Caption} from 'react-md'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from '../../services/errorService'
import fileSaver from 'file-saver';
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import servicePo from './../OpPaymentOrder/OpPaymentOrder.service'
import { autorun, toJS } from 'mobx';
import { Container, Row, Col } from 'react-grid-system';
import { format } from '../../libs/react-mpk/services/number.service'

const OpPaymentNotificationDetails = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  temporaryStore,
  match
}) => {
  const [data, setData] = useState({loading : false, content : {}})
  const [getStatus, setStatus] = useState({})
  const [invoice, setInvoice] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})

  const baseId = 'mod-op-payment-notification-details'
  const [basePath, setBasePath] = useState('paymentNotification')

  useEffect(async () => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    try {
      var invoice = toJS(temporaryStore).properties.payment_notification
      invoice.name = invoice.paymentAccount.name
      invoice.orderType = invoice.paymentAccount.orderType.code
      setInvoice(invoice)
    } catch(e){}
  }, []) 

  async function initData() {
    try {
      const status = await service.coreStatus()
      setStatus(status.data)
    } catch{

    }
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function orderBulkExec(item) {
    try {
      var proses = t.translate('words.confirmOrder')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = [1]
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            var invoice = toJS(temporaryStore).properties.payment_notification
            let proccedThis = await service.orderBulk(invoice.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.success')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function orderBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.confirmOrder'),
      children : t.translate('words.confirmOrders'),
      onSubmit : (callback) => {
        orderBulkExec(item)
        callback()
      }
    })
  }

  async function confirmBulkExc(item) {
    try {
      var proses = t.translate('words.confirmOrder')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.cancelBulk(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneConfirmOrder')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function confirmBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.confirmOrder'),
      children : t.translate('mpk.confirm.message'),
      onSubmit : (callback) => {
        confirmBulkExc(item)
        callback()
      }
    })
  }

  async function cancelExc(item) {
    try {
      let res = await service.cancel(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function cancel(item) {
    modalStore.showConfirm({
      title : t.translate('words.cancel'),
      children : t.translate('mpk.confirm.message'),
      onSubmit : (callback) => {
        cancelExc(item)
        callback()
      }
    })
  }

  var actions = [
    new TableWrapper.action(`${t.translate('words.back')}`, 'mdi mdi-arrow-left', () => { history.push(`/onpremise/paymentNotification`) }, true),
    new TableWrapper.action(`${t.translate('words.cancel')}`, 'mdi mdi-cancel', () => {cancel()}, true),
    new TableWrapper.action(`${t.translate('words.confirmOrder')}`, 'mdi mdi-check', () => orderBulk(), true),
    new TableWrapper.action(`${t.translate('words.detailTransaksi')}`, 'mdi mdi-subtitles', () => { history.push(`/onpremise/fileTransaksi/${match.params.id}`) }, true),
  ]
  console.group(invoice)
  return (
    <>
    <TableWrapper
      tableHeader={(
        <div style={{ width: '100%', paddingBottom: '14px' }}>
          <TableRow>
            <TableCell><strong>{t.translate('words.invoice')}</strong></TableCell>
            <TableCell>{t.translate('words.noTransaction')+" : "+invoice.orderNo}</TableCell>
            <TableCell>{t.translate('words.status')+" : "+invoice.status}</TableCell>
            <TableCell>{t.translate('words.createdDate')+" : "+moment(invoice.createdDate).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
            <TableCell>{t.translate('words.activeUntil')+" : "+moment(invoice.activeUntil).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><strong>{t.translate('words.targetPayment')}</strong></TableCell>
            <TableCell>{t.translate('words.orderType')+" : "+ invoice.orderType}</TableCell>
            <TableCell>{t.translate('words.name')+" : "+ invoice.name}</TableCell>
            <TableCell>{t.translate('words.total')+" : "+ format(invoice.amountTotal)}</TableCell>
          </TableRow>
        </div>
      )}
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.formTitle`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      onFetchData={(params)=> {
        params = {
          ...search,
          ...params
        }
        return (
          new Promise(async (resolve, reject)=> {
            try {
              await initData()
              let getPage = null
              params.sortBy = params.sort.split(',')[0]
              getPage = await service.getPageEtax(params, match.params.id)
              // getPage.data.orderType = getPage.data.paymentAccount.orderType.code
              getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`${baseId}-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            setSearch(values)
            localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(search))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render : (
                <div>
                  {getStatus.success &&
                    <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.connected")}</Chip>
                  }
                  {!getStatus.success &&
                    <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.notConnected")}</Chip>
                  }
                </div>
              )
            },
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.code`),
              key: 'code.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.idBilling`),
              key: 'idBilling.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              mask: '##.###.###.#-###.###',
              maskChar: '_',
              type: 'text',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.typeTax`),
              key: 'jpCode.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.jenisSetoran`),
              key: 'jsCode.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.month1`),
              key: 'bulan1.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.month2`),
              key: 'bulan2.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.year`),
              key: 'year.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.code'),
          searchable: true,
          sortable : true,
          key:'code',
          render: item => (item.code)
        },
        {
          label: t.translate('words.idBilling'),
          searchable: true,
          sortable : true,
          key:'idBilling',
          render: item => (item.idBilling)
        },
        {
          label: t.translate('words.npwp'),
          searchable: true,
          sortable : true,
          key:'npwp',
          render: item => (item.npwp)
        },
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable : true,
          key:'name',
          render: item => (item.name)
        },
        {
          label: t.translate('words.jenisPajak'),
          searchable: true,
          sortable : true,
          key:'jpCode',
          render: item => (item.jpCode)
        },
        {
          label: t.translate('words.jenisSetoran'),
          searchable: true,
          sortable : true,
          key:'jsCode',
          render: item => (item.jsCode)
        },
        {
          label: t.translate('words.masaPajak'),
          searchable: true,
          sortable : true,
          key:'masaPajak',
          render: item => (item.bulan1 + " - " + item.bulan2 + " " + item.year)
        },
        {
          label: t.translate('words.activeUntil'),
          searchable: true,
          sortable : true,
          key:'activeUntil',
          render: item => (item.activeUntil)
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key:'status',
          render: item => (item.status)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={actions}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.cancel')}`, 'mdi mdi-cancel', ()=> console.log('moreInfo'), (item)=> {
          try{
            if(item.status === 'Order gagal dilakukan.' || item.status === 'Order duplikasi.'){
             return true
            }
          } catch(e){
            return false
          }
        }),
      ]}
      showCommandbar={showCommandbar}
    />
  </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpPaymentNotificationDetails))
