import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpSetEunifikasi.service'
import { toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import iziToast from 'izitoast'

const OpSetEunifikasiForm = ({ authStore, envStore, match, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [isShow, setIsShow] = useState(false)
  const [useProxy, setuseProxy] = useState(false)

  const basePath = 'pengaturanUnifikasi'
  const baseId = 'mod-pengaturanUnifikasi-form'

  useEffect(() => {
    initData()
  }, [])

  async function initData(){
    try {
      var company = JSON.parse(window.localStorage.getItem("user"))
      var companyId = company.company
      var id = null
      if(window.location.href.indexOf('open') != -1){
        id = match.params.companyId
      } else {
        id = companyId.id
      }
      const res = await service.getOne(id)
      res.data.type = res.data.type + ""
      setData({loading: false, content:res.data})
    } catch(e){
      setData({ loading: false })
      ErrorService(e)
    }
  }
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      // backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {
        if(key == "useProxy"){
          try{
            if(value){
              setIsShow(true)
            }else {
              setIsShow(false)
            }
          } catch(e){} 
        }
      }}
      style ={{
        maxWidth : '80%'
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.clientId'),
          key: 'clientId',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.clientSecret`),
          key: 'clientSecret',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.enabledTemplateRole`),
          key: 'enableTemplateRole',
          width: '50%'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.useProxy`),
          key: 'useProxy',
          width: '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyHost'),
          key: 'proxyHost',
          type: 'text',
          show : isShow,
          width: '50%',
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.proxyPort`),
          key: 'proxyPort',
          type: 'text',
          validation: 'required',
          show : isShow,
          width: '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyUsername'),
          key: 'proxyUsername',
          type: 'text',
          show : isShow,
          width: '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPassword`),
          key: 'proxyPassword',
          type: 'password',
          show : isShow,
          width: '50%',
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.http`),
          key: 'proxyType',
          name: 'proxyType',
          width: '50%',
          value: "0",
          style: {
            width: '50%'
          },
          show : isShow
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.sock`),
          key: 'proxyType',
          name: 'proxyType',
          width: '50%',
          value: "1",
          style: {
            width: '50%'
          },
          show : isShow
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.accessToken'),
          key: 'accessToken',
          type: 'text',
          disabled : true,
          width: '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.refreshToken`),
          key: 'refreshToken',
          type: 'text',
          disabled : true,
          width: '50%',
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)

          if(formData.useProxy){
            if(formData.proxyPort) formData.proxyPort = parseInt(formData.proxyPort)

            if(_.isEmpty(formData.proxyHost)|| formData.proxyPort == 0){
              iziToast.error({
                title : "Warning",
                message : "Host dan Port tidak boleh kosong, jika use proxy dicentang"
              });
            }
          }
          
          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`${backTo}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpSetEunifikasiForm))
