import React from 'react'
import { Button, DropdownMenu, FontIcon } from 'react-md'
import Flex from '../Flex'

const Pagination = ({
  baseId      = 'mpk-pagination-id',
  page        = 1,
  size        = 20,
  sizeOptions = [5, 10, 20, 50, 100],
  totalData   = 0,
  onChange    = () => console.log('[react-mpk][component][pagination] - The onChange props has not been defined yet'),
  ...props
}) => {
  page = Number(page)
  size = Number(size)
  const offset = (page - 1) * size

  const start = offset + 1
  let end   = offset+size

  end = end > totalData ? totalData : end
  const totalPage = Math.ceil(totalData/size)

  return (
    <Flex 
      className="mpk-pagination weight-B"
      align={Flex.properties.align.CENTER}
      justify={Flex.properties.justify.END}
      {...props}
    >
      <div className="mpk-margin-N margin-right">{`${start} - ${end} of ${totalData}`}</div>
      <DropdownMenu
        id={`${baseId}-size-menu`}
        disableDropdownIcon
        items={sizeOptions.map( d => ({
          children: d,
          onClick: () => onChange({page:1, size: d})
        }))}
        theme="primary"
      >
        {`${size} rows/page`}
      </DropdownMenu>
      <Button
        theme="primary"
        disabled={page === 1}
        onClick={() => onChange({page: page-1, size})}
      >
        <FontIcon iconClassName="mdi mdi-arrow-left"/>
      </Button>
      <Button
        theme="primary"
        disabled={totalPage <= 1 || page === totalPage}
        onClick={() => onChange({page: page+1, size})}
      >
        <FontIcon iconClassName="mdi mdi-arrow-right"/>
      </Button>
    </Flex>
  )
}

export default Pagination
