import { makeAutoObservable } from 'mobx';
import t from 'counterpart';
import { merge } from 'lodash';

const defaultConfirmation = () => ( {
  id                  : 'mpk-modal-confirmation',
  visible             : false,
  title               : '',
  children            : '',
  onSubmit            : null,
  dialogstyle         : null,
  submitLabel         : null,
  cancelLabel         : null
})

const defaultInformation = () => ({
  id                  :'mpk-modal-information',
  visible             : false,
  title               : '',
  children            : '',
  dialogstyle         : null
})

class ModalStore{
  confirmation = defaultConfirmation()
  information = defaultInformation()

  closeConfirm(){
    this.confirmation.visible = false
  }

  showConfirm({
    title       = t.translate('mpk.confirm.title'),
    children    = t.translate('mpk.config.message'),
    onSubmit    = () => {},
    dialogstyle = null
  }){
    this.confirmation = merge(defaultConfirmation(), {
      visible : true,
      title, children, dialogstyle
    })
    this.confirmation.onSubmit = (callback) => {
      onSubmit(() => {
        callback()
        this.confirmation.visible = false
      })
    }
    
  }

  closeInfo(){
    this.information.visible = false
  }

  showInfo({
    title       = t.translate('mpk.word.info'),
    children    = '',
    dialogstyle = null
  }){
    this.information = merge(defaultInformation(), {
      visible: true,
      title, children, dialogstyle
    })
  }

  constructor(){
    makeAutoObservable(this)
  }
}

export default ModalStore;