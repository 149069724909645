import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from '../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Doss1Archive.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from 'react-md'
import ErrorService from '../../services/errorService'
import Doss1View from './Doss1Archive.form_'
import DoppView from './../DoppDokumenLainArchive/DoppDokumenLainArchive.form_'
import izitoast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'

const Doss1ArchiveForm = ({ authStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [mergeForm, setMergeForm] = useState({
      doss: null,
      dopp: null
  })
  const [ploading, setPloading] = useState({ loading: false, message: '' })

  const baseName = `doss1-archive`
  const basePath = `doss1-archive`

  useEffect(() => {
    initData()
  }, [])

  async function initData(){
    try {
      setData({loading: true})
      var spt = JSON.parse(localStorage.getItem('spt'));
      var res = await service.getDefault({
        sptNpwp: spt.npwp,
        sptYear: spt.year,
        sptMonth: spt.month,
        sptRev: spt.rev
      });
      setData({ content: res.data, loading: false })
      setMergeForm(res.data)
    } catch(e){
      ErrorService(e)
    }
  }

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}

  async function onSubmit(){
    try {
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var res = {}
      res = await service.saveData(mergeForm)
      var message = null
      if(res && res.data && res.data.message) message = res.data.message
      if(message){
        izitoast.info({
          message: message
        })
      } else {
        izitoast.info({
          message: t.translate('sentences.tersimpan')
        })
      }
      setPloading({ loading: false, message: `` })
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  var hintMessage = t.translate('words.belumDiPosting')
  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(window.location.href.indexOf('open') != -1){
      var c = spt.postingDate
      if(c){
        hintMessage = t.translate('words.tanggalPosting') + spt.postingDate 
      }
    } else {
      hintMessage = ''
    }
  } catch(e){}
  
  var actions = [
    {
      label: t.translate('words.reload'),
      iconClassName: 'mdi mdi-reload',
      onClick: initData
    },
    {
      label: 'Simpan',
      iconClassName: 'mdi mdi-floppy',
      onClick: onSubmit
    }
  ]
  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(spt.status == 'SUBMIT' || spt.status == 'FINSIH'){ 
      actions = [
        {
          label: t.translate('words.reload'),
          iconClassName: 'mdi mdi-reload',
          onClick: initData
        },
      ]
    }
  } catch(e){}
  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <FormWrapper
        style={{
          maxWidth: '100%',
          background: '#F3F0ED',
          padding: '0px'
        }}
        className="fullWidth"
        loading={data.loading}
        actions={actions}
        hintMessage={hintMessage}
        baseId={`mod-form-${baseName}`}
        title={t.translate(`modules.${baseName}.title`)}
        definitions={[
          {
            render: (
              <div style={{ width: '100%' }}>
                {mergeForm.doss &&
                  <Doss1View match={match} mergeForm={mergeForm} setMergeForm={setMergeForm} />
                }
                <br />
                {mergeForm.dopp && 
                  <DoppView  match={match} mergeForm={mergeForm} setMergeForm={setMergeForm} />
                }
                {/* <br />
                <DoppView  match={match} mergeForm={mergeForm} setMergeForm={setMergeForm} /> */}
              </div>
            )
          }
        ]}
      >
        
      </FormWrapper>
    </>
  )
}

export default inject('authStore', 'envStore')(observer(Doss1ArchiveForm))
