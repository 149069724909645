import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/ebilling-core/invoices');

service.getPage = async(query)=> {
  return http.request({
    method : http.methods.GET,
    url :  `/api/ebilling-core/invoices`,
    query
  })
}

service.getInvoice = async(invoiceId)=> {
  return http.request({
    method : http.methods.GET,
    url :  `/api/ebilling-core/invoices/orderFile/${invoiceId}`
  })
}

service.coreStatus = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/ebilling/coreStatus`,
    query
  })
}

service.validate = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/validate`,
    data
  })
}

service.create = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices`,
    data
  })
}

service.validateAll = async(data)=> {
  return http.request({
    method : http.methods.GET,
    url :  `/api/ebilling-core/invoices/validate/all`,
    data
  })
}

service.createAll = async(data)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/all`,
    data
  })
}

service.show = async(data, id)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/${id}`,
    data
  })
}

service.findbillingByOrderId = async(data, invoiceId)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/billing/${invoiceId}`,
    data
  })
}

service.findOrderFileByOrderId = async(data, invoiceId)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/orderFile/${invoiceId}`,
    data
  })
}

service.resendOrderFileByOrderId = async(invoiceId)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/orderFile/${invoiceId}/resend`
  })
}

service.confirmOrder = async(data, id)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/confirm/${id}`,
    data
  })
}

service.pullNtpn = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/pull-ntpn`,
    data
  })
}

service.checkNtpn = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/pull-ntpn/check`,
    data
  })
}

service.print = async(data, id)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/print/${id}`,
    data
  })
}

service.printBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/print-bulk`,
    data
  })
}

service.printBpn = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/print-bpn`,
    data
  })
}

service.printBpnBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/print-bpn-gabung`,
    data
  })
}

service.printReport = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/print-report`,
    data
  })
}

service.deleteBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/invoices/delete/checked`,
    data
  })
}

service.getFinnetWidget = async(data, invoiceId) => {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/${invoiceId}/getFinnetWidget`,
    data
  })
}

service.cancel = async(id)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling/etaxs/cancel/${id}`
  })
}

service.orderBulk = async(id)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/invoices/confirm/${id}`,
  })
}

service.getPageEtax = async(query, id)=> {
  return http.request({
    method : http.methods.GET,
    url : `/api/ebilling-core/etaxs/byInvoice/${id}`,
    query
  })
}

service.generate = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/invoiceReports`,
    data
  })
}

service.generateBpn = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/bpns`,
    data
  })
}

service.generateBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/bpns/bulk`,
    data
  })
}

service.generatePn = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/invoices`,
    data
  })
}

service.generatePnBulk = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/ebilling-core/log/print/invoices/bulk`,
    data
  })
}

export default service;