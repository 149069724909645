import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpPaymentAccount.service'
import { toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import errorService from './../../services/errorService'

const OpPaymentAccountForm = ({ 
  authStore, 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [bankCore, setBankCore] = useState([])

  const basePath = 'paymentAccount'
  const baseId = 'mod-payment-account-form'

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          console.log(e)
          ErrorService(e)
        }
      } else {
        let res = await service.getOne(match.params.id)
        res.data.orderType = res.data.orderType.id
        setData({loading: false, content: res.data})
      }
    }
    initData()
    // getBank()
    getBankCore()
  }, [])

  const getBankCore = async ()=> {
    try {
      let res = await service.getBankCore()
      var options = []
      res.data.map((d)=> {
        d.label = d.name
        options.push({
          label: d.name,
          value: d.id
        })
      })
      setBankCore(options)
    } catch(e){
      ErrorService(e)
    }
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {`/onpremise/${basePath}`}
      baseId={`mod-form-${basePath}`}
      title = {t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate('words.orderType'),
          key: 'orderType',
          type: 'text',
          validation: 'required',
          data : bankCore,
          options : bankCore,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.userId'),
          key: 'name',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.companyId'),
          key: 'companyId',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.accountNumber'),
          key: 'accountNumber',
          type: 'text',
          validation: 'required',
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.updateById(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`/onpremise/${basePath}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpPaymentAccountForm))
