import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/log/mail'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/log/mail');
  serviceUrl = '/api/eunifikasi/bp/log/mail'
} else {
  service = new CrudService('/bp/log/mail');
}

service.getBySpt = async(query, sptId)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/spt/${sptId}`,
      query
    })
}

service.history = async(id, query)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/history/${id}`,
      query
    })
}

export default service;