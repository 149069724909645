import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpSettingCore.service'
import { toJS } from 'mobx';
import {Button, List, ListItem, FontIcon, Avatar, Chip} from 'react-md'
import UtilsService from '../../services/utilsService'
import _ from 'lodash'
import ErrorService from '../../services/errorService'
import errorService from '../../services/errorService'

const OpSettingCoreForm = ({ authStore, envStore, match, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {
    proxyPort: ""
  }})
  const [getStatus, setStatus] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [useProxy, setuseProxy] = useState(false)

  const basePath = 'pengaturan'
  const baseId = 'mod-pengaturan-form'

  useEffect(() => {
    initData()
  }, [])

  async function initData(){
    try{
      const res = await service.get()
      res.data.type = res.data.type + ""
      res.data.proxyPort = res.data.proxyPort + ""
      setData({loading : false, content: res.data})
      if(res.data.useProxy){
        setIsShow(true)
      }
      const status = await service.coreStatus()
      setStatus(status.data)
    } catch(e){
      errorService(e)
    }
  }
  
  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  return (
    <>
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {
        if(key == "useProxy"){
          if(value == true){
            setIsShow(true)
          } else {
            setIsShow(false)
          }
        }
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          render : (
            <>
            {getStatus.success &&
              <Chip style={{ background: "#4CAF50", color: 'white', marginBottom: 14 }}>{t.translate("words.connected")}</Chip>
            }
            {!getStatus.success &&
              <Chip style={{ background: "#F44336", color: 'white',  marginBottom: 14 }}>{t.translate("words.notConnected")}</Chip>
            }
            </>
          )
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.clientId'),
          key: 'clientId',
          type: 'text',
          
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.clientSecret`),
          key: 'clientSecret',
          type: 'text',
          
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.useProxy`),
          key: 'useProxy',
          width: '50%'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyHost'),
          key: 'proxyHost',
          type: 'text',  
          show : isShow
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPort`),
          key: 'proxyPort',
          type: 'text',
          show : isShow
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.http`),
          key: 'proxyType',
          name: 'proxyType',
          width: '50%',
          value: "0",
          style: {
            width: '50%'
          },
          show : isShow
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.sock`),
          key: 'proxyType',
          name: 'proxyType',
          width: '50%',
          value: "1",
          style: {
            width: '50%'
          },
          show : isShow
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.proxyUsername'),
          key: 'proxyUsername',
          type: 'text',
          show : isShow
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPassword`),
          key: 'proxyPassword',
          type: 'text',
          show : isShow
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.accessToken'),
          key: 'accessToken',
          type: 'text',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.refreshToken`),
          key: 'refreshToken',
          type: 'text',
          disabled : true
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          if(formData.type == 'true'){
            formData.type = true
          } else {
            formData.type = false
          }
          formData.proxyPort = parseInt(formData.proxyPort)
          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.update(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(`${backTo}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
    </>
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpSettingCoreForm))
