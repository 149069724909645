import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpRoleTemplate.service'
import { toJS, get } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import { Dialog, List, ListItem, DialogContent, DialogFooter, Card, CardContent, TableRow, Table, TableBody, TableCell, Button, TableHeader, Checkbox } from 'react-md'

const OpRoleTemplate = ({ 
  authStore, 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const [permission, setPermission] = useState([])
  const [access, setAccess] = useState([])
  const [template, setTemplate] = useState([])
  const [organization, setOrganization] = useState([])
  const [isAdmin, setIsAdmin] = useState(false);
  const [iCompany, setICompany] = useState(null);

  const basePath = 'templateRole'
  const baseId = 'mod-templateRole-form'

  useEffect(() => {
    async function initData(){
      try {
        let user = JSON.parse(localStorage.getItem('user'))
        var filterIsAdmin = user.authorities.map((d)=> {
          if(d.name == "ROLE_EPPT_ADMIN"){
            setIsAdmin(true)
          } else {
            setIsAdmin(false)
          }
        })
        await getCompany()
        var permissions = await service.permission();
        var accesss = await service.access({
          size: 200
        });
        setPermission(permissions.data)
        setAccess(accesss.data)
        permissions.data.map((d)=> {
          d.disabled  = true;
        })
        var template = []
        accesss.data.map((d)=> {
          d.permissions.map((c)=> {
            c.disabled = false
            c.checked = false
          })
          var accessT = {
            access: d,
            permissions: permissions.data
          }
          template.push(accessT)
        })
        template.map((d, index)=> {
          var newPermission = Object.assign([], d.permissions, accesss.data[index].permissions)
          d.permissions = newPermission
        })
        setTemplate(template)
        // console.log(permissions.data, "P")
        // console.log(accesss.data, "A")
        // console.log(template, "T")
      } catch(e){
        ErrorService(e)
      }
      if(match.params.id == 'new') {
        try {
          setData({loading: false, content: {}})
        } catch(e){
          ErrorService(e)
        }
      } else {
        try {
          let res = await service.getOne(match.params.id)
          setICompany(res.data.company)
          res.data.accesses.map((d)=> {
            d.permissions.map((c)=> {
              c.checked = true
            })
          })
          var templateCheked = Object.assign([], template, res.data.accesses)
          templateCheked.map((d, index)=> {
            var newPermission = Object.assign([], template[index].permissions, d.permissions)
            d.permissions = newPermission
          })
          setTemplate(templateCheked)
          res.data.company.value = res.data.company
          res.data.company.label = res.data.company.nama
          setData({loading: false, content: res.data})
        } catch(e){
          ErrorService(e)
        }
        // let res = await service.getOne(match.params.id)
        // setData({loading: false, content: res.data})
      }
    }
    initData()
  }, [])

  var backTo = `/onpremise/${basePath}`
  if(window.location.href.indexOf('open') != -1){
    backTo = `/onpremise/company/open/${match.params.companyId}/${basePath}`
  }

  const getCompany = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['nama.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await service.getCompany(params)
      res.data.map((d)=> {
        d.label = d.nama
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{ 
        maxWidth: '100%'
      }}
      backTo = {`/onpremise/${basePath}`}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'company',
          labelKey: 'label', 
          valueKey: 'label',
          data: organization,
          options : organization,
          //show : (isShowOrg && !edit),
          show: isAdmin,
          async : true,
          defaultOptions : organization,
          loadOptions: async (inputValues)=> {
            var filter = await getCompany(inputValues);
            return filter
          }
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.name'),
          key: 'name',
          type: 'text',
          validation: 'required'
        },
        {
          render: (
            <div style={{ width: '100%' }}>
              <Card style={{ width: '100%' }}>
                <CardContent>
                  <Table style={{ width: '100%' }}>
                    <TableHeader>
                      <TableRow>
                        <TableCell>NO</TableCell>
                        <TableCell>MODULE</TableCell>
                        <TableCell><Checkbox name="checkAll" onChange={(e)=> {
                          try {
                            template.map((d, index)=> {
                              document.getElementById(`check${index}`).checked = e.target.checked
                              d.permissions.map((c, cIndex)=> {
                                if(!c.disabled){
                                  c.checked = e.target.checked;
                                  document.getElementById(`miniChk${index}${cIndex}`).checked = e.target.checked
                                }
                              })
                            })
                          } catch(e){}
                        }}></Checkbox></TableCell>
                        {permission && permission.length > 0 &&
                          <>
                            {permission.map((d,i)=> {
                              return <TableCell>{d.name.toUpperCase()}</TableCell>
                            })}
                          </>
                        }
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                        {template && template.length > 0 &&
                          <>
                            {template.map((d, index)=> {
                              return (
                                <TableRow>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{d.access.name}</TableCell>
                                    <TableCell><Checkbox name={`check${index}`} id={`check${index}`}  onChange={(d)=> {
                                      try {
                                        template[index].permissions.map((c, cIndex)=> {
                                          if(!c.disabled){
                                            c.checked = d.target.checked
                                            document.getElementById(`miniChk${index}${cIndex}`).checked = d.target.checked
                                          }
                                          setTemplate(template)
                                        })
                                      } catch(e){}
                                    }}></Checkbox></TableCell>
                                    {d.permissions && d.permissions.length > 0 &&
                                      <>
                                        {d.permissions.map((c, cIndex)=> {
                                          return <TableCell><Checkbox id={`miniChk${index}${cIndex}`} disabled={c.disabled} defaultChecked={c.checked} onChange={async (z)=> {
                                            try {
                                              template[index].permissions[cIndex].checked = z.target.checked
                                              setTemplate(template)
                                            } catch(e){}
                                          }} name={`check${index}${cIndex}`}></Checkbox></TableCell>
                                        })}
                                      </>
                                    }
                                </TableRow>
                              )
                            })}
                          </>
                        }
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </div>
          )
        }
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          if(!formData.company){
            formData.company = JSON.parse(localStorage.getItem('user')).company
          }
          // access 
          formData.accesses = []
          try {
            var getTemplate = Object.assign([], template)
            getTemplate.map((d)=> {
              delete d.id
              delete d.access.permissions
              d.permissions = d.permissions.filter((c)=> {
                return c.checked == true
              })
            })
            getTemplate = getTemplate.filter((d)=> {
              return d.permissions.length != 0
            })
            getTemplate.map((d)=> {
              var permit = []
              d.permissions.map((c)=> {
                if(c.permission){
                  permit.push(c)
                } else {
                  permit.push({
                    permission: c
                  })
                }
              })
              d.permissions = permit
            })
            formData.accesses = getTemplate
            console.log(getTemplate)
          } catch(e){}
          // access
          // if(!formData.company){
          //   formData.company = JSON.parse(localStorage.getItem('company'))
          // }
          // formData.accesses = _.cloneDeep(accessRole)
          try {
            delete formData.company.value
            delete formData.check_all
          } catch(e){}
          if(match.params.id == 'new') {
            res = await service.createdOne(formData)
          } else {
            res = await service.editById(formData.id, formData)
          }
          navigationStore.redirectTo(`${backTo}`)      
        } catch(e){
          console.log(e)
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpRoleTemplate))