import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/profile');

service.me = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/account/me`,
    query
  })
}

service.saveAccount = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/account`,
    data
  })
}

service.changePassword = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/account/change_password`,
    data
  })
}

export default service;